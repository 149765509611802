import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, SecurityContext } from '@angular/core';
import { PriceListFilterI, PriceListI, PriceListItemI } from '@shared';
import { CitiesService } from '@shared/services/pages/cities.service';
import { ServicesService } from '@shared/services/pages/services.service';
import { BaseComponent } from '@front/app/base-component';
import {
  Observable,
  BehaviorSubject,
  switchMap,
  map,
  combineLatest,
} from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-service-price-list',
  templateUrl: './price-list.component.html',
  styleUrls: ['./price-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PriceListComponent extends BaseComponent implements OnInit {
  @Input() set specId(event: number) {
    this._specId.next(event);
  }
  @Input() set searchText(event: string) {
    this._searchText.next(event);
  }

  
  @Input() selectable: boolean = false;
  @Output() onSelect = new EventEmitter<PriceListItemI>();

  @Input() searchParams: PriceListFilterI | {} = {};
  
  priceList$: Observable<PriceListI[]>;
  _specId = new BehaviorSubject<number>(0);
  _searchText = new BehaviorSubject<string>('');

  constructor(
    private readonly srv: ServicesService,
    private readonly citiesSrv: CitiesService,
    private _sanitizer: DomSanitizer,
  ) {
    super();
    this.priceList$ = combineLatest({
      specId: this._specId,
      cityIds: this.citiesSrv.selectedCitiesObs,
      searchText: this._searchText
    }).pipe(
      switchMap(({ specId, cityIds, searchText }) => {
        return this.srv.getPriceList({
          ...this.searchParams,
          specializationId: specId,
          cityIds: cityIds,
          searchText: searchText,
        });
      }),
      map((priceList) => {
        return priceList.map((item) => {
          const tempDiv = document.createElement('div');
          tempDiv.innerHTML = item.branch || '';
          const branch = tempDiv.textContent || '';
          return {
            ...item,
            branch 
          };
        });
      })
    );
  }
  selectPrice(item: PriceListItemI, branchName: string, branchId: number) {
    this.selectable && this.onSelect.emit({...item, branchName, branchId})
  }
  ngOnInit() {}

  

  stripTags(html: string): string {
    const str = this._sanitizer.sanitize(SecurityContext.HTML, this._sanitizer.bypassSecurityTrustHtml(html));
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = str || '';
    return tempDiv.textContent || '';
  }

  removeHtmlTags(html: string): string {
    return this.stripTags(html)
  }
}
