import { ItemType } from '@s';
import {
  MappedAnswer,
  SurveyFormI,
  SurveyItemI,
  SurveyResponse,
  SurveyResponseItem,
  SurveyTreeNodeI,
} from '../interfaces/personal-data.interface';

export function createTree(flatArray: SurveyItemI[]): SurveyTreeNodeI[] {
  const tree: SurveyTreeNodeI[] = [];
  const itemMap: Record<string, SurveyTreeNodeI> = {};

  flatArray.forEach((item: SurveyItemI) => {
    item.children = [];
    itemMap[item.id] = item;
  });

  flatArray.forEach((item: SurveyItemI) => {
    if (item.parentId) {
      const parent = itemMap[item.parentId];
      if (parent) {
        parent.children.push(item);
      }
    } else {
      tree.push(item);
    }
  });
  sortItems(tree);
  return tree;
}
function sortItems(items: SurveyItemI[]) {
  items.sort((a, b) => (a.sortOrder || 0) - (b.sortOrder || 0));
  items.forEach(item => {
    if (item.children.length > 0) {
      sortItems(item.children);
    }
  });
}

export function filterOutFiles(flatArray: SurveyResponse, list: SurveyItemI[]): SurveyResponseItem[] {
  // answer
  const fileNodes = list.filter(item => item.itemType === ItemType.FileUpload).map(item => item);
  // using reduce within answers 'answer' array keys to filter out file ids
  return flatArray.answers.reduce((acc: SurveyResponseItem[], item: SurveyResponseItem) => {
    const { answer } = item;
     Object.entries(answer).forEach(([key, value]: any) => {
      const node: any = fileNodes.find(f => f.id === key);
      if (node) acc.push({answer: value, ...node});
    });
    
    return acc;
  }, [] as SurveyResponseItem[]);
}

export function formatSurvey(flatArray: SurveyResponse, list: SurveyItemI[]): SurveyFormI {
  return {
    surveyTitle: flatArray.surveyTitle,
    surveyId: flatArray.surveyId,
    answers: mapAnswers(flatArray.answers, list),
    fullName: flatArray.fullName,
    birthDate: flatArray.birthDate,
    phone: flatArray.phone,
    email: flatArray.email,
    height: flatArray.height,
    weight: flatArray.weight,
    description: flatArray.description,
  };
}

function mapAnswers(questionData: SurveyResponseItem[], answerList: SurveyItemI[]): MappedAnswer[] {
  return questionData.map(item => {
    const { question, answer } = item;
    let fullAnswer: any = {};

    answerList.filter(a => a.itemType !== ItemType.FileUpload).forEach(answerItem => {
      const { id, title } = answerItem;
      const answerValue = answer[id];

      if (answerValue !== undefined && answerValue !== null) {
        fullAnswer = { ...fullAnswer, [title]: answerValue };
      }
    });

    return {
      questionName: question,
      fullAnswer: fullAnswer,
    };
  });
}
