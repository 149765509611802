

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { FaqComponent } from './faq/faq.component';
import { OpinionsComponent } from './opinions/opinions.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { RequisitesComponent } from './requisites/requisites.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';

export const childRoutes = [
  { path: 'frequently-asked-questions', component: FaqComponent },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'terms-of-use', component: TermsAndConditionsComponent },
  { path: 'requisites', component: RequisitesComponent },
  { path: 'opinions', component: OpinionsComponent },
  // { path: 'description', component: CompanyDescriptionComponent },
  { path: 'about-us', component: AboutUsComponent },
]
@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild(childRoutes),
  ],
  exports: [
    RouterModule
  ]
})
export class AboutUsRoutingModule {}
