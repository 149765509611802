import { Injectable } from '@angular/core';
import { ApplicationHttpClient } from '@s';
import {
  BranchDataI,
  BranchesListI,
  CitiesListI,
  ResponseDto,
} from '@shared/interfaces';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CitiesApiService {
  
  constructor(private http: ApplicationHttpClient) {}

  getCitiesList(): Observable<ResponseDto<CitiesListI[]>> {
    return this.http.get<ResponseDto<CitiesListI[]>>(
      '/portal/api/v1/cities',
      {}
    );
  }
  getBranchesList(cityIds?: number[]): Observable<ResponseDto<BranchesListI[]>> {
    return this.http.get<ResponseDto<BranchesListI[]>>(
      '/portal/api/v1/branches',
      {
        params: {
          cities: cityIds || '',
        },
      }
    );
  }
  getBranchBySlug(slug: string): Observable<ResponseDto<BranchDataI>> {
    const encodedRoute = encodeURIComponent(slug);
    return this.http.get<ResponseDto<BranchDataI>>(
      `/portal/api/v1/branches/bySlug/${encodedRoute}`
    );
  }
}
