import { ChangeDetectionStrategy, SecurityContext } from '@angular/core';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-radio-filter',
  templateUrl: './radio-filter.component.html',
  styleUrls: ['./radio-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioFilterComponent implements OnInit {
  @Input() list: { title: string; id: number }[] = [];
  @Input() set selected(event: number | undefined) {
    event && this.form.get('id')?.setValue(event.toString());
  }
  @Output() save = new EventEmitter<number>();

  form: FormGroup;

  constructor(private readonly fb: FormBuilder, public readonly translate: TranslateService, private _sanitizer: DomSanitizer) {
    this.form = this.fb.group({
      id: ['', Validators.required],
    });
  }

  ngOnInit() {}
  change(): void {
    this.save.emit(+this.form.value.id);
  }

  stripTags(html: string): string {
    const str = this._sanitizer.sanitize(SecurityContext.HTML, this._sanitizer.bypassSecurityTrustHtml(html));
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = str || '';
    return tempDiv.textContent || '';
  }

  removeHtmlTags(html: string): string {
    return this.stripTags(html)
  }
}
