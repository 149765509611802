import { Injectable } from '@angular/core';
import { CompanyInfoI, GlobalSearchI, GlobalSearchTypes } from '@shared';
import { BehaviorSubject, map, Observable, tap } from 'rxjs';
import { GlobalApiService } from './api/global.api.service';
import { MetaService } from '@ngx-meta/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  companyInfo$ = new BehaviorSubject<CompanyInfoI>({} as CompanyInfoI);
  captchaSiteKey$ = new BehaviorSubject<any>(undefined);
  
  constructor(
    private readonly srv: GlobalApiService,
    private readonly meta: MetaService,
    private readonly router: Router
  ) {
    if (!this.router.routerState.snapshot.url.endsWith('404')) {
      this.getCompanyInfo().subscribe(i => this.companyInfo$.next(i));
    }
  }

  getCompanyInfo(): Observable<CompanyInfoI> {
    return this.srv.getCompanyInfo().pipe(
      map(d => {
        return d?.data || {};
      }),
      tap(data => {
        const twitter = data.twitter && data.twitter.split('/').at(-1)!;
        this.meta.setTag('twitter:creator', twitter);
        this.meta.setTag('article:publisher', data.facebook);
      })
    );
  }
  globalSearch(text: string): Observable<GlobalSearchI[]> {
    return this.srv.globalSearch(text).pipe(
      map(d => {
        return d?.data.filter(i => i.path !== GlobalSearchTypes.bestOffers) || [];
      })
    );
  }  
}
