<div *ngIf="isLoading && !isServer" class="w-full h-full d-flex justify-center items-center">
  <mat-spinner></mat-spinner>
</div>
<div class="flex h-full" 

[ngClass]="{
  'visually-hidden': isLoading && !isServer,
  'h-full': !autoHeight
}">
  <img
    [src]="image"
    [ngStyle]="styles"
    (load)="hideLoader()"
    (error)="hideLoader()"
    [alt]="alt"
    [width]="(isLoading && isServer) ? 0 : ''"
    [height]="(isLoading && isServer) ? 0 : ''"
  />
</div>
