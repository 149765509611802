export interface AppSettings {
  navPos: 'side' | 'top';
  dir: 'ltr' | 'rtl';
  theme: 'light' | 'dark' | 'auto';
  showHeader: boolean;
  headerPos: 'fixed' | 'static' | 'above';
  showUserPanel: boolean;
  sidenavOpened: boolean;
  sidenavCollapsed: boolean;
  language: string;
}

export const defaults: AppSettings = {
  navPos: 'side',
  dir: 'ltr',
  theme: 'auto',
  showHeader: true,
  headerPos: 'fixed',
  showUserPanel: true,
  sidenavOpened: true,
  sidenavCollapsed: false,
  language: 'hy',
};

export enum MimeToExtension {
  'jpg' = 'image/jpeg',
  'png' = 'image/png',
  'svg' = 'image/svg+xml',
  'gif' = 'image/gif',
  'bmp' = 'image/bmp',
  'webp' = 'image/webp',
  'jp2' = 'image/jpeg2000',
  'tiff' = 'image/tiff',
  'ico' = 'image/x-icon'
}