<div
  class="container-grid vcim-grid vcim-grid-size-1 vcim-grid-size-2-xs vcim-grid-size-4-md gap-4 gap-md-6 px-3"
  *ngIf="list$ | async as list">
  <div *ngFor="let item of list; let i = index" #itemElement>
    <app-services-card
      [service]="{
        id: item.id,
        photo: item.photo,
        photoPath: item.photoPath,
        title: item.title,
        link: '/services/' + item.slug
      }"></app-services-card>
  </div>
</div>
