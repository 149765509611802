import { Inject, Injectable, Optional } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BASE_URL, ProtocolService, getHost } from '@s';

@Injectable()
export class BaseUrlInterceptor implements HttpInterceptor {
  apiUrl: string = '';

  constructor(
    public protocolService: ProtocolService,
    @Optional() @Inject(BASE_URL) private baseUrl?: {[key: string]: string}
    ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.apiUrl = getHost(this.baseUrl, request.url);
    return this.apiUrl
      ? next.handle(request.clone({ url: this.protocolService.getUrl(request.url) }))
      : next.handle(request);
  }

}
