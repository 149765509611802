import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { WorkshopViewComponent } from './workshop-view/workshop-view.component';
import { WorkshopsListComponent } from './workshops-list/workshops-list.component';

export const childRoutes = [
  { path: '', component: WorkshopsListComponent },
  { path: ':slug', component: WorkshopViewComponent },
];
@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild(childRoutes),
  ],
  exports: [RouterModule],
})

export class WorkshopsRoutingModule {}