import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ResultsDataI } from '@shared/interfaces';
import { DashboardService } from '@shared/services/pages/dashboard.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-our-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class OurResultsComponent implements OnInit {

  resultsData$: Observable<ResultsDataI>;
  constructor(
    // private readonly route: ActivatedRoute,
    private readonly srv: DashboardService
  ) {
    this.resultsData$ = this.srv.getResultsData();
  }

  ngOnInit() {}
}
