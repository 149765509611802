import { Component, OnInit, ChangeDetectionStrategy, Inject, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@env/environment';
import { BaseComponent } from '@front/app/base-component';
import { MimeToExtension } from '@front/app/core';
import { MetaDataService } from '@front/app/shared/services/meta-data.service';
import { TranslateService } from '@ngx-translate/core';
import { BASE_URL, ProtocolService, SettingsService } from '@s';
import { NewsShareType, WorkshopDataI } from '@shared';
import { WorkshopsService } from '@shared/services/pages/workshops.service';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, of, switchMap, tap } from 'rxjs';

@Component({
  selector: 'app-workshop-view',
  templateUrl: './workshop-view.component.html',
  styleUrls: ['./workshop-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkshopViewComponent extends BaseComponent implements OnInit {
  environment = environment;
  workshopInfo$: Observable<WorkshopDataI | undefined>;
  slug$ = new BehaviorSubject<string | null>(null);
  NewsShareType = NewsShareType;
  link = '';
  isCopying = false;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly srv: WorkshopsService,
    private readonly toastr: ToastrService,
    private readonly translate: TranslateService,
    private readonly meta: MetaDataService,
    private _sanitizer: DomSanitizer,
    private readonly router: Router,
    public protocolService: ProtocolService,
    public readonly settings: SettingsService,
    @Inject(BASE_URL) public baseUrl?: { [key: string]: string }
  ) {
    super();
    this.workshopInfo$ = this.slug$.pipe(
      switchMap(slug => {
        if (slug) {
          return this.srv.getWorkshopBySlug(slug)
        }
        return of(undefined);
      })
    );
  }

  ngOnInit() {
    this.route.params.pipe(this.takeUntilDestroy()).subscribe(snapshot => {
      this.slug$.next(snapshot.slug);
      this.link = this.protocolService.getUrl(environment?.website + this.router.url);
    });
  }
  copy(): void {
    if (!this.isCopying) {
      this.isCopying = true;
      this.toastr.info(this.translate.instant('website.workshops.text-copied'));
      setTimeout(() => {
        this.isCopying = false;
      }, 5000);
    }
  }

  stripTags(html: string): string {
    const str = this._sanitizer.sanitize(SecurityContext.HTML, this._sanitizer.bypassSecurityTrustHtml(html));
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = str || '';
    return tempDiv.textContent || '';
  }

  removeHtmlTags(html: string, crop?: boolean): string {
    return crop ? this.stripTags(html).substring(0, 150) : this.stripTags(html);
  }
}
