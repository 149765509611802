import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Token } from './interface';
import { Menu } from '@core';
import { map } from 'rxjs/operators';
import { User } from '@shared';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(protected http: HttpClient) {}

  login(username: string, password: string) {
    return this.http.post<Token>(
    '/patients/api/v1/auth/sign-in', { username, password });
  }

  refresh() {
    return this.http.post<Token>('/patients/api/v1/auth/refresh-token', {});
  }

  logout() {
    return this.http.post<any>('/auth/logout', {});
  }

  me() {
    return this.http.get<User>('/patients/api/v1/auth/me');
  }

  // menu() {
  //   return this.http.get<{ menu: Menu[] }>('/me/menu').pipe(map(res => res.menu));
  // }
}
