<section class="vcim-container container pt-8 pt-lg-16 mb-6 pb-10 pb-xl-16">
    <div *ngIf="policyInfo$ | async as policyInfo; else showSpinner">
  
      <div class="vcim-headline-1 mb-6 text-center">
        <ngx-dynamic-hooks
          [options]="{ sanitize: false }"
          class="vcim-inner-html inner-html-title"
          [content]="policyInfo.title"></ngx-dynamic-hooks>
      </div>
  
      <div class="row">
        <div class="col-12">
          <ngx-dynamic-hooks
            [options]="{ sanitize: false }"
            class="vcim-body-1 vcim-inner-html"
            [content]="policyInfo.description"
          ></ngx-dynamic-hooks>
        </div>
      </div>
    </div>
  </section>
  
  <ng-template #showSpinner>
    <div style="height: 100px" class="my-10 d-flex justify-center">
      <mat-spinner></mat-spinner>
    </div>
  </ng-template>
  