<section class="vcim-container container pt-8 pt-lg-16 mb-6 pb-10 pb-xl-16">
  <div class="row column-reverse md-flex-row" *ngIf="workshopInfo$ | async as workshopInfo; else showSpinner">
    <div class="col-12 col-md-4">
      <div class="flex flex-col pr-md-16">
        <h1 class="vcim-headline-4 mb-6">
          {{ 'website.workshops.related' | translate }}
        </h1>

        <div *ngIf="workshopInfo.related.length" class="w-full flex flex-col">
          <div
            *ngFor="let related of workshopInfo.related; let i = index"
            [routerLink]="'/seminars/' + related.slug | localize"
            class="related-item w-full d-flex flex-col py-6 pointer">
            <div class="vcim-headline-4 mb-2">
              <ngx-dynamic-hooks
                [options]="{ sanitize: false }"
                class="vcim-inner-html inner-html-title"
                [content]="removeHtmlTags(related.title)"></ngx-dynamic-hooks>
            </div>
            <ngx-dynamic-hooks
              [options]="{ sanitize: false }"
              class="related-description vcim-body-2 mb-3 truncate-2"
              [content]="removeHtmlTags(related.description, true)"></ngx-dynamic-hooks>
            <p class="related-date f-14">
              {{ related.seminarDate }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-8">
      <div class="mb-6 vcim-headline-1">
        <ngx-dynamic-hooks
          [options]="{ sanitize: false }"
          class="vcim-inner-html inner-html-title"
          [content]="workshopInfo.title"></ngx-dynamic-hooks>
      </div>

      <div class="d-flex justify-between items-center mb-6">
        <span class="date">{{ workshopInfo.seminarDate }}</span>
        <div class="d-flex justify-end">
          <!-- 
            some social btns has changed sharing principles
            linked-in images should be preregistered, fb needs tags, etc
           -->
          <app-share-button appId="845217403389659" icon="ic-facebook" button="facebook" class="d-flex ml-2"></app-share-button>
          <app-share-button icon="ic-linkedin" button="linkedin" class="d-flex ml-2"></app-share-button>
          <app-share-button icon="ic-twitter" button="twitter" class="d-flex ml-2"></app-share-button>

          <button mat-icon-button class="d-flex items-center justify-center ml-2" [cdkCopyToClipboard]="link" (click)="copy()">
            <i class="vcim-icon d-flex ic-share f-20"> </i>
          </button>
          <textarea [(ngModel)]="link" class="visually-hidden"></textarea>
        </div>
      </div>

      <div *ngIf="workshopInfo.photo" class="mb-6 d-flex container-image">
        <loading-image
          [image]="'/portal/' + workshopInfo.photoPath + '/' + workshopInfo.photo"
          [alt]="workshopInfo.title"
          [styles]="{
            'object-fit': 'cover',
            'object-position': 'center'
          }"
          class="w-full h-full">
        </loading-image>
      </div>

      <div class="w-full">
        <ngx-dynamic-hooks
          [options]="{ sanitize: false }"
          class="vcim-body-1 vcim-inner-html"
          [content]="workshopInfo.description"></ngx-dynamic-hooks>
      </div>
    </div>
  </div>
</section>

<ng-template #showSpinner>
  <div style="height: 100px" class="my-10 d-flex justify-center">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
