import { Injectable } from '@angular/core';
import { ApplicationHttpClient } from '@s';
import { ResponseDto } from '@shared/interfaces';
import { Observable } from 'rxjs';
import {
  AppointmentCreateFormI,
  AppointmentCreatePolicies,
  AppointmentsTableI,
  RatingFormI,
  RatingList,
  SheduleTimesFilterI,
  SheduleTimesI,
  SurveyDataI,
  SurveyFormDto,
  SurveyFormI,
  SurveyResponse,
  TaskCreateFormI,
  TasksTableI,
} from '../../interfaces/personal-data.interface';

@Injectable({
  providedIn: 'root',
})
export class AppointmentCreateApiService {
  constructor(private http: ApplicationHttpClient) { }

  getAppointmentPolicies(serviceId: number): Observable<ResponseDto<AppointmentCreatePolicies[]>> {
    return this.http.get<ResponseDto<AppointmentCreatePolicies[]>>(`/patients/api/v1/appointments/policies`, {
      params: {
        serviceId,
      },
    });
  }

  getScheduleDates(doctorId: number): Observable<ResponseDto<string[]>> {
    return this.http.get<ResponseDto<string[]>>(`/patients/api/v1/appointments/schedule-dates`, {
      params: {
        doctorId,
      },
    });
  }
  getScheduleTimes(searchOptions: SheduleTimesFilterI): Observable<ResponseDto<SheduleTimesI[]>> {
    return this.http.get<ResponseDto<SheduleTimesI[]>>(`/patients/api/v1/appointments/schedule-times`, {
      params: {
        ...searchOptions,
      },
    });
  }
  createAppointment(body: AppointmentCreateFormI): Observable<ResponseDto<AppointmentsTableI>> {
    return this.http.post<ResponseDto<AppointmentsTableI>>(`/patients/api/v1/appointments`, body);
  }
  createTask(body: TaskCreateFormI): Observable<ResponseDto<TasksTableI>> {
    return this.http.post<ResponseDto<TasksTableI>>(`/patients/api/v1/tasks`, body);
  }

  getAppointmentDetails(id: string): Observable<ResponseDto<AppointmentsTableI>> {
    return this.http.get<ResponseDto<AppointmentsTableI>>(`/patients/api/v1/appointments/${id}/details`);
  }
  cancelAppointment(id: string): Observable<ResponseDto<void>> {
    return this.http.post<ResponseDto<void>>(`/patients/api/v1/appointments/cancel`, {
      appointmentId: id,
    });
  }
  getCompanies(text: string): Observable<ResponseDto<any>> {
    return this.http.get<ResponseDto<any>>(`/patients/api/v1/patients/organizations`, { params: { text } });
  }
  getInsuranceCompanies(): Observable<ResponseDto<any>> {
    return this.http.get<ResponseDto<any>>(`/patients/api/v1/patients/insurance-companies`)
  }
  createRating(appointmentId: string, body: RatingFormI): Observable<ResponseDto<RatingFormI>> {
    return this.http.post<ResponseDto<RatingFormI>>(`/patients/api/v1/appointments/${appointmentId}/rating`, body);
  }
  getReadyRating(appointmentId: string): Observable<ResponseDto<RatingFormI>> {
    return this.http.get<ResponseDto<RatingFormI>>(`/patients/api/v1/appointments/${appointmentId}/rating`);
  }
  getSurveyForm(appointmentId: string): Observable<ResponseDto<SurveyDataI>> {
    return this.http.get<ResponseDto<SurveyDataI>>(`/patients/api/v1/appointments/${appointmentId}/survey`);
  }
  getSurveyFormPublic(slug: string): Observable<ResponseDto<SurveyDataI>> {
    const encodedRoute = encodeURIComponent(slug);
    return this.http.get<ResponseDto<SurveyDataI>>(`/portal/api/v1/surveys/bySlug/${encodedRoute}`);
  }
  saveSurveyForm(appointmentId: string, formData: FormData): Observable<ResponseDto<void>> {
    return this.http.post<ResponseDto<void>>(`/patients/api/v1/appointments/${appointmentId}/survey`, formData);
  }
  saveSurveyFormPublic(appointmentId: string, formData: FormData): Observable<ResponseDto<void>> {
    return this.http.post<ResponseDto<void>>(`/portal/api/v1/filled-surveys`, formData);
  }


}
