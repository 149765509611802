import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { getInTouchFormI } from '@shared';
import { AboutUsService } from '@shared/services/pages/about-us.service';
import { BaseComponent } from '@front/app/base-component';
import { ToastrService } from 'ngx-toastr';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactUsComponent extends BaseComponent implements OnInit {

  constructor(
    private readonly srv: AboutUsService,
    private readonly toastr: ToastrService,
    private readonly translate: TranslateService,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {
    super();
  }

  ngOnInit() {}

  createMessage(e: getInTouchFormI): void {
    this.recaptchaV3Service.execute('importantAction')
    .subscribe((token: string) => {
       this.srv
      .createContactQuestion({...e, clientRecaptchaResponse: token})
      .pipe(this.takeUntilDestroy())
      .subscribe((i) => {
        this.toastr.success(
          this.translate.instant('website.help.contact-us.message-saved')
        );
      });
    });
  }
}
