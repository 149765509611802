import { Injectable } from '@angular/core';
import {
  WorkshopsFilterI,
  PaginationDto,
  WorkshopsListI,
  WorkshopDataI,
} from '@shared/interfaces';
import * as moment from 'moment';
import { map, Observable, of, switchMap } from 'rxjs';
import { WorkshopsApiService } from '../api/workshops.api.service';

@Injectable({
  providedIn: 'root',
})
export class WorkshopsService {
  constructor(private readonly srv: WorkshopsApiService) {}

  getWorkshopsList(
    searchOptions: WorkshopsFilterI
  ): Observable<PaginationDto<WorkshopsListI>> {
    return this.srv.getWorkshopsList(searchOptions).pipe(
      map((d) => {
        return {
          list: d?.data.map((i) => {
            return {
              ...i,
              seminarDate: moment(i.seminarDate).format('DD.MM.YYYY'),
              seminarTime: moment(i.seminarDate).format('HH:mm'),
            };
          }) || [],
          total: d?.total || 0,
        };
      })
    );
  }

  getWorkshopBySlug(slug: string): Observable<WorkshopDataI> {
    return this.srv.getWorkshopBySlug(slug).pipe(
      map(
        d =>
          d?.data && {
            ...d.data,
            seminarDate: moment(d.data.seminarDate).format('MMMM DD, YYYY'),
          }
      ),
      switchMap(workshops =>
        workshops ? this.srv.getWorkshopsRelatedById(workshops.id).pipe(
          map(related => {
            return {
              ...workshops,
              related: (related?.data || []).map(i => ({
                ...i,
                seminarDate: moment(i.seminarDate).format('MMMM DD, YYYY'),
              })),
            };
          })
        ) : of({related: []} as any)
      )
    );
  }
}
