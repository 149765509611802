import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LeaveReviewFormI, OpinionsListI, PaginationI } from '@shared';
import { AboutUsService } from '@shared/services/pages/about-us.service';
import { BaseComponent } from '@front/app/base-component';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, map, Observable, switchMap, tap } from 'rxjs';
import { PageEvent } from '@angular/material/paginator';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'app-opinions',
  templateUrl: './opinions.component.html',
  styleUrls: ['./opinions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OpinionsComponent extends BaseComponent implements OnInit {
  opinionsList$: Observable<OpinionsListI[]>;
  private pageSize = 3;
  public total = 0;
  private searchOptions$ = new BehaviorSubject<PaginationI>({
    pageNumber: 0,
    pageSize: this.pageSize,
  });
  constructor(
    private readonly srv: AboutUsService,
    private readonly toastr: ToastrService,
    private readonly translate: TranslateService,

    private recaptchaV3Service: ReCaptchaV3Service
  ) {
    super();
    this.opinionsList$ = this.searchOptions$.pipe(
      switchMap(searchOptions => {
        return this.srv.getOpinions(searchOptions).pipe(
          tap(i => {
            this.total = i.total;
          }),
          map(i => {
            return i.list;
          })
        );
      })
    );
  }

  ngOnInit() {}

  createMessage(e: LeaveReviewFormI): void {
    this.recaptchaV3Service.execute('importantAction').subscribe((token: string) => {
      this.srv
        .createOpinion({ ...e, clientRecaptchaResponse: token })
        .pipe(this.takeUntilDestroy())
        .subscribe(i => {
          this.toastr.success(this.translate.instant('website.help.opinions.message-saved'));
        });
    });
  }

  onPageChanged(event: PageEvent): void {
    this.searchOptions$.next({ ...this.searchOptions$.value, pageNumber: event.pageIndex });
  }
}
