import { Component, OnInit } from '@angular/core';
import { DoctorsListI, Paths } from '@shared';
import { CitiesService } from '@shared/services/pages/cities.service';
import { DashboardService } from '@shared/services/pages/dashboard.service';
import { BaseComponent } from '@front/app/base-component';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { BehaviorSubject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-dashboard-doctors',
  templateUrl: './doctors.component.html',
  styleUrls: ['./doctors.component.scss'],
})
export class DashboardDoctorsComponent extends BaseComponent implements OnInit {
  doctorsList: DoctorsListI[] = [];
  Paths = Paths;

  private pageNumber$ = new BehaviorSubject<number>(0);
  private pageSize = 16;
  private gettedCount = 0;
  private total = this.pageSize;
  isLoading = true;

  constructor(
    private readonly srv: DashboardService,
    private readonly citiesSrv: CitiesService
  ) {
    super();
  }

  customOptions: OwlOptions = {
    loop: false,
    autoplay: false,
    center: false,
    dots: false,
    nav: true,
    autoHeight: true,
    autoWidth: true,
    navText: [
      '<i class="vcim-icon ic-arrow-down"></i>',
      '<i class="vcim-icon ic-arrow-down"></i>',
    ],
    responsive: {
      0: {
        items: 1,
        slideBy: 1,
      },
      378: {
        items: 2,
        slideBy: 2,
      },
      700: {
        items: 3,
        slideBy: 3,
      },
      900: {
        items: 4,
        slideBy: 4,
      },
      1200: {
        items: 6,
        slideBy: 4,
      },
    },
  };

  fieldTrackBy(_index: number, field: DoctorsListI) {
    return field.id;
  }

  ngOnInit() {
    this.citiesSrv.selectedCitiesObs
      .pipe(
        this.takeUntilDestroy(),
        tap((i) => {
          this.doctorsList = [];
          this.pageNumber$.next(0);
        })
      )
      .subscribe();

    this.pageNumber$
      .pipe(
        switchMap((pageNumber) => {
          return this.srv.getDoctorsList({
            pageNumber,
            pageSize: this.pageSize,
            cityIds: this.citiesSrv.selectedCities,
          });
        })
      )
      .subscribe((i) => {
        if (i) {
          if (this.pageNumber$.value === 0) {
            this.total = i.total;
            const emptyObjects = Array.from(
              Array(i.total),
              () => ({} as DoctorsListI)
            );
            this.doctorsList = emptyObjects;
            this.gettedCount = this.gettedCount + this.pageSize;

            i.list.forEach((data, index) => {
              this.doctorsList[index] = Object.assign(
                this.doctorsList[index],
                i.list[index]
              );
            });
            this.isLoading = false;
          } else {
            i.list.forEach((data, index) => {
              Object.assign(
                this.doctorsList[index + this.gettedCount],
                i.list[index]
              );
            });
            this.gettedCount = this.gettedCount + this.pageSize;
          }
        }
      });
  }

  ChangePage(page: any): void {
    if (
      page.startPosition &&
      (page.startPosition / page.slides.length) * page.slides.length +
        page.slides.length >=
        this.gettedCount &&
      this.gettedCount < this.total
    ) {
      this.pageNumber$.next(this.pageNumber$.value + 1);
    }
  }
}
