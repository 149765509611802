<section [class]="route.parent?.routeConfig?.path === 'pages' ? 'vcim-container container pt-8 pt-lg-16 mb-6 pb-10 pb-xl-16' : ''">
  <div *ngIf="pageInfo$ | async as pageInfo; else showSpinner">
    <div *ngIf="pageInfo.photo" class="mb-16 d-flex container-image">
      <loading-image
        [image]="'/portal/' + pageInfo.photoPath + '/' + pageInfo.photo"
        [styles]="{
          'object-fit': 'cover',
          'object-position': 'center'
        }"
        class="w-full h-full">
      </loading-image>
    </div>

    <div class="vcim-headline-1 mb-4">
      <ngx-dynamic-hooks
        [options]="{ sanitize: false }"
        class="vcim-inner-html inner-html-title"
        [content]="pageInfo.title"></ngx-dynamic-hooks>
    </div>

    <div class="row mb-16">
      <div class="col-12">
        <ngx-dynamic-hooks
          [options]="{ sanitize: false }"
          class="vcim-body-1 vcim-inner-html"
          [content]="pageInfo.description"></ngx-dynamic-hooks>
      </div>
    </div>
  </div>
</section>

<ng-template #showSpinner>
  <div style="height: 100px" class="my-10 d-flex justify-center">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
