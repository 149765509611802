<div class="flex flex-col w-full">
  <div class="d-flex">
    <span
      *ngFor="let holder of placeholders; index as i"
      [class.code-hidden]="isCodeHidden"
      [class.error]="error"
      class="px-2"
    >
      <input
        #input
        (click)="onClick($event)"
        (paste)="onPaste($event, i)"
        (input)="onInput($event, i)"
        (keydown)="onKeydown($event, i)"
        [type]="inputType"
        [disabled]="disabled"
        [attr.inputmode]="inputMode"
        [attr.autocapitalize]="autocapitalize"
        autocomplete="one-time-code"
        class="vcim-headline-2"
      />
    </span>
  </div>
  <span class="error-text mt-8 text-center" *ngIf="error">
    {{ "website.validations.wrong-code" | translate }}
  </span>
</div>
