<section class="vcim-container container pt-8 pt-lg-16 mb-6 pb-10 pb-xl-16">
  <ng-container *ngIf="vacancyInfo$ | async as vacancyInfo; else showSpinner">
    <div class="container-title px-2 mx-auto mb-8 mb-md-12 text-center">
      <div class="mb-3 vcim-headline-1"  *ngIf="!vacancyInfo.isDefault; else showTitle">
        <ngx-dynamic-hooks
          [options]="{ sanitize: false }"
          class="vcim-inner-html inner-html-title"
          [content]="vacancyInfo.item.title"></ngx-dynamic-hooks>
      </div>
  
    </div>
    <ng-template #showTitle>
      <h1 class="vcim-headline-1 mb-3">
        {{ 'website.vacancies.title' | translate }}
      </h1>
      <span class="vcim-subtitle">
        {{ 'website.vacancies.subtitle' | translate }}
      </span>
    </ng-template>

    <div class="row wrap-reverse md-flex-wrap mx-0 mx-md--4 mx-lg--8 mb-3 mb-md-6">
      <div class="col-12 col-md-5 col-lg-4 px-0 px-md-4 px-lg-8 mt-8 mt-md-0">
        <div class="w-full mb-3">
          <mat-form-field class="vcim-search md-field" subscriptSizing="dynamic" appearance="fill">
            <input
              type="text"
              matInput
              (input)="ChangeSearchText($any($event.target).value)"
              [placeholder]="'website.vacancies.search-for-vacancy' | translate"
              [matAutocomplete]="auto" />
            <i class="vcim-icon ic-search pl-4 f-20"></i>
          </mat-form-field>

          <mat-autocomplete #auto="matAutocomplete">
            <div *ngIf="foundVacalcies$ | async as foundVacalcies">
              <mat-option
                class="f-14 fw-600 vcim-headline-5"
                *ngFor="let option of foundVacalcies"
                [routerLink]="'/vacancies/' + option.slug | localize"
                [value]="option.title">
                <ngx-dynamic-hooks
                  [options]="{ sanitize: false }"
                  class="vcim-inner-html inner-html-title"
                  [content]="option.title"></ngx-dynamic-hooks>
              </mat-option>
            </div>
          </mat-autocomplete>
        </div>
        <div *ngIf="vacanciesList$ | async as vacanciesList; else showSpinner">
          <div *ngIf="categoriesList$ | async as categoriesList; else showSpinner">
            <mat-accordion [multi]="false" class="container-accordion d-flex flex-col">
              <mat-expansion-panel
                (opened)="onCategoryChange([category.id])"
                *ngFor="let category of categoriesList"
                hideToggle
                class="vcim-expansion-reset mb-3">
                <mat-expansion-panel-header class="p-4 animated">
                  <div class="d-flex justify-between items-center w-full">
                    <span class="vcim-headline-5">
                      <ngx-dynamic-hooks
                        [options]="{ sanitize: false }"
                        class="vcim-inner-html inner-html-title"
                        [content]="category.title"></ngx-dynamic-hooks>
                      </span>
                    <span class="item-icon flex justify-center items-center ml-4">
                      <i class="vcim-icon ic-arrow-down"></i>
                    </span>
                  </div>
                </mat-expansion-panel-header>
                <div class="min-small-spinner">
                  <div
                    infinite-scroll
                    [infiniteScrollDistance]="1"
                    [infiniteScrollThrottle]="300"
                    [infiniteScrollDisabled]="vacanciesScrollDisabled"
                    class="d-flex flex-col pb-2"
                    *ngIf="!(loading$ | async); else showSmallSpinner"
                    (scrolled)="toggleVacanciesScrolled()">
                    <div
                      class="vcim-body-1 pointer px-4 py-1"
                      [ngClass]="{
                        'active ': vacancy.slug === (slug$ | async)
                      }"
                      *ngFor="let vacancy of vacanciesList"
                      [routerLink]="'/vacancies/' + vacancy.slug | localize">
                      <ngx-dynamic-hooks
                        [options]="{ sanitize: false }"
                        class="vcim-inner-html inner-html-title"
                        [content]="removeHtmlTags(vacancy.title)"></ngx-dynamic-hooks>
                    </div>
                    
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-7 col-lg-8 px-0 px-md-4 px-lg-8">
        <div *ngIf="!vacancyInfo.isDefault" class="d-flex justify-end">
          <app-share-button appId="845217403389659" icon="ic-facebook" button="facebook" class="d-flex ml-2"></app-share-button>
          <app-share-button icon="ic-linkedin" button="linkedin" class="d-flex ml-2"></app-share-button>
          <app-share-button icon="ic-twitter" button="twitter" class="d-flex ml-2"></app-share-button>

          <button
            mat-icon-button
            class="d-flex items-center justify-center ml-2"
            [cdkCopyToClipboard]="link"
            (click)="copy()">
            <i class="vcim-icon d-flex ic-share f-20"> </i>
          </button>
          <textarea [(ngModel)]="link" class="visually-hidden"></textarea>
        </div>

        <div>
          <h2 *ngIf="vacancyInfo.isDefault && vacancyInfo.item.title" class="f-30 mb-8 text-center">
            <ngx-dynamic-hooks
              [options]="{ sanitize: false }"
              class="vcim-inner-html inner-html-title"
              [content]="vacancyInfo.item.title"></ngx-dynamic-hooks>
          </h2>
          <div class="d-flex">
            <ngx-dynamic-hooks
              [options]="{ sanitize: false }"
              class="vcim-body-1 vcim-inner-html"
              [content]="vacancyInfo.item.description"></ngx-dynamic-hooks>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</section>

<ng-template #showSpinner>
  <div style="height: 100px" class="my-10 d-flex justify-center">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>

<ng-template #showSmallSpinner>
  <div class="pb-4 d-flex justify-center">
    <mat-spinner style="height: 40px"></mat-spinner>
  </div>
</ng-template>
