<div class="container d-flex flex-col">
  <div class="p-3 header d-flex justify-between items-center">
    <h1 class="vcim-headline-4 p-3">
      {{ data.title | translate }}
    </h1>
    <button mat-icon-button class="d-flex items-center justify-center ml-2" [mat-dialog-close]>
      <i class="vcim-icon ic-close f-16"> </i>
    </button>
  </div>
  <div class="pb-10 p-6 d-flex flex-col">
    <span class="vcim-body-1 mb-8"> {{ data.description | translate }}</span>
  </div>

  <div class="row justify-center mt-auto items-center px-md-3 pb-6">
    <div class="col-6 px-4">
      <button mat-stroked-button type="button" color="primary" [mat-dialog-close] class="vcim-button color-primary sm-button w-full">
        {{ data.cancel | translate }}
      </button>
    </div>
    <div class="col-6 px-4">
      <button mat-flat-button color="primary" [mat-dialog-close]="true" class="vcim-button color-primary sm-button w-full">
        {{ data.confirm | translate }}
      </button>
    </div>
  </div>
</div>
