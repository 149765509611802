import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { BranchesListI, CompanyInfoI } from "@shared/interfaces";
import { GlobalService } from "@shared/services/global.service";
import { CitiesService } from "@shared/services/pages/cities.service";
import { BehaviorSubject, Observable, of, switchMap } from "rxjs";
import { Router } from '@angular/router';

@Component({
  selector: "app-contacts-info",
  templateUrl: "./contacts-info.component.html",
  styleUrls: ["./contacts-info.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class ContactsInfoComponent implements OnInit {
  companyInfo$: BehaviorSubject<CompanyInfoI>;
  branchesList$: Observable<BranchesListI[]> = of([]);
  
  constructor(private readonly srv: GlobalService,
    private readonly router: Router,
    private readonly citiesSrv: CitiesService
  ) {
    this.companyInfo$ = this.srv.companyInfo$;

    if (!this.router.routerState.snapshot.url.endsWith('404')) {
      this.branchesList$ = this.citiesSrv.selectedCitiesObs.pipe(
        switchMap((i) => (i.length ? this.citiesSrv.getBranchesList(i) : of([])))
      );
    }
  }

  ngOnInit() {

  }
}
