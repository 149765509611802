import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { SettingsService } from '@s';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@front/app/core';
import { BaseComponent } from '@front/app/base-component';

@Component({
  selector: 'app-mobile-translate',
  styleUrls: ['./mobile-translate.component.scss'],
  template: `
    <mat-expansion-panel hideToggle class="vcim-expansion-reset">
      <mat-expansion-panel-header>
        <button class="container-item vcim-body-2 p-3 w-full flex justify-between pointer">
          {{ 'website.layout.header.language' | translate }}
          <i class="vcim-icon ic-arrow-down ml-2"></i>
        </button>
      </mat-expansion-panel-header>
      <div class="flex flex-col pl-3 pr-5 pointer">
        <a
          *ngFor="let lang of this.langs"
          (click)="this.refresh(lang)"
          class="container-item vcim-body-2 py-2 w-full d-flex items-center fw-600">
          <img class="mr-3" [alt]="lang.short" [src]="'/assets/images/flags/' + lang.short + '.svg'" />
          {{ lang.value }}

          <i *ngIf="lang.short === settings.getLanguage()" class="vcim-icon ic-toastr-success f-16 ml-auto"> </i>
        </a>
      </div>
    </mat-expansion-panel>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileTranslateComponent extends BaseComponent {
  langs = this.settings.langs.sort((a, b) => a.order - b.order);
  protected currentUrl = '';
  constructor(
    private readonly router: Router,
    public auth: AuthService,
    public translate: TranslateService,
    public settings: SettingsService
  ) {
    super();
    this.setCurrentUrl();

    this.router.events
      .pipe(
        this.takeUntilDestroy(),
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe(() => {
        this.setCurrentUrl();
      });
  }

  private setCurrentUrl(): void {
    const languagePattern = new RegExp(`^\\/${this.settings.getLanguage()}(\\/|$)`, 'g');
    this.currentUrl = this.router.url.replace(languagePattern, '');
  }

  refresh(lang: (typeof this.settings.langs)[number]) {
    window.location.href = `/${lang.short}/` + this.currentUrl;
  }
}
