import { Injectable, Inject, PLATFORM_ID, Optional } from '@angular/core';
import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import {
  TranslateService as NGXTranslateService,
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
  LangChangeEvent,
} from '@ngx-translate/core';
import { MetaService } from '@ngx-meta/core';
import { Observable, of } from 'rxjs';

import { ILang } from './translates.interface';
import { SettingsService } from '@s';

export const LANG_LIST: ILang[] = [
  {key: 'en-US', value: 'English', short: 'en', order: 2},
  {key: 'ru-RU', value: 'Русский', short: 'ru', order: 1},
  {key: 'hy-AM', value: 'Հայերեն', short: 'hy', order: 0}
];

export const LANG_DEFAULT: ILang = LANG_LIST[2];
const STORAGE_LANG_NAME: string = 'langCode';

@Injectable()
export class TranslatesService {
  constructor(
    @Inject(PLATFORM_ID) private _platformId: Object,
    @Inject(DOCUMENT) private _document: any,
    @Optional() @Inject(REQUEST) private _request: any,
    @Inject(NGXTranslateService) private _translate: NGXTranslateService,
    @Inject(MetaService) private _meta: MetaService,
    private settings: SettingsService
  ) {
    this._translate.addLangs(LANG_LIST.map((lang: ILang) => lang.short));
    this._translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.settings.setLanguage(event.lang);
  });
  }

  private _getLanguage(): ILang {
    const lang = this.settings.getOptions().language;
    let language: ILang | null | undefined = this.settings.langs.find(l => l.short === lang);
    // let language: ILang | null | undefined = this._getFindLang(localStorage.getItem(STORAGE_LANG_NAME) || '');
    if (language) {
      return language;
    }
    if (isPlatformBrowser(this._platformId)) {
      language = this._getFindLang(this._translate.getBrowserLang() || '');
    }
    if (isPlatformServer(this._platformId)) {
      try {
        const reqLangList: string[] = this._request.headers['accept-language']
          .split(';')[0]
          .split(',');
        language = LANG_LIST.find(
          (lang: ILang) =>
            reqLangList.indexOf(lang.short) !== -1 || reqLangList.indexOf(lang.short) !== -1,
        );
      } catch (err) {
        language = LANG_DEFAULT;
      }
    }
    language = language || LANG_DEFAULT;
    this.settings.setLanguage(language.short);
    return language;
  }

  private _getFindLang(short: string): ILang | null {
    return short ? LANG_LIST.find((lang: ILang) => lang.short === short) || null : null;
  }

  private _setLanguage(lang: ILang): void {
    this._translate.use(lang.short).subscribe(() => {
      this._meta.setTag('og:locale', lang.short);
      this._document.documentElement.lang = lang.short;
    });
  }

  public changeLang(code: string): void {
    const lang: ILang | null = this._getFindLang(code);
    if (!lang || lang.short === this._translate.currentLang) {
      return;
    }
    this.settings.setLanguage(lang.short);
    this._setLanguage(lang);
  }

  public getLangList(): Observable<ILang[]> {
    return of(LANG_LIST);
  }

  public getCurrentLang(): string {
    return this._translate.currentLang;
  }
}

export class CommonMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    // if (
    //   params.key.match(/\w+\.\w+/) &&
    //   params.translateService.translations['ru'] &&
    //   !params.translateService.translations['ru'][params.key]
    // ) {
    //   console.warn(`Нехватает перевода для "${params.key}"`);
    // }
    return params.key;
  }
}
