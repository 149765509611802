<div class="h-full w-full flex" *ngIf="img; else showNoAvatar">
  <loading-image
    [image]="environment.baseUrl.patients + '/patients/' + imgPath + '/' + img"
    alt="no avatar"
    [styles]="{
      'object-fit': 'cover',
      'object-position': 'center'
    }"
    class="w-full h-full"
  >
  </loading-image>
</div>

<ng-template #showNoAvatar>
  <img class="no-avatar w-full h-full" src="/assets/images/no-avatar.svg" />
</ng-template>
