import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { SettingsService } from '@s';

@Component({
    selector: 'a-link',
    template: `
    <ng-container *ngIf="isAbsoluteLink(link); else relativeLink">
      <a [class]="classList" [href]="link">
        <ng-container [ngTemplateOutlet]="linkTpl"></ng-container>
      </a>
    </ng-container>
    <ng-template #relativeLink>
      <a [class]="classList" [routerLink]="link" [queryParams]="queryParams" [fragment]="anchorFragment">
        <ng-container [ngTemplateOutlet]="linkTpl"></ng-container>
      </a>
    </ng-template>
    <ng-template #linkTpl>
        <ng-content></ng-content>
    </ng-template>
  `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicRouterLinkComponent implements OnInit {

    @Input() link: string = '';
    @Input() queryParams: Record<string, any> = {};
    @Input() anchorFragment: string = '';
    @Input() classList: string = '';

    constructor(
        private readonly settings: SettingsService,
    ) {
    }

    ngOnInit() { }

    isAbsoluteLink(link: string) {
        return link.startsWith('http') || link.startsWith('mailto') || link.startsWith('tel');
    }
}
