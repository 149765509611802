import { Injectable } from '@angular/core';
import { ApplicationHttpClient } from '@s';
import {
  CustomPageDataI,
  ResponseDto,
} from '@shared/interfaces';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomPagesApiService {
  
  constructor(private http: ApplicationHttpClient) {}

  getCustomPageBySlug(slug: string): Observable<ResponseDto<CustomPageDataI>> {
    const encodedRoute = encodeURIComponent(slug);
    return this.http.get<ResponseDto<CustomPageDataI>>(
      `/portal/api/v1/custom-pages/bySlug/${encodedRoute}`
    );
  }
}
