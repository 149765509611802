import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { AuthService } from './auth.service';
import { SettingsService } from '@s';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(
    private auth: AuthService,
    private readonly router: Router,
    private settings: SettingsService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.authenticate(state.url);
  }

  canLoad(route: Route, segments: UrlSegment[]) {
    // get url from segments
    const url = segments.map((s) => s.path).join('/');
    return this.authenticate(`/${this.settings.getLanguage()}/` + url);
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree {
    return this.authenticate(state.url);
  }

  private authenticate(url: string = ''): boolean | UrlTree {
    if (this.auth.check()) {
      return true;
    } else {
      this.router.navigate([`/${this.settings.getLanguage()}/login`], {
        queryParams: { returnUrl: url },
      });
      return false;
    }
  }
}
