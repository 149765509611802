import { HttpClient, HttpHeaders, HttpParams, HttpHandler } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {map, Observable} from 'rxjs';
import { SettingsService } from './settings.service';
import { Translation } from '../types/entities';
type IRequest = HttpClient['get']
export interface IRequestOptions {
  headers?: HttpHeaders;
  observe?: 'body';
  params?: HttpParams | {
    [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
};
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
  body?: any;
}

interface Response<T> {
  data: T;
}
@Injectable()
export class ApplicationHttpClient extends HttpClient {
  constructor(httpHandler: HttpHandler, private settingsService: SettingsService) {
    super(httpHandler);
  }

  /**
   * GET request
   * @param {string} endPoint it doesn't need / in front of the end point
   * @param {IRequestOptions} options options of the request like headers, body, etc.
   * @returns {Observable<T>}
   */
  public Get<T>(endPoint: string, options?: IRequestOptions): Observable<T> {
    return this.get<Response<T>>(endPoint, options).pipe(map(response => {
      let trnsl = (response?.data as any)?.translations as Translation[];
      if(trnsl?.length) {
        if(trnsl.length !== this.settingsService.langs.length) {
          const langs = this.settingsService.langs
            .filter(lang => !trnsl.find(data => data.language === lang.short))
            .map(lng => ({language: lng.short})) as Translation[];
          trnsl.push(...langs);
        }
        ((response.data as any).translations as Translation[]) = trnsl.sort((a, b) => a.language < b.language ? 1 : a.language > b.language ? -1 : 0).filter(item => this.settingsService.langs.map(l=>l.short).includes(item.language));
      }
      return response?.data;
    }));
  }

  /**
   * POST request
   * @param {string} endPoint end point of the api
   * @param {Object} params body of the request.
   * @param {IRequestOptions} options options of the request like headers, body, etc.
   * @returns {Observable<T>}
   */
  public Post<T>(endPoint: string, params: Object, options?: IRequestOptions): Observable<T> {
    return this.post<Response<T>>(endPoint, params, options).pipe(map(response => {
      let trnsl = (response?.data as any)?.translations as Translation[];
      if(trnsl?.length) {
        if(trnsl.length !== this.settingsService.langs.length) {
          const langs = this.settingsService.langs
            .filter(lang => !trnsl.find(data => data.language === lang.short))
            .map(lng => ({language: lng.short})) as Translation[];
          trnsl.push(...langs);
        }
        ((response.data as any).translations as Translation[]) = trnsl.sort((a, b) => a.language < b.language ? 1 : a.language > b.language ? -1 : 0).filter(item => this.settingsService.langs.map(l=>l.short).includes(item.language));
      }
      return response?.data;
    }));
  }

  /**
   * PUT request
   * @param {string} endPoint end point of the api
   * @param {Object} params body of the request.
   * @param {IRequestOptions} options options of the request like headers, body, etc.
   * @returns {Observable<T>}
   */
  public Put<T>(endPoint: string, params: Object, options?: IRequestOptions): Observable<T> {
    return this.put<Response<T>>(endPoint, params, options).pipe(map(response => {
      let trnsl = (response?.data as any)?.translations as Translation[];
      if(trnsl?.length) {
        if(trnsl.length !== this.settingsService.langs.length) {
          const langs = this.settingsService.langs
            .filter(lang => !trnsl.find(data => data.language === lang.short))
            .map(lng => ({language: lng.short})) as Translation[];
          trnsl.push(...langs);
        }
        ((response.data as any).translations as Translation[]) = trnsl.sort((a, b) => a.language < b.language ? 1 : a.language > b.language ? -1 : 0).filter(item => this.settingsService.langs.map(l=>l.short).includes(item.language));
      }
      return response?.data;
    }));
  }

  /**
   * DELETE request
   * @param {string} endPoint end point of the api
   * @param {IRequestOptions} options options of the request like headers, body, etc.
   * @returns {Observable<T>}
   */
  public Delete<T>(endPoint: string, options?: IRequestOptions): Observable<T> {
    return this.delete<Response<T>>(endPoint, options).pipe(map(response => response.data));
  }
}