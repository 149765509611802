import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserTransferStateModule } from '@angular/platform-browser';
import { AppModule } from './app.module';
import { AppComponent } from './app.component';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { environment } from '@env/environment';

@NgModule({
  imports: [
    BrowserModule.withServerTransition({ appId: 'vardanants-app' }),
    BrowserTransferStateModule,
    AppModule,
    NgxGoogleAnalyticsModule.forRoot(environment.gtag),
    NgxGoogleAnalyticsRouterModule,
  ],
  bootstrap: [AppComponent],
})
export class AppBrowserModule {}