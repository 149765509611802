import { Nullable } from ".";

export interface NewsI {
    coverPhoto: Nullable<string>;
    photoPath: string;
    id: number;
    newsDate: string;
    slug: string;
    title: string;
    description: string;
  }
  export interface NewsDataI {
    id: number;
    description: string;
    title: string;
    coverPhoto: Nullable<string>;
    photoPath: string;
    relatedNews: NewsI[];
    newsDate: string;
  }

  export enum NewsShareType {
    facebook = 'facebook',
    twitter = 'twitter',
    linkedin = 'linkedin',
    share = 'share',
  }