import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { VacanciesListComponent } from './vacancies-list/vacancies-list.component';

export const childRoutes = [
  { path: '', component: VacanciesListComponent },
  { path: ':slug', component: VacanciesListComponent },
]
@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild(childRoutes),
  ],
  exports: [RouterModule],
})
export class VacanciesRoutingModule {}
