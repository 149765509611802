<mat-expansion-panel hideToggle class="vcim-expansion-reset">
  <mat-expansion-panel-header>
    <button class="container-item vcim-body-2 p-3 w-full flex justify-between pointer">
      {{ 'website.layout.location.city' | translate }}
      <i class="vcim-icon ic-arrow-down ml-2"></i>
    </button>
  </mat-expansion-panel-header>
  <div class="flex flex-col px-2 pointer">
    <form class="flex flex-col" [formGroup]="form">
      <div class="container-item vcim-body-2 w-full d-flex items-center fw-600" *ngFor="let checkbox of srv.allCities; index as i">
        <mat-checkbox
          (change)="handleCheckbox($event)"
          [value]="checkbox.id.toString()"
          [checked]="ids.value.includes(checkbox.id.toString())"
          class="vcim-checkbox color-cyan"
          color="primary">
          <span class="vcim-body-1">
            <ngx-dynamic-hooks
              [options]="{ sanitize: false }"
              class="vcim-inner-html inner-html-title"
              [content]="checkbox.title"></ngx-dynamic-hooks>
          </span>
        </mat-checkbox>
      </div>
    </form>
  </div>
</mat-expansion-panel>
