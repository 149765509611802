import { Injectable } from '@angular/core';
import { ResponseDto } from '@shared/interfaces';
import { map } from 'rxjs';
import { AppointmentFile, AppointmentsTableI, AppointmentsFilters, OrderConfirmModel, PersonalInfoFormI } from '@shared/interfaces/personal-data.interface';
import {
  AttachmentsFilterI,
  AttachmentsTableI,
  PersonalInfoI,
  RequestsTasksFilterI,
  TasksTableI,
} from '@shared/interfaces/personal-data.interface';
import { Observable } from 'rxjs';
import { ApplicationHttpClient } from '@s';
import { Token } from '@front/app/core/authentication/interface';

@Injectable({
  providedIn: 'root',
})
export class PersonalDataApiService {
  constructor(private http: ApplicationHttpClient) {}

  getPersonalInfo(): Observable<ResponseDto<PersonalInfoI>> {
    return this.http.get<ResponseDto<PersonalInfoI>>(`/patients/api/v1/patients/details`);
  }

  getAppointmentsFilters(): Observable<ResponseDto<PersonalInfoI>> {
    return this.http.get<ResponseDto<PersonalInfoI>>(`/patients/api/v1/appointments/getAppointmentsFilters`);
  }

  getAppointmentsFiles(id: string): Observable<AppointmentFile[]> {
    return this.http.get<ResponseDto<AppointmentFile[]>>(`/patients/api/v1/appointments/${id}/files`).pipe(map(res => res?.data));
  }

  getAppointments(filters: AppointmentsFilters = { page: 1, size: 10 }): Observable<ResponseDto<AppointmentsTableI[]>> {
    return this.http.get<ResponseDto<AppointmentsTableI[]>>(`/patients/api/v1/appointments`, {
      params: { ...filters },
    });
  }

  getAppointmentByOrderId(orderId: string): Observable<AppointmentsTableI> {
    return this.http.Get<AppointmentsTableI>(`/patients/api/v1/appointments/byOrderId/${orderId}`);
  }

  orderPaymentConfirmed(order: OrderConfirmModel): Observable<any> {
    return this.http.Post<any>(`/patients/api/v1/payments/orderPaymentConfirmed`, order);
  }

  getRequestsTasks(searchOptions: RequestsTasksFilterI): Observable<ResponseDto<TasksTableI[]>> {
    return this.http.get<ResponseDto<TasksTableI[]>>(`/patients/api/v1/tasks`, {
      params: {
        page: searchOptions.pageNumber + 1,
        size: searchOptions.pageSize,
        completed: searchOptions.tab === 1 ? true : searchOptions.tab === 2 ? false : '',
      },
    });
  }
  getAttachmentsList(searchOptions: AttachmentsFilterI): Observable<ResponseDto<AttachmentsTableI[]>> {
    return this.http.get<ResponseDto<AttachmentsTableI[]>>(`/patients/api/v1/patient-attachments`, {
      params: {
        page: searchOptions.pageNumber + 1,
        size: searchOptions.pageSize,
      },
    });
  }

  uploadAttachment(file: File) {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post<any>(`/patients/api/v1/patient-attachments`, formData, {
      params: {
        name: file.name,
      },
    });
  }

  attach(appointmentId: string, id: string): Observable<ResponseDto<Token>> {
    return this.http.post<ResponseDto<Token>>(`/patients/api/v1/appointments/${appointmentId}/files/${id}/attach`, {});
  }

  switchAccount(id: number): Observable<ResponseDto<Token>> {
    return this.http.post<ResponseDto<Token>>(`/patients/api/v1/patients/switch-to-account`, { patientId: id });
  }

  downLoadAttachment(id: number) {
    return this.http.get<Blob>(`/patients/api/v1/patient-attachments/download?id=${id}`, {
      responseType: 'blob' as 'json',
    });
  }

  deleteAttachment(id: number) {
    return this.http.delete<Blob>(`/patients/api/v1/patient-attachments/${id}`);
  }

  downLoadFile(appointmentId: string, id: string) {
    return this.http.get<Blob>(`/patients/api/v1/appointments/${appointmentId}/files/${id}/download`, {
      responseType: 'blob' as 'json',
    });
  }
  editAttachmentName(id: number, name: string): Observable<ResponseDto<AttachmentsTableI>> {
    return this.http.put<ResponseDto<AttachmentsTableI>>(`/patients/api/v1/patient-attachments/${id}`, {
      name,
      id,
    });
  }
  uploadAvatar(file: File): Observable<ResponseDto<{ file: string; path: string }>> {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post<any>(`/patients/api/v1/patients/uploadPhoto`, formData, {
      params: {
        file: file.name,
      },
    });
  }
  updatePersonalInfo(data: PersonalInfoFormI): Observable<ResponseDto<PersonalInfoI>> {
    return this.http.post<ResponseDto<PersonalInfoI>>(`/patients/api/v1/patients/update-account`, data);
  }

}
