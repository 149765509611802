import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ServicesListComponent } from './services-list/services-list.component';
import { PriceItemComponent } from './price-item/price-item.component';

export const childRoutes = [
  { path: '', component: ServicesListComponent },
  { path: ':slug', component: ServicesListComponent },
  { path: 'pages/:page', component: ServicesListComponent },
  { path: ':slug/price', component: PriceItemComponent },
];
@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(childRoutes)],
  exports: [RouterModule],
})
export class ServicesRoutingModule {}
