import { isPlatformBrowser } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Input, PLATFORM_ID } from '@angular/core';
import { ProtocolService } from '@s';

@Component({
  selector: 'loading-image',
  templateUrl: './loading-image.component.html',
  styleUrls: ['./loading-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingImageComponent {
  _image: string | null = null;
  isServer = false;
  @Input()
  get image(): string | null {
    return this._image ? this.ps.getUrl(this._image) : '';
  }

  set image(value: string | null) {
    this._image = value;
  }
  @Input() styles = {};
  @Input() public alt?: string = '';
  @Input() public autoHeight?: boolean = false;

  isLoading: boolean = true;

  constructor(
    public ps: ProtocolService,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {
    this.isServer = !isPlatformBrowser(this.platformId);
    setTimeout(() => {
      this.isLoading = true;
    });
  }

  hideLoader() {
    this.isLoading = false;
  }
}
