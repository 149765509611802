<section class="vcim-container container pt-8 pt-lg-16 mb-6 pb-10 pb-xl-16">
  <div class="container-title mb-6 mb-xl-16 text-center">
    <h1 class="vcim-headline-1 mb-3">
      {{ 'website.help.faq.title' | translate }}
    </h1>
    <span class="vcim-subtitle">
      {{ 'website.help.faq.subtitle' | translate }}
    </span>
  </div>

  <div class="row wrap-reverse md-flex-wrap items-start mx-0 md-md--4 mx-xl--8">
    <div class="col-12 col-md-6 col-lg-5 col-xl-4 px-0 px-md-4 px-xl-8 mb-12">
      <div class="container-title mb-6 mb-xl-16 text-center">
        <h2 class="vcim-headline-3 mb-3">
          {{ 'website.help.faq.question-title' | translate }}
        </h2>
        <span class="vcim-subtitle f-14">
          {{ 'website.help.faq.question-subtitle' | translate }}
        </span>
      </div>

      <div>
        <get-in-touch-form (save)="createMessage($event)"></get-in-touch-form>
      </div>
    </div>

    <div class="col-12 col-md-6 col-lg-7 col-xl-8 px-0 px-md-4 px-xl-8 mb-12">
      <div class="mb-10" *ngIf="faqInfos$ | async as faqInfos">
        <div class="mb-10" *ngFor="let faq of faqInfos; let i = index">
          <h3 class="vcim-headline-3 mb-4 text-center">{{ faq.category }}</h3>

          <mat-accordion class="vcim-accordion faq-accordion d-flex flex-col">
            <mat-expansion-panel hideToggle class="" *ngFor="let item of faq.list; let index = index">
              <mat-expansion-panel-header class="px-0 py-4">
                <mat-panel-title class="vcim-headline-4 d-flex justify-between items-center">
                  <ngx-dynamic-hooks
                    [options]="{ sanitize: false }"
                    class="vcim-inner-html inner-html-title"
                    [content]="item.title"></ngx-dynamic-hooks>
                  <i class="vcim-icon ic-arrow-down"></i>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="vcim-body-1 pb-3 pb-md-6 vcim-inner-html">
                <ngx-dynamic-hooks [options]="{ sanitize: false }" [content]="item.description"></ngx-dynamic-hooks>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>

        <mat-paginator
          class="vcim-paginator-container"
          *ngIf="total"
          [length]="total || 0"
          [pageSize]="pageSize"
          [hidePageSize]="true"
          showFirstLastButtons
          (page)="onPageChanged($event)">
        </mat-paginator>
      </div>
    </div>
  </div>
</section>
