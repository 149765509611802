<section class="vcim-container container pt-8 pt-lg-16 mb-6 pb-10 pb-xl-16">
  <div class="container-title px-2 mx-auto mb-8 mb-md-12 text-center">
    <h1 class="vcim-headline-1">
      {{ 'website.help.requisites.title' | translate }}
    </h1>
  </div>

  <div *ngIf="requisitesList$ | async as requisitesList; else showSpinner">
    <mat-card appearance="outlined" class="container-card px-4 px-md-10 py-md-6">
      <div
        *ngFor="let item of requisitesList; let i = index"
        class="container-card-item d-flex justify-between md-items-center py-3 flex-col md-flex-row">
        <span class="fw-700 vcim-body-1">
          <ngx-dynamic-hooks
            [options]="{ sanitize: false }"
            class="vcim-inner-html inner-html-title"
            [content]="item.title"></ngx-dynamic-hooks>
        </span>
        <span class="pt-4 pt-md-0 pl-md-10 vcim-body-1 text-right" [ngSwitch]="item.type">
          <ng-container *ngSwitchCase="RequisitesType.phone">
            <a rel="noreferrer" target="_blank" [href]="'tel:' + item.description">
              {{ item.description }}
            </a>
          </ng-container>
          <ng-container *ngSwitchCase="RequisitesType.email">
            <a rel="noreferrer" target="_blank" [href]="'mailto:' + item.description">
              {{ item.description }}
            </a>
          </ng-container>
          <ng-container *ngSwitchCase="RequisitesType.link">
            <a rel="noreferrer" target="_blank" [href]="item.description">
              {{ item.description }}
            </a></ng-container
          >
          <ng-container *ngSwitchDefault> {{ item.description }} </ng-container>
        </span>
      </div>
    </mat-card>
  </div>
</section>

<ng-template #showSpinner>
  <div style="height: 100px" class="my-10 d-flex justify-center">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
