<div class="container d-flex">
  <form [formGroup]="form" (ngSubmit)="saveForm()" *ngIf="!(completed$ | async); else showCompleted">
    <div class="p-6 header d-flex justify-between items-center">
      <h1 class="vcim-headline-3 p-3">
        {{ 'website.personal-data.requests-tasks.create.popup.title' | translate }}
      </h1>
      <button mat-icon-button class="d-flex items-center justify-center ml-2" type="button" (click)="dialogRef.close()">
        <i class="vcim-icon ic-close f-16"> </i>
      </button>
    </div>
    <div class="p-6 pb-10 p-md-10 d-flex flex-col">
      <h2 class="vcim-headline-3 mb-4">
        {{ data.title }}
      </h2>
      <span class="vcim-body-1 mb-8"> {{ 'website.personal-data.requests-tasks.create.popup.subtitle' | translate }}</span>

      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <mat-datepicker-toggle class="disabled-ripple" [disableRipple]="true" matPrefix [for]="picker1">
          <i matDatepickerToggleIcon class="d-flex grey vcim-icon ic-thin-date f-24"> </i>
        </mat-datepicker-toggle>
        <input placeholder="00/00/0000" [min]="minDate" formControlName="date" matInput [matDatepicker]="picker1" />
        <mat-datepicker #picker1></mat-datepicker>
      </mat-form-field>
    </div>

    <div class="row justify-center mt-auto items-center px-2 px-md-6 pb-10">
      <div class="col-12 mb-4 mb-sm-0 col-sm-6 px-4">
        <button mat-stroked-button type="button" color="primary" [mat-dialog-close] class="vcim-button color-primary sm-button w-full">
          {{ 'website.personal-data.requests-tasks.create.popup.cancel' | translate }}
        </button>
      </div>
      <div class="col-12 col-sm-6 mb-sm-0 px-4">
        <button mat-flat-button color="primary" [disabled]="!!form.invalid" class="vcim-button color-primary sm-button w-full">
          {{ 'website.personal-data.requests-tasks.create.popup.next' | translate }}
        </button>
      </div>
    </div>
  </form>
</div>

<ng-template #showCompleted>
  <div class="header p-10 d-flex flex-col items-center justify-center">
    <div class="icon mb-6 d-flex justify-center items-center">
      <i class="vcim-icon ic-toastr-success d-flex f-24"> </i>
    </div>
    <div class="vcim-headline-3 text-center mb-6">{{ 'website.personal-data.requests-tasks.create.popup.completed' | translate }}</div>
    <div class="text-center vcim-body-1">{{ 'website.personal-data.requests-tasks.create.popup.completed-description' | translate }}</div>
  </div>
</ng-template>
