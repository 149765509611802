<div class="vcim-container container pt-8 pt-lg-16 mb-6 pb-10 pb-xl-16">
  <div class="container-title px-2 mx-auto mb-8 text-center">
    <h1 class="vcim-headline-1 mb-3">
      <ngx-dynamic-hooks [options]="{ sanitize: false }" class="vcim-inner-html inner-html-title" [content]="title"></ngx-dynamic-hooks>
    </h1>
    <span class="vcim-subtitle">
      <ngx-dynamic-hooks [options]="{ sanitize: false }" class="vcim-inner-html inner-html-title" [content]="subtitle"></ngx-dynamic-hooks>
    </span>
  </div>

  <div class="content px-0 px-lg-37">
    <div *ngIf="description" class="info-card p-6 mb-6">
      <ngx-dynamic-hooks [options]="{ sanitize: false }" class="vcim-body-2 vcim-inner-html" [content]="description"></ngx-dynamic-hooks>
    </div>
    <ng-container *ngIf="survey$ | async as survey; else showError">
      <form
        [formGroup]="form"
        (ngSubmit)="saveForm()"
        *ngIf="!(isLoading$ | async); else showSpinner"
        class="form pt-2 pb-6 px-4 px-md-8 flex flex-col">
        <div class="row mx--4 pt-4" *ngIf="route.snapshot.data?.public">
          <!-- add required form fields for fullName, birthDate, phone, email, height, weight -->
          <mat-form-field class="col-12 col-sm-6 px-4 pb-1">
            <mat-label>{{ 'website.personal-data.appointments.survey.full-name' | translate }}</mat-label>
            <input matInput formControlName="fullName" />
            <mat-error *ngIf="form.get('fullName')!.hasError('required')">
              {{ 'website.validations.required' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-12 col-sm-6 px-4 pb-1">
            <mat-label>{{ 'website.personal-data.appointments.survey.birth-date' | translate }}</mat-label>
            <input matInput [max]="maxDate" [min]="minDate" [matDatepicker]="picker" formControlName="birthDate" />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error *ngIf="form.get('birthDate')!.hasError('required')">
              {{ 'website.validations.required' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-12 col-sm-6 px-4 pb-1">
            <mat-label>{{ 'website.personal-data.appointments.survey.phone' | translate }}</mat-label>
            <input mask="+000 (00) 00 00 00" matInput formControlName="phone" />
            <mat-error *ngIf="form.get('phone')!.hasError('required')">
              {{ 'website.validations.required' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-12 col-sm-6 px-4 pb-1">
            <mat-label>{{ 'website.personal-data.appointments.survey.email' | translate }}</mat-label>
            <input matInput formControlName="email" />
            <mat-error *ngIf="form.get('email')?.hasError('required')">
              {{ 'website.validations.required_field' | translate : { field: translate.instant('website.help.get-in-touch.email') } }}
            </mat-error>
            <mat-error *ngIf="form.get('email')?.hasError('email')">
              {{ 'website.validations.invalid_email' | translate }}
            </mat-error>
          </mat-form-field>
          <!-- <mat-form-field class="col-12 col-sm-6 px-4 pb-1">
            <mat-label>{{ 'website.personal-data.appointments.survey.height' | translate }}</mat-label>
            <input type="number" matInput formControlName="height" />
            <mat-error *ngIf="form.get('height')!.hasError('required')">
              {{ 'website.validations.required' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-12 col-sm-6 px-4 pb-1">
            <mat-label>{{ 'website.personal-data.appointments.survey.weight' | translate }}</mat-label>
            <input type="number" matInput formControlName="weight" />
            <mat-error *ngIf="form.get('weight')!.hasError('required')">
              {{ 'website.validations.required' | translate }}
            </mat-error>
          </mat-form-field> -->
        </div>
        <ng-container *ngFor="let node of survey">
          <app-survey-item [list]="list" [form]="form" [node]="node"></app-survey-item>
        </ng-container>

        <div class="row justify-center pt-16 items-center">
          <button
            mat-stroked-button
            color="primary"
            type="button"
            (click)="reset()"
            class="vcim-button color-primary md-button mx-4 mb-4 w-full w-sm-auto">
            {{ 'website.personal-data.appointments.survey.clear-all' | translate }}
          </button>
          <button
            mat-flat-button
            color="primary"
            class="vcim-button color-primary md-button mx-4 mb-4 w-full w-sm-auto">
            {{ 'website.personal-data.appointments.survey.submit' | translate }}
          </button>
        </div>
      </form>
    </ng-container>
  </div>
</div>

<ng-template #showSpinner>
  <div style="height: 100px" class="my-10 d-flex justify-center">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>

<ng-template #showError>
  <div class="py-4 text-center">
    {{ 'website.personal-data.appointments.survey.not-aviable' | translate }}
  </div>
</ng-template>
