<div class="container">
  <div class="p-6 header d-flex justify-between items-center">
    <h1 class="vcim-headline-3 p-3">
      {{ 'website.personal-data.appointments.create.popup.title' | translate }}
    </h1>
    <button mat-icon-button class="d-flex items-center justify-center ml-2" type="button" (click)="dialogRef.close()">
      <i class="vcim-icon ic-close f-16"> </i>
    </button>
  </div>
  <div class="p-6 pb-10 p-md-10">
    <h2 class="vcim-headline-3 mb-25 break-word">
      {{ data.title }}
    </h2>

    <div class="flex flex-col mb-16">
      <div class="border-item flex justify-between vcim-body-1 mb-4">
        <span class="grey pr-2"> {{ 'website.personal-data.appointments.create.popup.branch' | translate }} </span>
        <ng-container *ngIf="showForm$ | async">
          <mat-select
            [placeholder]="'website.personal-data.appointments.create.popup.select-branch' | translate"
            *ngIf="data.branches.length"
            class="select w-auto"
            [(value)]="data.branchId">
            <mat-option class="f-14 fw-600 vcim-headline-5" *ngFor="let branch of data.branches" [value]="branch.id">
              <ngx-dynamic-hooks
                [options]="{ sanitize: false }"
                class="vcim-inner-html inner-html-title"
                [content]="removeHtmlTags(branch.title)"></ngx-dynamic-hooks>
            </mat-option>
          </mat-select>
        </ng-container>
        <ng-container *ngIf="!(showForm$ | async)">
          <span class="pr-2">
            <ngx-dynamic-hooks
              [options]="{ sanitize: false }"
              class="vcim-inner-html inner-html-title"
              [content]="removeHtmlTags(data.branchName)"></ngx-dynamic-hooks>
          </span>
        </ng-container>
      </div>
      <div class="border-item flex justify-between vcim-body-1 mb-4">
        <span class="grey pr-2"> {{ 'website.personal-data.appointments.create.popup.price' | translate }} </span>
        <span class="pr-2">
          {{ data.price | thousands }}
          AMD
        </span>
      </div>
      <div class="info-panel d-flex f-14" *ngIf="data.survey">
        <i class="vcim-icon ic-toastr-error f-24 d-flex pr-4"> </i>
        {{ 'website.personal-data.appointments.create.popup.survey' | translate }}
      </div>
    </div>
  </div>

  <div class="row justify-center mt-auto items-center px-2 px-md-6 pb-10">
    <div class="col-12 mb-4 mb-sm-0 col-sm-6 px-4">
      <button mat-stroked-button color="primary" [mat-dialog-close] class="vcim-button color-primary sm-button w-full">
        {{ 'website.personal-data.appointments.create.popup.cancel' | translate }}
      </button>
    </div>
    <div class="col-12 col-sm-6 mb-sm-0 px-4">
      <button
        mat-flat-button
        color="primary"
        [disabled]="!data.branchId"
        [mat-dialog-close]="data"
        class="vcim-button color-primary sm-button w-full">
        {{ 'website.personal-data.appointments.create.popup.next' | translate }}
      </button>
    </div>
  </div>
</div>
