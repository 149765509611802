<div *ngIf="bannerData$ | async as bannerData" class="container">
  <owl-carousel-o class="h-full flex global-banner" [options]="customOptions">
    <ng-container *ngFor="let slide of bannerData; let index; fieldTrackBy">
      <ng-template class="slide" carouselSlide [id]="index.toString()">
        <div class="slide flex flex-col sm-flex-row w-full" [style]="{backgroundColor: '#' + slide.background}">
          <div class="col-12 col-sm-6 h-full content-img">
            <div
              class="d-none sm-d-block content-img-slide"
              [ngStyle]="{
            'background-image': 'url(' +  ps.getUrl('/portal/' + slide.photoPath + '/' + slide.photo) + ')',
          }"></div>
            <loading-image
              [image]="'/portal/' + slide.photoPath + '/' + slide.photo"
              [alt]="slide.title || ''"
              [styles]="{
                'object-fit': 'cover',
                'object-position': 'center'
              }"
              class="w-full h-full sm-d-none">
            </loading-image>
          </div>
          <div class="slide-text vcim-container pt-8 pb-20 py-md-16 pt-lg-20 pb-lg-25 sm-h-auto">
            <div class="col-12 col-sm-6 h-full pr-4">
              <div class="flex flex-col pr-md-10 h-full ">
                <h1 class="vcim-title-1 pb-12">{{ slide.title }}</h1>
                <p class="vcim-body-1 mb-12 mb-xl-16">{{ slide.description }}</p>

                <span *ngIf="slide.buttonLink && slide.buttonText" class="auth-btns d-flex mt-auto">
                  <button  mat-flat-button color="primary" class="vcim-button md-button color-primary mr-6" [routerLink]="slide.buttonLink | localize">
                    {{ slide.buttonText }}
                  </button>                  
                </span>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </ng-container>
  </owl-carousel-o>
</div>
