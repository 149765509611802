import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError, BehaviorSubject, of, mergeMap, iif } from 'rxjs';
import { catchError, tap, switchMap, filter, take, finalize, } from 'rxjs/operators';
import { Token, TokenService } from '@core/authentication';
import { ApplicationHttpClient, BASE_URL } from '@s';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private hasHttpScheme = (url: string) => new RegExp('^http(s)?://', 'i').test(url);
  apiUrl: string = '';

  private isRefreshingToken = false;
  private tokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

  constructor(
    private tokenService: TokenService,
    protected http: ApplicationHttpClient,
    private readonly router: Router,
    @Optional() @Inject(BASE_URL) private baseUrl?: { [key: string]: string }
  ) { }

  private addTokenToRequest(request: HttpRequest<any>): HttpRequest<any> {
    const refresh = request.url.includes('/refresh-token');
    // refresh && (this.debug = true);
    return request.clone({
      headers: request.headers.append('Authorization', this.tokenService.getBearerToken(refresh)),
      // withCredentials: true,
    });
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    if (this.tokenService.valid()) { // && this.shouldAppendToken(request.url)
      return next
        .handle(
          this.addTokenToRequest(request)
        )
        .pipe(
          mergeMap((value: any) =>
            iif(() => {
              if (value?.body?.code === -7) {
                return true
              }
              return false;
            },
              throwError(() => -7),
              of(value)
            )),
          catchError((resp: any) => {
            if (resp !== -7) {
              return throwError(() => resp);
            }
            if (request.url.endsWith('/patients/api/v1/auth/refresh-token')) {
              // Handle the error when refreshing the token (e.g., log or throw)
              const refreshError = 'Failed to refresh token';
              this.tokenService.clear();
              this.router.navigateByUrl('/auth/login');
              return throwError(() => refreshError);
            }
            if (!this.isRefreshingToken) {
              // Token expired, try to refresh the token and retry the request
              this.isRefreshingToken = true;
              this.tokenSubject.next(null);

              return this.http.Post<Token>('/patients/api/v1/auth/refresh-token', {}).pipe(
                switchMap((newToken: Token) => {
                  this.tokenService.save(newToken);
                  this.tokenSubject.next(newToken.accessToken);
                  request = this.addTokenToRequest(request);
                  return next.handle(request);
                }),
                finalize(() => {
                  this.isRefreshingToken = false;
                })
              ) as Observable<HttpEvent<any>>;
            } else {
              // Token refresh is already in progress, wait for the new token
              return this.tokenSubject.pipe(
                filter((token) => token !== null),
                take(1),
                switchMap(() => {
                  request = this.addTokenToRequest(request);
                  return next.handle(request);
                })
              );
            }
          }),
          catchError((error: HttpErrorResponse) => {
            return throwError(() => error);
          }),
          // tap(() => handler())
        );
    }
    // TODO: temporary solution for 401 login password popup of 401
    return next.handle(request.clone({
      headers: request.headers.append('x-requested-with', 'XMLHttpRequest'),
    })).pipe();
  }
}
