import { Route, Routes } from '@angular/router';
import { EnterCodeType } from '@shared/interfaces/register.interface';
import { fromByteArray, toByteArray } from 'base64-js';
import { LANG_LIST } from '../translates/translates.service';
import { ISeo, ISeoTranslation } from '@s';

export const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export class Base64 {
  static encode(plainText: string): string {
    return fromByteArray(pack(plainText)).replace(/[+/=]/g, m => {
      return { '+': '-', '/': '_', '=': '' }[m] as string;
    });
  }

  static decode(b64: string): string {
    b64 = b64.replace(/[-_]/g, m => {
      return { '-': '+', '_': '/' }[m] as string;
    });
    while (b64.length % 4) {
      b64 += '=';
    }

    return unpack(toByteArray(b64));
  }
}

export function pack(str: string) {
  const bytes: any = [];
  for (let i = 0; i < str.length; i++) {
    bytes.push(...[str.charCodeAt(i)]);
  }

  return bytes;
}

export function unpack(byteArray: any) {
  return String.fromCharCode(...byteArray);
}

export const base64 = { encode: Base64.encode, decode: Base64.decode };

export function capitalize(text: string): string {
  return text.substring(0, 1).toUpperCase() + text.substring(1, text.length).toLowerCase();
}

export function currentTimestamp(): number {
  return Math.ceil(new Date().getTime() / 1000);
}

export function timeLeft(expiredAt: number): number {
  return Math.max(0, expiredAt - currentTimestamp());
}

export function filterObject<T extends Record<string, unknown>>(obj: T) {
  return Object.fromEntries(
    Object.entries(obj).filter(([, value]) => value !== undefined && value !== null)
  );
}

export function isEmptyObject(obj: Record<string, any> | null) {
  return Object.keys(obj || {}).length === 0;
}

export const acceptedFiles = '.png, .jpeg, .jpg, .svg, .html';

export function phoneOrEmail(value: string): EnterCodeType {
  if (emailRegex.test(value)) {
    return EnterCodeType.email;
  }
  return EnterCodeType.phone;
}

export function ssrDisabled(routes: Route[], sections: string[]): boolean {
  for (const tree of routes) {

    if (sections.length === 0) {
      // No more sections to check, but we have a valid path
      return true;
    }

    const currentSection = sections[0];

    const samePath = tree.path === '';
    const children = tree.children || tree.data?.routes;

    if (samePath && children?.length) {
      if(ssrDisabled(children, sections)) return true;
    }

    // Check if the current section matches the path of the tree node
    if (tree.path === currentSection) {
      if (tree.data?.ssr === false) {
        return true;
      }

      const [, ...nextSections] = sections;
      if(!nextSections.length) return false;

      // Check the children of the current node first
      if (tree.children) {
        if (ssrDisabled(tree.children, nextSections)) return true;
      };

      // If children do not have their own children, check data.routes
      if (tree.data?.routes) {
        if (ssrDisabled(tree.data.routes, nextSections)) return true;
      };
    }
  }

  return false;
}

// Recursive function to flatten the nested routes and extract ISeo information
export function getRoutesFromTree(routes: Routes | undefined): Partial<ISeo>[] {
  const result: Partial<ISeo>[] = [];

  if (!routes || !routes.length) {
    return result;
  }

  function flattenRoutesRecursive(currentRoutes: Routes, parentRoute: string = '') {
    for (const route of currentRoutes) {
      // Check if the route has nested routes
      const nestedRoutes = route.data?.routes;
      const nestedChildren = route.children;

      if ((nestedRoutes && nestedRoutes.length) || (nestedChildren && nestedChildren.length)) {
        // Concatenate the current route with the parent route
        const currentRoute = parentRoute + (route.path ? '/' + route.path : '');
        // Recursively flatten the nested routes and concatenate the results
        flattenRoutesRecursive(nestedRoutes || nestedChildren, currentRoute);
      }

      // Extract route information to create the ISeo object
      const routePath = route.path || '';
      if (routePath === '**') continue; 
      const currentRoute = parentRoute + (routePath ? '/' + routePath : '');

      // Replace :slug with *
      // Replace ** at the end of the string with *

      let transformedRoute = currentRoute.replace(/\/:[\w-]+.*$/, '/*').replace(/\/\*\*$/, '/*');

      // Convert '' route to '/'
      transformedRoute = transformedRoute === '' ? '/' : transformedRoute;

      const existingRouteIndex = result.findIndex((item) => item.route === transformedRoute);
      if (existingRouteIndex === -1) {

        const routeTranslations: ISeoTranslation[] = LANG_LIST.map((language) => ({
          language: language.short,
          title: '',
          description: '',
          tags: '',
        }));

        result.push({
          translations: routeTranslations,
          route: transformedRoute,
          global: true,
          include: false,
        });
      }
    }
  }

  flattenRoutesRecursive(routes);
  return result;
}

export function mergeAndFilterSeoData(seoData: Partial<ISeo>[], flattenedRoutes: Partial<ISeo>[]): Partial<ISeo>[] {
  // Step 1: Filter seoData based on the provided logic
  const filteredSeoData = seoData.filter((item) => {
    const existsInFlattenedRoutes = flattenedRoutes.some((flattenedItem) => flattenedItem.route === item.route);
    return existsInFlattenedRoutes || item.global === false;
  });

  // Step 2: Concatenate filteredSeoData with unique items from flattenedRoutes
  const uniqueFlattenedRoutes = flattenedRoutes.filter((item) => {
    const existsInFilteredSeoData = filteredSeoData.some((filteredItem) => filteredItem.route === item.route);
    return !existsInFilteredSeoData;
  });

  // Step 3: Concatenate filteredSeoData with the uniqueFlattenedRoutes array
  return filteredSeoData.concat(uniqueFlattenedRoutes);
}