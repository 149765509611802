<section class="vcim-container container pt-8 pt-lg-16 mb-6 pb-10 pb-xl-16">
  <div class="our-mission row mb-27" *ngIf="ourMissionInfo$ | async as ourMissionInfo">
    <div class="our-mission-img col-12 col-sm-6">
      <loading-image
        [image]="'/portal/' + ourMissionInfo.photoPath + '/' + ourMissionInfo.photo"
        [alt]="ourMissionInfo.title"
        *ngIf="ourMissionInfo.photo"
        [styles]="{
          'object-fit': 'cover',
          'object-position': 'center'
        }"
        class="w-full h-full">
      </loading-image>
    </div>
    <div class="our-mission-title p-10 col-12 col-sm-6 d-flex flex-col justify-center">
      <h2 class="vcim-headline-1 mb-2">
        <ngx-dynamic-hooks
          [options]="{ sanitize: false }"
          class="vcim-inner-html inner-html-title"
          [content]="ourMissionInfo.title"></ngx-dynamic-hooks>
      </h2>
      <ngx-dynamic-hooks
        [options]="{ sanitize: false }"
        class="vcim-body-1 fw-700"
        [content]="ourMissionInfo.description"></ngx-dynamic-hooks>
    </div>
  </div>

  <div class="mb-27" *ngIf="ourValuesInfo$ | async as ourValuesInfo">
    <div class="container-our-value-title px-2 mx-auto mb-16 text-center">
      <h2 class="vcim-headline-1 mb-2">
        <ngx-dynamic-hooks
          [options]="{ sanitize: false }"
          class="vcim-inner-html inner-html-title"
          [content]="ourValuesInfo.title"></ngx-dynamic-hooks>
      </h2>
      <ngx-dynamic-hooks
        [options]="{ sanitize: false }"
        class="vcim-body-1 fw-700"
        [content]="ourValuesInfo.description"></ngx-dynamic-hooks>
    </div>

    <div class="container-grid mb-16 vcim-grid vcim-grid-size-1 vcim-grid-size-3-sm gap-4 gap-md-6 gap-xl-10">
      <div *ngFor="let service of ourValuesInfo.items; let i = index" class="">
        <app-services-card [service]="service"></app-services-card>
      </div>
    </div>
  </div>

  <div class="our-story row mb-27" *ngIf="ourStoryInfo$ | async as ourStoryInfo">
    <div class="our-story-title p-10 col-12 col-sm-6 d-flex flex-col justify-center">
      <h2 class="vcim-headline-1 mb-2">
        <ngx-dynamic-hooks
          [options]="{ sanitize: false }"
          class="vcim-inner-html inner-html-title"
          [content]="ourStoryInfo.title"></ngx-dynamic-hooks>
      </h2>
      <ngx-dynamic-hooks
        [options]="{ sanitize: false }"
        class="vcim-body-1 fw-500"
        [content]="ourStoryInfo.description"></ngx-dynamic-hooks>
    </div>
    <div class="our-story-img col-12 col-sm-6">
      <loading-image
        [image]="'/portal/' + ourStoryInfo.photoPath + '/' + ourStoryInfo.photo"
        [alt]="ourStoryInfo.title"
        *ngIf="ourStoryInfo.photo"
        [styles]="{
          'object-fit': 'cover',
          'object-position': 'center'
        }"
        class="w-full h-full">
      </loading-image>
    </div>
  </div>

  <app-our-results></app-our-results>
</section>

<ng-template #showSpinner>
  <div style="height: 100px" class="my-10 d-flex justify-center">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
