import { Injectable } from '@angular/core';
import {
  BranchDataI,
  BranchesListI,
  BranchesListViewI,
  CitiesListI,
} from '@shared/interfaces';
import { BehaviorSubject, map, Observable, Subject, tap } from 'rxjs';
import { CitiesApiService } from '../api/cities.api.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class CitiesService {
  private _allCities: CitiesListI[] = [];
  public _allCities$ = new BehaviorSubject<BranchesListI[]>([]);
  private _selectedCitiesBS = new BehaviorSubject<number[]>(
    localStorage
      .getItem('selectedCities')
      ?.split(',')
      .filter((i) => i !== '')
      .map((i) => +i) || []
  );

  constructor(
    private readonly citiesSrv: CitiesApiService,
    private readonly router: Router
  ) {
    if (!this.router.routerState.snapshot.url.endsWith('404')) {
      this.citiesSrv
        .getCitiesList()
        .pipe(
          map((d) => d?.data),
          tap((i) => {
            this.allCities = i;
            if (!this.selectedCities.length) {
              this.selectedCities = i.map((i) => i.id);
            }
          })
        )
        .subscribe();
    }
  }

  set selectedCities(value: number[]) {
    localStorage.setItem('selectedCities', value.join(','));
    this._selectedCitiesBS.next(value);
  }

  get selectedCities(): number[] {
    return this._selectedCitiesBS.value;
  }

  get selectedCitiesObs() {
    return this._selectedCitiesBS as Observable<number[]>;
  }

  set allCities(value: CitiesListI[]) {
    this._allCities = value;
  }

  get allCities(): CitiesListI[] {
    return this._allCities;
  }

  getBranchesListForView(): Observable<BranchesListViewI[]> {
    return this.citiesSrv.getBranchesList().pipe(
      map((d) => {
        const groupedData = (d?.data || []).reduce((acc: any, obj) => {
          const city = obj.city;

          const existingCity = acc.find((c: any) => c.city === city);
          if (existingCity) {
            existingCity.branches.push(obj);
          } else {
            acc.push({ city, branches: [obj] });
          }

          return acc;
        }, []);

        return groupedData as BranchesListViewI[];
      })
    );
  }

  getBranchesList(cityIds: number[]): Observable<BranchesListI[]> {
    return this.citiesSrv
      .getBranchesList(cityIds).pipe(
        map(i => i?.data || []),
        tap(value => this._allCities$.next(value))
      );
  }
  
  getBranchBySlug(slug: string): Observable<BranchDataI> {
    return this.citiesSrv
      .getBranchBySlug(slug)
      .pipe(map((d) => d?.data));
  }
}
