import { Injectable } from '@angular/core';
import { ApplicationHttpClient } from '@s';
import {
  ResponseDto,
  VacanciesCategoriesI,
  VacanciesFilterI,
  VacanciesListI,
  VacancyDataI,
} from '@shared/interfaces';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VacanciesApiService {
  constructor(private http: ApplicationHttpClient) {}

  getVacanciesList(
    searchOptions: VacanciesFilterI
  ): Observable<ResponseDto<VacanciesListI[]>> {
    return this.http.get<ResponseDto<VacanciesListI[]>>(
      '/portal/api/v1/vacancies',
      {
        params: {
          page: searchOptions.pageNumber + 1,
          size: searchOptions.pageSize,
          categoryIds: searchOptions.categoriesIds?.join(',') || '',
          text: searchOptions.searchText || '',
        },
      }
    );
  }

  getCategoriesList(): Observable<ResponseDto<VacanciesCategoriesI[]>> {
    return this.http.get<ResponseDto<VacanciesCategoriesI[]>>(
      '/portal/api/v1/vacancy-categories',
      {}
    );
  }

  getVacancyBySlug(slug: string): Observable<ResponseDto<VacancyDataI>> {
    const encodedRoute = encodeURIComponent(slug);
    return this.http.get<ResponseDto<VacancyDataI>>(
      `/portal/api/v1/vacancies/bySlug/${encodedRoute}`
    );
  }
  getVacancyDescription(): Observable<ResponseDto<VacancyDataI>> {
    return this.http.get<ResponseDto<VacancyDataI>>(
      `/portal/api/v1/vacancy-description`
    );
  }
  
}
