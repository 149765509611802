<div
  [routerLink]="service.link ? (service.link | localize) : []"
  [class]="'container py-6 px-3 flex flex-col items-center animated h-full ' + size"
  [ngClass]="{ pointer: service.link, 'py-6': size === 'sm', 'py-8': size === 'md' }">
  <span *ngIf="service.photo" class="icon mb-4 flex items-center justify-center animated">
    <svg-icon [src]="'/portal/' + service.photoPath + '/' + service.photo" class="icon f-40 f-md-40" />
  </span>
  <span class="vcim-headline-5 text-center mt-auto">
    <ngx-dynamic-hooks
      [options]="{ sanitize: false }"
      class="vcim-inner-html inner-html-title"
      [content]="service.title"></ngx-dynamic-hooks>
  </span>
</div>
