<ng-container *ngIf="type === 'block'">
  <div class="container block py-8 px-5">
    <h1 class="vcim-headline-4 mb-3 px-3">
      {{ title | translate }}
    </h1>

    <form class="flex flex-col" [formGroup]="form">
      <div class="mb-1" *ngFor="let checkbox of list; index as i">
        <mat-checkbox
          (change)="handleCheckbox($event)"
          [value]="checkbox.id.toString()"
          [checked]="ids.value.includes(checkbox.id.toString())"
          class="vcim-checkbox color-cyan"
          color="primary">
          <span class="vcim-body-1">
            <ngx-dynamic-hooks
              [options]="{ sanitize: false }"
              class="vcim-inner-html inner-html-title"
              [content]="removeHtmlTags(checkbox.title)"></ngx-dynamic-hooks>
          </span>
        </mat-checkbox>
      </div>
    </form>
  </div>
</ng-container>

<ng-container *ngIf="type === 'accordion'">
  <mat-accordion [multi]="true" class="d-flex flex-col">
    <mat-expansion-panel hideToggle class="accordion-header vcim-expansion-reset">
      <mat-expansion-panel-header class="py-3 px-4">
        <mat-panel-title class="vcim-headline-4 d-flex">
          <span class="pr-2"> {{ title | translate }} </span>
          <i class="vcim-icon ic-arrow-down ml-auto"></i>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <form class="flex flex-col px-4 pt-2 pb-6" [formGroup]="form">
        <div class="mb-1" *ngFor="let checkbox of list; index as i">
          <mat-checkbox
            (change)="handleCheckbox($event)"
            [value]="checkbox.id.toString()"
            [checked]="ids.value.includes(checkbox.id.toString())"
            class="vcim-checkbox color-cyan"
            color="primary">
            <span class="vcim-body-1">
              <ngx-dynamic-hooks
                [options]="{ sanitize: false }"
                class="vcim-inner-html inner-html-title"
                [content]="removeHtmlTags(checkbox.title)"></ngx-dynamic-hooks>
            </span>
          </mat-checkbox>
        </div>
      </form>
    </mat-expansion-panel>
  </mat-accordion>
</ng-container>
