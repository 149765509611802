import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { LeaveReviewFormI } from '@shared';

@Component({
  selector: 'app-leave-review',
  templateUrl: './leave-review.component.html',
  styleUrls: ['./leave-review.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LeaveReviewComponent implements OnInit {
  leaveReviewForm: FormGroup;
  @Output() save = new EventEmitter<LeaveReviewFormI>();

  constructor(
    private readonly fb: FormBuilder,
    readonly translate: TranslateService
  ) {
    this.leaveReviewForm = this.fb.group({
      fullName: ['', Validators.compose([Validators.required])],
      email: ['', Validators.compose([ Validators.email])],
      phone: ['', Validators.compose([Validators.required])],
      message: ['', Validators.compose([Validators.required])],
      rate: [null, Validators.compose([Validators.required])],
    });
  }
  saveForm(): void {
    this.save.emit(this.leaveReviewForm.value);
    this.leaveReviewForm.reset();
    for (var name in this.leaveReviewForm.controls) {
      if(name !== 'rate') {
        this.leaveReviewForm.controls[name].markAsUntouched();
      }
      this.leaveReviewForm.controls[name].setErrors([]); 
    }
  }
  ngOnInit() {}

  onRatingChange(e: { rating: number }): void {
    this.leaveReviewForm.patchValue({ rate: e.rating });
  }
}
