import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";

@Component({
  selector: "app-no-image",
  templateUrl: "./no-image.component.html",
  styleUrls: ["./no-image.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class NoImageComponent implements OnInit {
  
  constructor() { 

  }

  ngOnInit() {

  }
}
