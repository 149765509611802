import { Component, OnInit, Input } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';

@Component({
  selector: 'app-share-button',
  templateUrl: './share-button.component.html',
  styleUrls: ['./share-button.component.scss']
})

export class ShareButtonComponent implements OnInit {
  @Input() icon = '';
  @Input() button!: 'facebook' | 'twitter' | 'linkedin';
  @Input() url = encodeURIComponent(document.URL);
  @Input() title = '';
  @Input() description = '';
  @Input() image = '';
  @Input() appId = '';

  constructor(
    private router: Router
  ) {
  }

  ngOnInit() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe(() => {
      this.url = encodeURIComponent(document.URL);
    })
  }

  share(event: Event) {
    event.preventDefault();

    const url: string[] = [];
    this.title ? url.push(`title=${this.title}`) : '';
    let shareLink = '';
    let height = 400;
    switch (this.button) {
      case 'facebook':
        this.description ? url.push(`description=${this.description}`) : '';
        this.url ? url.push(`href=${this.url}`) : '';
        url.push(...[`app_id=${this.appId}`, 'display=popup']);

        shareLink = `https://www.facebook.com/dialog/share`;
        break;
      case 'twitter':
        this.url ? url.push(`url=${this.url}`) : '';
        this.description ? url.push(`text=${this.description}`) : '';

        shareLink = `https://twitter.com/intent/tweet`;
        break;
      case 'linkedin':
        height = 600;
        this.url ? url.push(`url=${this.url}`) : '';
        this.description ? url.push(`summary=${this.description}`) : '';
        // rest params
        url.push(...['mini=true']);

        shareLink = `https://www.linkedin.com/shareArticle`;

        break;

      default:
        break;
    }
    const link = [shareLink, url.join('&')].join('?');

    window.open(`${link}`, '', `_blank, width=400, height=${height}, resizable=yes, scrollbars=yes`); return false;
  }
}
