<div class="container p-4 h-100 flex flex-col">
  <div class="mb-8">
    <ng-container *ngIf="user$ | async; else showLoginBtn">
      <button
        [routerLink]="'/personal-data' | localize"
        mat-flat-button
        color="primary"
        class="vcim-button color-primary sm-button w-full">
        <i class="vcim-icon ic-user mr-2 f-20"></i>
        {{ 'website.layout.header.my-account' | translate }}
      </button>
      <button mat-flat-button color="primary" class="mt-4 vcim-button color-primary sm-button w-full" (click)="logout()">
        {{ 'website.layout.header.logout' | translate }}
      </button>
    </ng-container>
  </div>

  <div class="container-list flex flex-col">
    <ng-container *ngFor="let link of links">
      <ng-container *ngIf="link.children">
        <mat-expansion-panel [expanded]="isOpened(link.children)" hideToggle class="vcim-expansion-reset">
          <mat-expansion-panel-header>
            <button class="container-item vcim-body-2 p-3 w-full flex justify-between pointer">
              {{ link.titleKey | translate }}
              <i class="vcim-icon ic-arrow-down ml-2"></i>
            </button>
          </mat-expansion-panel-header>
          <div class="flex flex-col">
            <a
              *ngFor="let child of link.children"
              routerLinkActive="active"
              [routerLink]="child.link! | localize"
              class="container-item vcim-body-2 py-2 px-5 w-full">
              {{ child.titleKey | translate }}
            </a>
          </div>
        </mat-expansion-panel>
      </ng-container>

      <a
        class="container-item vcim-body-2 p-3 w-full"
        *ngIf="!link.children"
        [routerLink]="link.link! | localize"
        routerLinkActive="active">
        {{ link.titleKey | translate }}
      </a>
    </ng-container>

    <app-mobile-translate></app-mobile-translate>

    <div class="">
      <app-mobile-city-select></app-mobile-city-select>
    </div>
    <div>
      <!-- <button mat-flat-button class="vcim-button color-primary sm-button mt-4" color="primary" [routerLink]="'/3d-map' | localize">
        <div class="d-flex items-center">
          <mat-icon class="vcim-icon f-20 pr-2 w-auto h-auto">layers</mat-icon>
          <span class="mat-body-2 fw-700"> {{ 'website.layout.header.3d-map' | translate }} </span>
        </div>
      </button> -->
    </div>
  </div>
</div>

<ng-template #showLoginBtn>
  <button mat-flat-button color="primary" class="vcim-button color-primary sm-button w-full" [routerLink]="'/login' | localize">
    {{ 'website.layout.header.login' | translate }}
  </button>
</ng-template>
