import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { AboutUsApiService } from '../api/about-us.api.service';
import {
  CompanyDescriptionDataI,
  faqDescriptionI,
  faqListDto,
  faqListI,
  getInTouchFormI,
  LeaveReviewFormI,
  LicensesListI,
  OpinionsListI,
  OurMissionDataI,
  OurStoryDataI,
  OurValuesDataI,
  PrivacyPolicyDataI,
  RequisitesListI,
  TermsAndConditionsDataI,
} from '@shared/interfaces/help.interface';
import { PaginationDto, PaginationI } from '@shared/interfaces';
import { SettingsService } from '@s';

@Injectable({
  providedIn: 'root',
})
export class AboutUsService {
  constructor(private readonly srv: AboutUsApiService, private readonly settingsSrv: SettingsService) {}

  getFaqInfo(): Observable<PaginationDto<faqListDto>> {
    return this.srv.getFaqList().pipe(
      map(i => {
        return {
          total: i.data?.length || 0,
          list: i.data?.sort(function (a, b) {
            if (a.category < b.category) return -1;
            if (a.category > b.category) return 1;
            return 0;
          }),
        };
      })
    );
  }

  createFaqQuestion(body: getInTouchFormI): Observable<any> {
    return this.srv.createFaqQuestion(body);
  }
  getFaqDescription(): Observable<faqDescriptionI> {
    return this.srv.getFaqDescription().pipe(map(response => response?.data));
  }

  createContactQuestion(body: getInTouchFormI): Observable<any> {
    return this.srv.createContactQuestion(body);
  }

  getPrivacyPolicy(): Observable<PrivacyPolicyDataI> {
    return this.srv.getPrivacyPolicy().pipe(map(d => d?.data));
  }
  getTermsAndConditions(): Observable<TermsAndConditionsDataI> {
    return this.srv.getTermsAndConditions().pipe(map(d => d?.data));
  }
  getRequisites(): Observable<RequisitesListI[]> {
    return this.srv.getRequisites().pipe(map(d => d?.data));
  }
  getOpinions(searchOptions: PaginationI): Observable<PaginationDto<OpinionsListI>> {
    return this.srv.getOpinions(searchOptions).pipe(
      map(d => {
        return { list: d?.data || [], total: d?.total || 0 };
      })
    );
  }
  createOpinion(body: LeaveReviewFormI): Observable<any> {
    const obj = {
      ...body,
      translations: [
        {
          language: this.settingsSrv.getLanguage(),
          fullName: body.fullName,
          message: body.message,
        },
      ],
    };
    return this.srv.createOpinion(obj);
  }
  getCompanyDescription(): Observable<CompanyDescriptionDataI> {
    return this.srv.getCompanyDescription().pipe(map(d => d?.data));
  }
  getOurMission(): Observable<OurMissionDataI> {
    return this.srv.getOurMission().pipe(map(d => d?.data));
  }
  getOurValues(): Observable<OurValuesDataI> {
    return this.srv.getOurValues().pipe(map(d => d?.data));
  }
  getOurStory(): Observable<OurStoryDataI> {
    return this.srv.getOurStory().pipe(map(d => d?.data));
  }
}
