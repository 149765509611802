<section class="vcim-container container pt-8 pt-lg-16 mb-6 pb-10 pb-xl-16">
  <div class="container-title px-2 mx-auto mb-16 text-center">
    <h1 class="vcim-headline-1">
      {{ 'website.survey-saved.title' | translate }}
    </h1>
  </div>
  <div class="d-flex justify-center">
    <button
      [routerLink]="'/' | localize"
      mat-stroked-button
      color="primary"
      class="vcim-button md-button color-primary whitespace-nowrap mt-6">
      {{ 'website.survey-saved.button' | translate }}
    </button>
  </div>
</section>

<ng-template #showSpinner>
  <div style="height: 100px" class="my-10 d-flex justify-center">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
