<div [routerLink]="'/news/' + news.slug | localize" class="container flex flex-col items-center animated h-full pointer">
  <div class="container-image">
    <loading-image
      *ngIf="news.coverPhoto; else showNoImage"
      [image]="'/portal/' + news.photoPath + (news.coverPhoto.endsWith('.svg') ? '/' : '/medium_') + news.coverPhoto"
      [alt]="news.title"
      [styles]="{
        'object-fit': 'cover',
        'object-position': 'center'
      }"
      class="w-full h-full">
    </loading-image>
  </div>
  <div class="p-6 d-flex flex-col w-full h-full">
    <div class="vcim-headline-4 mb-3">
      <ngx-dynamic-hooks
        [options]="{ sanitize: false }"
        class="vcim-inner-html inner-html-title"
        [content]="removeHtmlTags(news.title)"></ngx-dynamic-hooks>
    </div>
    <ngx-dynamic-hooks
      [options]="{ sanitize: false }"
      class="container-description vcim-body-2 mb-4 truncate-2"
      [content]="removeHtmlTags(news.description, true)"></ngx-dynamic-hooks>
    <p class="container-date f-14 mt-auto">
      {{ news.newsDate }}
    </p>

    <button
      [routerLink]="'/news/' + news.slug | localize"
      mat-stroked-button
      color="primary"
      class="vcim-button md-button color-primary w-full whitespace-nowrap mt-6">
      {{ 'website.actions.learn-more' | translate }}
    </button>
  </div>
</div>

<ng-template #showNoImage>
  <div class="container-image-none">
    <app-no-image></app-no-image>
  </div>
</ng-template>
