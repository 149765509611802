import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AuthService } from '@core';
import { environment } from '@env/environment';
import { ProtocolService } from '@s';
import { BannerDataI, User } from '@shared';
import { DashboardService } from '@shared/services/pages/dashboard.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-dashboard-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardBannerComponent implements OnInit {
  environment = environment;
  user$: Observable<User | null>;

  bannerData$: Observable<BannerDataI[]>;
  constructor(
    // private readonly route: ActivatedRoute,
    private readonly srv: DashboardService,
    private readonly auth: AuthService,
    public ps: ProtocolService
  ) {
    this.bannerData$ = this.srv.getBannerData();
    this.user$ = this.auth.user();
  }

  customOptions: OwlOptions = {
    loop: true,
    autoplay: false,
    center: false,
    dots: true,
    nav: true,
    autoHeight: false,
    autoWidth: false,
    navText: [
      '<i class="vcim-icon ic-arrow-down"></i>',
      '<i class="vcim-icon ic-arrow-down"></i>',
    ],
    responsive: {
      0: {
        items: 1,
        slideBy: 1,
      },
    },
  };

  ngOnInit() {}
}
