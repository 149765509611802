import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@env/environment';
import { BranchesListI, PriceListI, PriceListItemI, SpecializationsFilterI, SpecializationsI } from '@shared';
import { CitiesService } from '@shared/services/pages/cities.service';
import { ServicesService } from '@shared/services/pages/services.service';
import { BaseComponent } from '@front/app/base-component';
import { BehaviorSubject, combineLatest, debounceTime, map, Observable, of, switchMap, tap } from 'rxjs';
import { ProtocolService, SettingsService } from '@s';
import { AuthService } from '@front/app/core';
import { MatDialog } from '@angular/material/dialog';
import { CreateRequestsTasksPopupComponent } from '@front/app/shared/components/personal-data/requests-tasks-popup/requests-tasks-popup.component';
import { SelectServicePopupComponent } from '@front/app/shared/components/personal-data/services-popup/services-popup.component';
import { AppointmentCreateService } from '@front/app/shared/services/pages/appointment-create.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { MetaDataService } from '@front/app/shared/services/meta-data.service';

@Component({
  selector: 'app-services-list',
  templateUrl: './services-list.component.html',
  styleUrls: ['./services-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServicesListComponent extends BaseComponent implements OnInit {
  environment = environment;
  specializationsList$: Observable<SpecializationsI[]>;
  private specializationsPageSize = 40;
  private specializationsTotal = 0;
  private specializationsItems: SpecializationsI[] = [];
  specializationsScrollDisabled: boolean = false;

  specializationInfo$: Observable<{
    item: SpecializationsI | null;
    isDefault: boolean;
  }>;

  private specializationsSearchOptions$ = new BehaviorSubject<SpecializationsFilterI>({
    pageNumber: 0,
    pageSize: this.specializationsPageSize,
  });
  branchesList: BranchesListI[] = [];
  selectedId$ = new BehaviorSubject<number | undefined>(undefined);

  userVerified = false;
  user$ = this.auth.user().subscribe(i => this.userVerified = i?.verified || false);

  foundServices$: Observable<PriceListItemI[]>;
  searchText$ = new BehaviorSubject('');

  link = '';
  isCopying = false;

  constructor(
    private readonly srv: ServicesService,
    private readonly citiesSrv: CitiesService,
    private readonly router: Router,
    private readonly settings: SettingsService,
    public readonly route: ActivatedRoute,
    private readonly auth: AuthService,
    private readonly dialog: MatDialog,
    private readonly appSrv: AppointmentCreateService,
    public protocolService: ProtocolService,
    private readonly toastr: ToastrService,
    private readonly meta: MetaDataService,
    private readonly translate: TranslateService,
  ) {
    super();

    this.specializationsList$ = this.specializationsSearchOptions$.pipe(
      switchMap(searchOptions => {
        return this.srv.getSpecializationsList({ ...searchOptions }).pipe(
          tap(i => {
            this.specializationsTotal = i.total;
            this.specializationsItems = [...this.specializationsItems, ...i.list];
            this.specializationsScrollDisabled = false;
          }),
          map(i => {
            return this.specializationsItems;
          })
        );
      })
    );
    this.citiesSrv.selectedCitiesObs
      .pipe(
        this.takeUntilDestroy(),
        tap(i => {
          this.specializationsItems = [];
          this.specializationsSearchOptions$.next({
            ...this.specializationsSearchOptions$.value,
            pageNumber: 0,
            pageSize: this.specializationsPageSize,
            cityIds: i,
          });
        })
      )
      .subscribe();

    this.specializationInfo$ = this.route.params.pipe(
      this.takeUntilDestroy(),
      switchMap(snapshot => {
        let request: Observable<any>;
        if (snapshot.slug) {
          const item = this.specializationsItems.find(i => i.slug === snapshot.slug);
          if (item) {
            this.selectedId$.next(item.id);
            request = of({
              item: item,
              isDefault: false,
            });
          } else {
            request = this.srv.getSpecializationsBySlug(snapshot.slug).pipe(
              tap(i => {
                this.selectedId$.next(i?.id);
              }),
              map(i => ({ item: i, isDefault: false }))
            );
          }
          return request.pipe(
            tap(async ({ item }) => {
              if (item === undefined) {
                this.router.navigate([`/${this.settings.getLanguage()}/` + '/404']);
                return;
              }
            })
          );
        } else {
          return this.srv.getSpecializationDescription().pipe(map(i => ({ item: i || {}, isDefault: true })));
        }
      })
    );

    this.foundServices$ = combineLatest({ cities: this.citiesSrv.selectedCitiesObs, searchText: this.searchText$ }).pipe(
      debounceTime(300),
      switchMap(({ cities, searchText }) => {
        return this.srv.getPriceFullList({ pageNumber: 0, pageSize: 30, searchText, cityIds: cities });
      })
    );
  }
  ChangeSearchText(event: string): void {
    this.searchText$.next(event);
    this.specializationsItems = [];
    this.specializationsSearchOptions$.next({
      ...this.specializationsSearchOptions$.value,
      pageNumber: 0,
      pageSize: this.specializationsPageSize,
      serviceSearchText: event,
    });
  }

  ngOnInit() {
    this.route.params.pipe(this.takeUntilDestroy()).subscribe(() => {
      if (this.route.snapshot.params.page) {
        this.meta.setTags('robots', 'noindex');
      } else {
        this.meta.removeTag('robots');
      }
      this.link = this.protocolService.getUrl(environment?.website + this.router.url);
    });
  }

  toggleSpecializationsScrolled(): void {
    this.specializationsScrollDisabled = true;
    if (this.specializationsTotal > this.specializationsPageSize * (this.specializationsSearchOptions$.value.pageNumber + 1)) {
      this.specializationsSearchOptions$.next({
        ...this.specializationsSearchOptions$.value,
        pageNumber: this.specializationsSearchOptions$.value.pageNumber + 1,
      });
    }
  }

  changeSpeciality(event: number) {
    const slug = this.specializationsItems.find(i => i.id === event)?.slug;
    this.router.navigate(['/' + this.settings.getLanguage() + '/services/' + slug]);
  }

  selectService(item: PriceListItemI): void {
    if (this.userVerified) {
      if (item.task) {
        this.dialog.open(CreateRequestsTasksPopupComponent, {
          data: item,
          autoFocus: false,
        });
      } else {
        const dialogRef = this.dialog.open(SelectServicePopupComponent, {
          data: item,
          autoFocus: false,
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.appSrv.setSelectedPriceItem(item);
          }
        });
      }
    } else {
      this.router.navigate(['/' + this.settings.getLanguage() + '/services/' + item.slug + '/price']);
    }
  }
  copy(): void {
    if (!this.isCopying) {
      this.isCopying = true;
      this.toastr.info(this.translate.instant('website.news.text-copied'));
      setTimeout(() => {
        this.isCopying = false;
      }, 5000);
    }
  }
}
