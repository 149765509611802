import { Component, OnChanges, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Inject } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { BASE_URL, ProtocolService } from '@s';

@Component({
  selector: 'svg-icon',
  template: `
    <mat-icon *ngIf="this.svgIcon" [svgIcon]="this.svgIcon" class="icon"> </mat-icon>
    <img *ngIf="imageUrl" [alt]="alt" [src]="imageUrl" />
  `,
  styleUrls: ['./icon.component.scss'],
})
export class SvgIconComponent implements OnChanges {
  @Input()
  public name?: string;
  @Input()
  public src: string | Blob = '';

  @Input()
  public alt: string = '';

  public svgIcon: string = '';
  public imageUrl: string = '';

  constructor(
    private ps: ProtocolService,
    private sanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
    @Inject(BASE_URL) public baseUrl?: { [key: string]: string }
  ) {}

  isSvg() {
    if (this.src instanceof Blob) return false;
    return this.src?.indexOf('<path') !== -1 || this.src?.indexOf('<circle') !== -1;
  }

  getSvg(src: string) {
    if (src?.indexOf('<svg') !== -1) {
      return src;
    }
    return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">${src}</svg>`;
  }

  public ngOnChanges(): void {
    if (this.name || this.src) {
      if (this.src instanceof Blob) {
        this.imageUrl = URL.createObjectURL(this.src);
      } else {
        if (this.isSvg()) {
          this.imageUrl = '';
          this.svgIcon = 'mat-icon';
          this.matIconRegistry.addSvgIcon('mat-icon', this.getSvg(this.src));
          return;
        }
        const url = this.name ? `assets/images/svg/${this.name}.svg` : this.src;
        if (!this.name && !url.endsWith('.svg')) {
          // && !this.src.endsWith('.svg')
          this.svgIcon = '';
          this.imageUrl = this.ps.getUrl(url);
        } else {
          this.svgIcon = 'mat-icon';
          this.matIconRegistry.addSvgIcon('mat-icon', this.sanitizer.bypassSecurityTrustResourceUrl(this.ps.getUrl(url)));
        }
      }
    } else {
      this.svgIcon = '';
      this.imageUrl = '';
    }
  }
}
