import { Component, OnInit, AfterViewInit, PLATFORM_ID, Inject, Renderer2, Optional } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { PreloaderService } from '@core';
import { isPlatformBrowser } from '@angular/common';
import { filter, forkJoin, map } from 'rxjs';
import { ProtocolService, SeoService, SettingsService } from '@s';
import { MetaDataService } from '@shared/services/meta-data.service';
import { ChatWidget } from '@front/assets/chat-widget';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { IS_CRAWLER } from './shared/services/api/payment.service';

export const isClr = makeStateKey<boolean>(`isCrawler`);
@Component({
  selector: 'app-root',
  template: `<router-outlet></router-outlet>`,
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'vardanants';
  private previousUrl: string | undefined;
  private currentUrl: string | undefined;
  constructor(
    private preloader: PreloaderService,
    // @Inject(DOCUMENT) private document: Document,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private readonly seoService: SeoService,
    private readonly settings: SettingsService,
    private readonly meta: MetaDataService,
    private readonly renderer: Renderer2,
    public protocolService: ProtocolService,
    private transferState: TransferState,
    @Inject(PLATFORM_ID) private platformId: any,
    @Optional() @Inject(IS_CRAWLER) private isCrawler: boolean
  ) {
    this.meta.renderer = renderer;
  }

  setData() {
    if (!this.router.routerState.snapshot.url.endsWith('404')) {
      let url = this.settings.getUrl(this.router.url!) || '/';
      if (url.indexOf('/pages/') !== -1) {
        // replace /news/ and next slug + / with /
        url = url.replace(/\/news\/[^/]+\//, '/');
        url = url.replace('/services/', '/');
      }
      // remove slashes only from the end and beginning
      const [objectData, seoData] = url.replace(/^\/|\/$/g, '').split('/');
      forkJoin([
        this.seoService.getSeoAndObject(objectData, seoData),
        this.seoService.getSeo(url)
      ]).pipe(
        map(([seoObject, seo]) => {
          if (seo?.photo) {
            seo['og:image'] = this.protocolService.getUrl(`/portal/${seo.photoPath}/${seo.photo}`);
            seo['og:image:secure_url'] = seo['og:image'];
          }
          return [seoObject, seo];
        }))
        .subscribe(([seoObject, seo]) => {
          const seoData = seoObject ? this.seoService.mapForSeo(seoObject) : seo;
          this.meta.clearSeoCache();
          this.meta.apply(seoData);
        });
    }
  }

  ngOnInit() {

    this.setData();
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe(() => {
      var rt = this.getChild(this.activatedRoute);
      rt.data.subscribe(() => this.setData())
    })

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (isPlatformBrowser(this.platformId)) {
          window.scrollTo(0, 0);
        }
      }
    });
  }

  getChild(activatedRoute: ActivatedRoute): ActivatedRoute {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }

  }

  ngAfterViewInit() {
    this.preloader.hide();

    if (isPlatformBrowser(this.platformId)) {
      const isCrawler = this.transferState.get<any>(isClr, false);
      if (!isCrawler) {
        setTimeout(() => {
          this.setChatWidget();
        }, 5000);
      }
    } else {
      this.transferState.set(isClr, this.isCrawler);
    }

    document.addEventListener('click', (event: MouseEvent) => {
      const targetElement = event.target as HTMLElement;

      if (targetElement.classList.contains('vcim-not-clickable')) {
        event.preventDefault();
        event.stopPropagation();
      }
    }, true);
  }

  // setGoogleAnalytics() {
  //   const script = this.renderer.createElement('script');
  //   script.type = 'text/javascript';
  //   script.text = `
  //     (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  //     new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  //     j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  //     'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  //     })(window,document,'script','dataLayer','GTM-5GQ633P4');
  //   `;
  //   this.renderer.appendChild(this.document.head, script);
  // }

  // setGoogleTagManagerNoScript() {
  //   const noscript = this.renderer.createElement('noscript');
  //   const iframe = this.renderer.createElement('iframe');

  //   this.renderer.setAttribute(iframe, 'src', 'https://www.googletagmanager.com/ns.html?id=GTM-5GQ633P4');
  //   this.renderer.setAttribute(iframe, 'height', '0');
  //   this.renderer.setAttribute(iframe, 'width', '0');
  //   this.renderer.setStyle(iframe, 'display', 'none');
  //   this.renderer.setStyle(iframe, 'visibility', 'hidden');

  //   this.renderer.appendChild(noscript, iframe);
  //   this.renderer.appendChild(this.document.body, noscript);
  // }

  setChatWidget() {
    const script = this.renderer.createElement('script');
    script.type = 'text/javascript';

    const scriptCode = (ChatWidget as any)[this.settings.getLanguage()];
    const scriptContent = this.renderer.createText(scriptCode);
    this.renderer.appendChild(script, scriptContent);

    const widgetItem = document.getElementById('widgetItem');
    this.renderer.appendChild(widgetItem, script);
  }
}

