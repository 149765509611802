<section class="vcim-container container pt-8 pt-lg-16 mb-6 pb-10 pb-xl-16">
  <div class="container-title px-2 mx-auto mb-8 mb-md-12 text-center">
    <h1 class="vcim-headline-1 mb-3">
      {{ 'website.doctors.title' | translate }}
    </h1>
    <span class="vcim-subtitle">
      {{ 'website.doctors.subtitle' | translate }}
    </span>
  </div>

  <div class="container-grid row mx-0 mx-md--4 mx-lg--8 mb-3 mb-md-6">
    <div class="col-12 col-md-5 col-lg-4 px-0 px-md-4 px-lg-8 mb-8 mb-md-0">
      <div class="w-full mb-6">
        <mat-form-field
          class="vcim-search md-field"
          subscriptSizing="dynamic"
          appearance="fill"
          (input)="ChangeSearchText($any($event.target).value)">
          <input type="text" matInput [placeholder]="'website.doctors.search-for-doctor' | translate" />
          <i class="vcim-icon ic-search pl-4 f-20"></i>
        </mat-form-field>
      </div>

      <div class="mb-3 mb-md-6" *ngIf="branchesList$ | async as branchesList; else showSpinner">
        <checkboxes-filter [selectedIds]="searchOptions$.value.branchIds || []" [type]="'accordion'" [list]="branchesList" [title]="'website.doctors.branches'" (save)="onBranchChange($event)">
        </checkboxes-filter>
      </div>

      <div
        infinite-scroll
        [infiniteScrollDistance]="1"
        [infiniteScrollThrottle]="300"
        (scrolled)="toggleSpecializationsScrolled()"
        [infiniteScrollDisabled]="specializationsScrollDisabled"
        *ngIf="specializationsList$ | async as specializationsList; else showSpinner">
        <div class="">
          <checkboxes-filter
            [selectedIds]="searchOptions$.value.specializationIds || []"
            [type]="'accordion'"
            [list]="specializationsList"
            (save)="onSpecialityChange($event)"
            [title]="'website.doctors.speciality'">
          </checkboxes-filter>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-7 col-lg-8 px-0 px-md-4 px-lg-8">
      <div
        infinite-scroll
        [infiniteScrollDistance]="1"
        [infiniteScrollThrottle]="300"
        (scrolled)="toggleDoctorsScrolled()"
        [infiniteScrollDisabled]="doctorsScrollDisabled"
        class="px-16 px-sm-0 vcim-grid vcim-grid-size-1 vcim-grid-size-2-xs vcim-grid-size-3-sm vcim-grid-size-2-md vcim-grid-size-3-lg vcim-grid-size-4-xl gap-4 gap-md-10 gap-xl-10"
        *ngIf="doctorsList$ | async as doctorsList; else showSpinner">
        <div class="break-word" *ngFor="let doctor of doctorsList">
          <app-doctors-card [breakName]="true" [doctor]="doctor"></app-doctors-card>
        </div>
      </div>
    </div>
  </div>
</section>

<ng-template #showSpinner>
  <div style="height: 100px" class="my-10 d-flex justify-center">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
