import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PriceListItemI } from '@front/app/shared/interfaces';
import { SelectServicePopupComponent } from '../services-popup/services-popup.component';
import { AppointmentCreateService } from '@front/app/shared/services/pages/appointment-create.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from '@front/app/base-component';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { SettingsService } from '@s';

@Component({
  selector: 'app-requests-tasks-popup',
  templateUrl: './requests-tasks-popup.component.html',
  styleUrls: ['./requests-tasks-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateRequestsTasksPopupComponent extends BaseComponent implements OnInit {
  completed$ = new BehaviorSubject(false);
  form: FormGroup;
  minDate = new Date();
  constructor(
    private readonly srv: AppointmentCreateService,
    public readonly dialogRef: MatDialogRef<SelectServicePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PriceListItemI,
    private readonly fb: FormBuilder,
    public readonly settings: SettingsService,
    private router: Router
  ) {
    super();
    this.form = this.fb.group({
      date: ['', Validators.compose([Validators.required])],
    });
  }

  ngOnInit() {
    this.dialogRef.afterClosed().subscribe(() => {
      if (this.completed$.value) {
        this.router.navigate(['/' + this.settings.getLanguage() + '/personal-data/requests-tasks']);
      }
    });
  }

  saveForm(): void {
    this.srv
      .createTask({
        serviceId: this.data.id,
        ...this.form.value,
      })
      .pipe(this.takeUntilDestroy())
      .subscribe(() => {
        this.completed$.next(true);
      });
  }
}
