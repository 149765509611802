<section class="vcim-container container pt-8 pt-lg-16 mb-6 pb-10 pb-xl-16">
  <h1 class="vcim-headline-1 mb-12">{{ 'website.branches.title' | translate }}</h1>

  <ng-container *ngIf="branchesList$ | async as branchesList; else showSpinner">
    <div *ngFor="let cityList of branchesList; let i = index" class="mb-12 d-flex flex-col">
      <h5 class="vcim-headline-4 mb-8">
        {{ cityList.city }}
      </h5>
      <div
        class="branches-list container-grid mb-16 vcim-grid vcim-grid-size-1 vcim-grid-size-2-sm vcim-grid-size-3-md gap-4 gap-md-6 gap-xl-10">
        <div
          *ngFor="let branch of cityList.branches; let i = index"
          class="d-flex pointer row"
          [routerLink]="'/branches/' + branch.slug | localize">
          <loading-image
            [image]="'/portal/' + branch.photoPath + (branch.photo.endsWith('.svg') ? '/' : '/medium_') + branch.photo"
            [alt]="branch.title"
            *ngIf="branch.photo"
            [styles]="{
              'object-fit': 'cover',
              'object-position': 'center'
            }"
            class="h-full col-4">
          </loading-image>
          <div class="p-3 col-8">
            <div class="vcim-headline-5 mb-3 f-18">
              <ngx-dynamic-hooks
                [options]="{ sanitize: false }"
                class="vcim-inner-html inner-html-title"
                [content]="branch.title"></ngx-dynamic-hooks>
            </div>
            <div class="flex" *ngIf="branch.workingHours.length">
              <i class="vcim-icon ic-time f-20 pr-2"> </i>
              <div class="vcim-body-2 f-16 d-flex flex-col">
                <span *ngFor="let item of branch.workingHours; let i = index" class="mb-1"> {{ item.title }} {{ item.description }} </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</section>

<ng-template #showSpinner>
  <div style="height: 100px" class="my-10 d-flex justify-center">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
