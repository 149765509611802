<section class="vcim-container container pt-8 pt-lg-16 mb-6 pb-10 pb-xl-16">
    <div *ngIf="termsInfo$ | async as termsInfo; else showSpinner">
      <div class="vcim-headline-1 mb-6 text-center">
        <ngx-dynamic-hooks
          [options]="{ sanitize: false }"
          class="vcim-inner-html inner-html-title"
          [content]="termsInfo.title"></ngx-dynamic-hooks>
      </div>
  
      <div class="row">
        <div class="col-12">
          <ngx-dynamic-hooks
            [options]="{ sanitize: false }"
            class="vcim-body-1 vcim-inner-html"
            [content]="termsInfo.description"
          ></ngx-dynamic-hooks>
        </div>
      </div>
    </div>
  </section>
  
  <ng-template #showSpinner>
    <div style="height: 100px" class="my-10 d-flex justify-center">
      <mat-spinner></mat-spinner>
    </div>
  </ng-template>
  
