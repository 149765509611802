<section class="vcim-container container pt-8 pt-lg-16 mb-6 pb-10 pb-xl-16">
  <div class="row wrap-reverse md-flex-wrap items-start mx-0 md-md--4 mx-xl--8">
    <div class="col-12 col-md-6 col-lg-7 col-xl-8 px-0 px-md-4 px-xl-8 mb-12">
      <div class="container-title mb-6 mb-xl-10 text-center">
        <h1 class="vcim-headline-2">
          {{ 'website.help.opinions.title' | translate }}
        </h1>
      </div>
      <div *ngIf="opinionsList$ | async as opinionsList; else showSpinner">
        <mat-card appearance="raised" *ngFor="let item of opinionsList; let i = index" class="container-card p-8 mb-12 items-start">
          <div class="d-flex items-center justify-between mb-4 flex-col sm-flex-row w-full">
            <span class="vcim-headline-3"> {{ item.fullName }} </span>
            <span class="pl-sm-10 pt-4 pt-sm-0">
              <star-rating [disabled]="true" [rating]="item.rate"></star-rating>
            </span>
          </div>
          <app-opinion-body [data]="item.message"></app-opinion-body>
        </mat-card>
      </div>

      <mat-paginator
        class="vcim-paginator-container"
        *ngIf="total"
        [length]="total || 0"
        [pageSize]="3"
        [hidePageSize]="true"
        showFirstLastButtons
        (page)="onPageChanged($event)">
      </mat-paginator>
    </div>
    <div class="col-12 col-md-6 col-lg-5 col-xl-4 px-0 px-md-4 px-xl-8 mb-12">
      <div class="container-title mb-6 mb-xl-10 text-center">
        <h2 class="vcim-headline-2">
          {{ 'website.help.opinions.leave-review' | translate }}
        </h2>
      </div>

      <div>
        <app-leave-review (save)="createMessage($event)"></app-leave-review>
      </div>
    </div>
  </div>
</section>

<ng-template #showSpinner>
  <div style="height: 100px" class="my-10 d-flex justify-center">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
