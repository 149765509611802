import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { TermsAndConditionsDataI } from "@shared";
import { AboutUsService } from "@shared/services/pages/about-us.service";
import { Observable } from "rxjs";

@Component({
  selector: "app-terms-and-conditions",
  templateUrl: "./terms-and-conditions.component.html",
  styleUrls: ["./terms-and-conditions.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class TermsAndConditionsComponent implements OnInit {
  
  termsInfo$: Observable<TermsAndConditionsDataI>;
  constructor(
    // private readonly route: ActivatedRoute,
    private readonly srv: AboutUsService
  ) {
    this.termsInfo$ = this.srv.getTermsAndConditions();
  }

  ngOnInit() {

  }
}
