export const environment = {
  production: true,
  baseUrl: {
    portal: 'vardanants.am',
    users: 'vardanants.am',
    monitoring: 'vardanants.am',
    notifications: 'vardanants.am',
    patients: 'vardanants.am'
  },
  fullVersion: true,
  website: 'vardanants.am',
  useHash: false,
  protocol: 'https',
  gtag: 'G-6VP0SD40M6',
  gcaptchakey: '6Le7pa8pAAAAAK428eiwdbmbwLnPMRhDYRl-XD3n'
};
