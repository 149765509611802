<section class="vcim-container container pt-8 pt-lg-16 mb-6 pb-10 pb-xl-16">
  <div class="container-title px-2 mx-auto  mb-10 mb-md-16 text-center">
    <h1 class="vcim-headline-1">
      {{ 'website.attachments.title' | translate }}
    </h1>
  </div>
  <div class="content d-flex items-center flex-col mx-auto w-full" *ngIf="attachments$ | async as attachments">
    <mat-tab-group
      (selectedIndexChange)="changePage($event)"
      animationDuration="0s"
      class="vcim-tab-group w-full centered"
      [disablePagination]="true">
      <mat-tab *ngFor="let item of attachments" [label]="item.title">
        <ngx-dynamic-hooks
          [options]="{ sanitize: false }"
          class="vcim-body-2 fw-600 vcim-inner-html text-center mb-10 d-flex"
          [content]="item.description"></ngx-dynamic-hooks>

        <form [formGroup]="form" class="mb-10 form" *ngIf="!item.adult">
          <div class="mb-6">
            <mat-form-field appearance="outline" subscriptSizing="dynamic" class="w-full">
              <input matInput [placeholder]="'website.attachments.parentFullName' | translate" formControlName="parentFullName" />

              <mat-error *ngIf="form.get('parentFullName')?.hasError('required')">
                {{ 'website.validations.required_field' | translate : { field: translate.instant('website.attachments.parentFullName') } }}
              </mat-error>
              <mat-error *ngIf="form.get('parentFullName')?.hasError('pattern')">
                {{ "website.auth.validations.patterns.name" | translate }}
              </mat-error>
            </mat-form-field>
          </div>
          <div>
            <mat-form-field appearance="outline" subscriptSizing="dynamic" class="w-full">
              <input matInput [placeholder]="'website.attachments.childFullName' | translate" formControlName="childFullName" />

              <mat-error *ngIf="form.get('childFullName')?.hasError('required')">
                {{ 'website.validations.required_field' | translate : { field: translate.instant('website.attachments.childFullName') } }}
              </mat-error>
              <mat-error *ngIf="form.get('childFullName')?.hasError('pattern')">
                {{ "website.auth.validations.patterns.name" | translate }}
              </mat-error>
            </mat-form-field>
          </div>
        </form>

        <div class="file-container p-8 p-sm-16 p-lg-27 mb-6 w-full mt-6" appDnd (fileDropped)="onFileDropped($event)">
          <input type="file" #fileDropRef id="fileDropRef" accept=".pdf, .jpg, .jpeg, .png, .doc, .docx" (change)="onFileDropped($event)" />

          <div class="mb-6"><img src="/assets/images/upload-file.svg" /></div>

          <p class="vcim-body-1 fw-700 mb-3">
             {{ 'website.attachments.select-file.title' | translate }}
          </p>
          <p class="vcim-body-2 fw-600 mb-6">
            {{ 'website.attachments.select-file.description' | translate }}
          </p>
          <label for="fileDropRef">
            <button mat-stroked-button color="primary" class="vcim-button md-button color-primary mx-auto w-full w-sm-auto">
              {{ file?.name || 'website.attachments.choose-file' | translate }}
            </button>
          </label>
        </div>

        <button
          type="submit"
          mat-flat-button
          [disabled]="!(file && !form.invalid)"
          (click)="saveForm()"
          class="vcim-button md-button color-grey-primary mt-auto w-full mb-8">
          {{ 'website.attachments.submit' | translate }}
        </button>

        <div class="d-flex flex-col">
          <a
            target="_blank"
            [href]="environment.protocol + '://' + environment.baseUrl.portal + '/portal/' + file.filePath + '/' + file.file"
            class="d-flex items-center py-2 mb-4 item pointer animated"
            *ngFor="let file of item.files">
            <i class="vcim-icon d-flex ic-file f-24 mx-2"> </i>
            <span *ngIf="file.title" class="flex flex-col px-2">
              <span> {{ file.title }}</span>
              <span> {{ file.subTitle }}</span>
            </span>
            <span *ngIf="file.size" class="px-2"> ({{ file.size }}) </span>
          </a>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</section>
