import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import { environment } from '@env/environment';
import { ServicesCardI } from '@shared/interfaces';

@Component({
  selector: 'app-services-card',
  templateUrl: './services-card.component.html',
  styleUrls: ['./services-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServicesCardComponent implements OnInit {
  environment = environment;
  @Input() public service!: ServicesCardI;
  @Input() public size: 'md' | 'sm' = 'sm';

  ngOnInit() {}
  
}
