import { Component, ChangeDetectionStrategy, OnInit, HostBinding, Input } from '@angular/core';
import { AuthService } from '@front/app/core';
import { SettingsService } from '@s';
import { CompanyInfoI, Paths, User } from '@shared';
import { GlobalService } from '@shared/services/global.service';
import { BehaviorSubject, Observable, tap } from 'rxjs';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent implements OnInit {
  Paths = Paths;
  @HostBinding('class') class = 'mt-auto';

  companyInfo$: BehaviorSubject<CompanyInfoI>;
  isLoggedin$ = new BehaviorSubject(false);
  constructor(private readonly srv: GlobalService, public readonly settings: SettingsService, private readonly auth: AuthService) {
    this.companyInfo$ = this.srv.companyInfo$;
    this.auth.user().subscribe(i => {
      this.isLoggedin$.next(!!i);
    });
  }

  ngOnInit() {}
  logout(): void {
    this.auth.logout().subscribe();
  }
}
