import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { environment } from "@env/environment";
import { BranchesListI, BranchesListViewI } from "@shared";
import { CitiesService } from "@shared/services/pages/cities.service";
import { Observable } from "rxjs";

@Component({
  selector: "app-branches-list",
  templateUrl: "./branches-list.component.html",
  styleUrls: ["./branches-list.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class BranchesListComponent implements OnInit {
  
  branchesList$: Observable<BranchesListViewI[]>;
  
  environment = environment;
  constructor(
    private readonly srv: CitiesService
  ) {
    this.branchesList$ = this.srv.getBranchesListForView();
  }

  ngOnInit() {

  }
}
