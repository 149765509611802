import { Injectable } from '@angular/core';
import {
  BannerDataI,
  BranchesListI,
  DoctorsFilterI,
  DoctorsListI,
  NewsI,
  PaginationDto,
  ResultsDataI,
  SpecializationsI,
} from '@shared/interfaces';
import * as moment from 'moment';
import { map, Observable, switchMap } from 'rxjs';
import { CitiesApiService } from '../api/cities.api.service';
import { DoctorsApiService } from '../api/doctors.api.service';
import { GlobalApiService } from '../api/global.api.service';
import { NewsApiService } from '../api/news.api.service';
import { SpecializationsApiService } from '../api/specializations.api.service';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(
    private readonly newsSrv: NewsApiService,
    private readonly doctorsSrv: DoctorsApiService,
    private readonly specsSrv: SpecializationsApiService,
    private readonly globalSrv: GlobalApiService,
    private readonly citiesSrv: CitiesApiService
  ) {}

  getDoctorsList(searchOptions: DoctorsFilterI): Observable<PaginationDto<DoctorsListI>> {
    return this.doctorsSrv.getDoctorsList(searchOptions).pipe(
      map(d => {
        return { list: d?.data || [], total: d?.total || 0 };
      })
    );
  }

  getNewsList(): Observable<NewsI[]> {
    return this.newsSrv
      .getNewsList({
        pageNumber: 0,
        pageSize: 4,
      })
      .pipe(
        map(d => {
          return (d?.data || []).map(i => {
            return {
              ...i,
              newsDate: moment(i.newsDate).format('MMMM DD, YYYY'),
            };
          });
        })
      );
  }

  getServicesList(): Observable<SpecializationsI[]> {
    return this.specsSrv.getSpecializationsHomePageList().pipe(map(d => d?.data.sort((a, b) => a.sortOrder - b.sortOrder) || []));
  }

  getBannerData(): Observable<BannerDataI[]> {
    return this.globalSrv.getBannerData().pipe(map(d => d?.data || []));
  }
  getResultsData(): Observable<ResultsDataI> {
    return this.globalSrv.getResultsData().pipe(map(d => d?.data));
  }
  getBranchesList(): Observable<BranchesListI[]> {
    return this.citiesSrv.getBranchesList().pipe(map(d => d?.data || []));
  }
}
