import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { SettingsService } from '@s';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@front/app/core';
import { BaseComponent } from '@front/app/base-component';

@Component({
  selector: 'app-translate',
  template: `
    <button mat-flat-button [matMenuTriggerFor]="menu" class="menu-button">
      <div class="d-flex items-center">
        <img
          [alt]="settings.getLanguage()"
          [src]="'/assets/images/flags/' + settings.getLanguage() + '.svg'"
        />
        <span class="mx-1 mat-body-2 fw-700">
          {{ settings.getLanguage() }}
        </span>
        <i class="vcim-icon ic-filled-arrow f-10"></i>
      </div>
    </button>

    <mat-menu #menu="matMenu">
      <div class="menu py-1 flex flex-col">
        <button
          mat-menu-item
          *ngFor="let lang of this.langs"
          class="menu-item pl-3 pr-8 py-1 animated vcim-body-1 d-flex items-center"
          (click)="this.refresh(lang)"
        >
          <img
            [alt]="lang.short"
            [src]="'assets/images/flags/' + lang.short + '.svg'"
          />
          <span class="ml-4"> {{ lang.value }} </span>
        </button>
      </div>
    </mat-menu>
  `,
  styleUrls: ['./translate.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TranslateComponent extends BaseComponent {
  langs = this.settings.langs.sort((a, b) => a.order - b.order);
  protected currentUrl = '';
  constructor(
    private readonly router: Router,
    public auth: AuthService,
    public translate: TranslateService,
    public settings: SettingsService
  ) {
    super();
    this.setCurrentUrl();

    this.router.events
      .pipe(this.takeUntilDestroy(), filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.setCurrentUrl();
      });
  }

  private setCurrentUrl(): void {
    const languagePattern = new RegExp(`^\\/${this.settings.getLanguage()}(\\/|$)`, 'g');
    this.currentUrl = this.router.url
      .replace(languagePattern, '');
  }

  refresh(lang: (typeof this.settings.langs)[number]) {
    window.location.href = `/${lang.short}/` + this.currentUrl;
  }
}
