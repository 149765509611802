import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { RequisitesListI, RequisitesType } from "@shared";
import { AboutUsService } from "@shared/services/pages/about-us.service";
import { Observable } from "rxjs";

@Component({
  selector: "app-requisites",
  templateUrl: "./requisites.component.html",
  styleUrls: ["./requisites.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class RequisitesComponent implements OnInit {
  
  requisitesList$: Observable<RequisitesListI[]>;
  RequisitesType = RequisitesType;
  
  constructor(
    // private readonly route: ActivatedRoute,
    private readonly srv: AboutUsService
  ) {
    this.requisitesList$ = this.srv.getRequisites();
  }

  ngOnInit() {

  }
}
