import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NewsListComponent } from './news-list/news-list.component';
import { NewsViewComponent } from './news-view/news-view.component';
import { CustomPagesComponent } from '../custom-pages/custom-pages/custom-pages.component';

export const childRoutes = [
  {
    path: '',
    children: [
      { path: '', component: NewsListComponent },
      { path: ':slug/pages/:page', component: NewsViewComponent },
      { path: ':slug', component: NewsViewComponent },
    ]
  },
];
@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild(childRoutes),
  ],
  exports: [
    RouterModule
  ]
})
export class NewsRoutingModule { }
