import { Component, OnInit, ChangeDetectionStrategy, HostListener } from '@angular/core';
import { DashboardService } from '@front/app/shared/services/pages/dashboard.service';
import { SpecializationsI } from '@shared';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-dashboard-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss'],
})
export class DashboardServicesComponent implements OnInit {
  public list: SpecializationsI[][] = [];
  private allList: SpecializationsI[] = [];

  constructor(private readonly srv: DashboardService) {
    this.srv.getServicesList().subscribe((res: SpecializationsI[]) => {
      this.allList = res;
      let size = 8;
      if (window.innerWidth < 450) {
        size = 2;
      } else if (window.innerWidth < 900) {
        size = 4;
      }
      this.updateListSize(size);
    });
  }
  @HostListener('window:resize')
  onResize() {
    let size = 8;
    if (window.innerWidth < 450) {
      size = 2;
    } else if (window.innerWidth < 900) {
      size = 4;
    }
    this.updateListSize(size);
  }
  ngOnInit() {}

  customOptions: OwlOptions = {
    loop: false,
    autoplay: false,
    center: false,
    dots: false,
    nav: true,
    autoHeight: false,
    autoWidth: false,
    navText: ['<i class="vcim-icon ic-arrow-down"></i>', '<i class="vcim-icon ic-arrow-down"></i>'],
    responsive: {
      0: {
        items: 1,
        slideBy: 1,
      },
    },
  };

  updateListSize(size: number = 8): void {
    this.list = this.allList.reduce((acc: SpecializationsI[][], curr: SpecializationsI, index: number) => {
      const chunkIndex = Math.floor(index / size);
      if (!acc[chunkIndex]) {
        acc[chunkIndex] = [];
      }
      acc[chunkIndex].push(curr);
      return acc;
    }, []);
  }
}
