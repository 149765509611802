export type Nullable<T> = T | null;

export interface ResponseDto<T> {
  code: number;
  data: T;
  error: boolean;
  total: number;
}
export interface PaginationI {
  pageNumber: number;
  pageSize: number;
}

export enum GenderEnum {
  male = 'male',
  famale = 'famale',
}

export interface PaginationDto<T> {
  list: T[];
  total: number;
}

export interface BannerDataI {
  background: string | null;
  buttonLink: string | null;
  buttonText: string | null;
  description: string | null;
  id: number;
  photo: string | null;
  photoPath: string;
  sortOrder: number;
  title: string | null;
}
export interface ResultsDataI {
  id: number;
  customerSatisfaction: string;
  patients: string;
  doctors: string;
  companyGrowth: string;
}
export interface CompanyInfoI {
  phone: string;
  email: string;
  webSite: string;
  facebook: string;
  twitter: string;
  linkedin: string;
  youtube: string;
  instagram: string;
}

export interface CustomPageDataI {
  description: string;
  id: number;
  photo: string;
  photoPath: string;
  title: string;
}
export interface PaginationInputI {
  length: number;
  page: number;
}

export interface ConfirmPopupDataI {
  title: string;
  description: string;
  confirm: string;
  cancel: string;
}
export enum GlobalSearchTypes {
  services = 'services',
  doctors = 'doctors',
  specializations = 'specializations',
  news = 'news',
  seminars = 'seminars',
  vacancies = 'vacancies',
  companyDescription = 'company-description',
  faqs = 'faqs',
  bestOffers = 'best-offers',
}

export interface GlobalSearchI {
  description: string;
  id: number;
  path: GlobalSearchTypes;
  slug: string;
  title: string;
  type: 'personalPackage' | 'service';
}
