import { NgModule } from '@angular/core';
import { SharedComponent } from './shared.component';
import { HttpHandler, HttpClient } from '@angular/common/http';
import { SettingsService } from './services/settings.service';
import { ApplicationHttpClient } from './services/http-client.service';


export function applicationHttpClientCreator(httpHandler: HttpHandler, settingsService: SettingsService) {
  return new ApplicationHttpClient(httpHandler, settingsService);
}

@NgModule({
  declarations: [
    SharedComponent
  ],
  imports: [
  ],
  exports: [
    SharedComponent
  ],
  providers: [
    {
      provide: ApplicationHttpClient,
      useFactory: applicationHttpClientCreator,
      deps: [HttpHandler, SettingsService]
    },
    {
      provide: HttpClient,
      useClass: ApplicationHttpClient,
    },
  ]
})
export class SModule { }
