import { ChangeDetectionStrategy, Component, OnInit, SecurityContext } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@front/app/core';
import { PriceListItemI } from '@front/app/shared';
import { CreateRequestsTasksPopupComponent } from '@front/app/shared/components/personal-data/requests-tasks-popup/requests-tasks-popup.component';
import { SelectServicePopupComponent } from '@front/app/shared/components/personal-data/services-popup/services-popup.component';
import { AppointmentCreateService } from '@front/app/shared/services/pages/appointment-create.service';
import { ServicesService } from '@front/app/shared/services/pages/services.service';
import { SettingsService } from '@s';
import { BehaviorSubject, Observable, map, tap } from 'rxjs';

@Component({
  selector: 'app-price-item',
  templateUrl: './price-item.component.html',
  styleUrls: ['./price-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PriceItemComponent implements OnInit {
  priceListItem$: Observable<PriceListItemI>;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly srv: ServicesService,
    public readonly auth: AuthService,
    private readonly dialog: MatDialog,
    private readonly appSrv: AppointmentCreateService,
    private _sanitizer: DomSanitizer,
  ) {
    this.priceListItem$ = this.srv.getPriceListItem(this.route.snapshot.params.slug).pipe(
      tap(i => {
        if (this.route.snapshot.queryParams['submit']) {
          this.selectService(i);
        }
      })
    );
  }

  ngOnInit() {}

  selectService(item: PriceListItemI) {
    if (this.auth.userValue()?.verified) {
      if (item.task) {
        this.dialog.open(CreateRequestsTasksPopupComponent, {
          data: item,
          autoFocus: false,
        });
      } else {
        const dialogRef = this.dialog.open(SelectServicePopupComponent, {
          data: item,
          autoFocus: false,
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.appSrv.setSelectedPriceItem(item);
          }
        });
      }
    }
  }

  stripTags(html: string): string {
    const str = this._sanitizer.sanitize(SecurityContext.HTML, this._sanitizer.bypassSecurityTrustHtml(html));
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = str || '';
    return tempDiv.textContent || '';
  }

  removeHtmlTags(html: string): string {
    return this.stripTags(html)
  }
}
