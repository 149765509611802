<div class="container w-full flex flex-col items-center">
  <div class="container-title px-2 mx-auto mb-8 mb-md-12 text-center">
    <h1 class="vcim-headline-1 mb-3">
      {{ 'website.dashboard.get-in-touch.title' | translate }}
    </h1>
    <span class="vcim-subtitle">
      {{ 'website.dashboard.get-in-touch.subtitle' | translate }}
    </span>
  </div>

  <div class="row mb-16 w-full">
    <div class="px-0 col-12 col-sm-6 col-lg-5 col-xl-4">
      <get-in-touch-form (save)="createMessage($event)"></get-in-touch-form>
    </div>
    <div class="px-0 pl-sm-10 px-xl-27 vcim-body-1 flex flex-col col-12 col-sm-6 col-lg-7 col-xl-8 pt-8 pt-sm-0">
      <app-contacts-info></app-contacts-info>
    </div>
  </div>
</div>
