import { Injectable } from '@angular/core';
import {
  BranchesListI,
  DoctorDataI,
  DoctorsFilterI,
  DoctorsListI,
  PaginationDto,
  ResponseDto,
  SpecializationsFilterI,
  SpecializationsI,
} from '@shared/interfaces';
import * as moment from 'moment';
import { map, Observable } from 'rxjs';
import { CitiesApiService } from '../api/cities.api.service';
import { DoctorsApiService } from '../api/doctors.api.service';
import { SpecializationsApiService } from '../api/specializations.api.service';

@Injectable({
  providedIn: 'root',
})
export class DoctorsService {
  constructor(
    private readonly doctorsSrv: DoctorsApiService,
    private readonly specsSrv: SpecializationsApiService,
    private readonly citiesSrv: CitiesApiService
  ) {}

  getDoctorsList(
    searchOptions: DoctorsFilterI
  ): Observable<PaginationDto<DoctorsListI>> {
    return this.doctorsSrv.getDoctorsList(searchOptions).pipe(
      map((d) => {
        return { list: d?.data || [], total: d?.total || 0 };
      })
    );
  }

  getSpecializationsList(
    searchOptions: SpecializationsFilterI
  ): Observable<PaginationDto<SpecializationsI>> {
    return this.specsSrv.getSpecializationsList({...searchOptions, filterForDoctors: true}).pipe(
      map((d) => {
        return { list: d?.data || [], total: d?.total || 0 };
      }));
  }

  getDoctorBySlug(slug: string): Observable<DoctorDataI> {
    const encodedSlug = encodeURIComponent(slug);
    return this.doctorsSrv
      .getDoctorBySlug(encodedSlug)
      .pipe(map((d) => d?.data || {} as DoctorDataI));
  }
  getBranchesList(cityIds: number[]): Observable<BranchesListI[]> {
    return this.citiesSrv.getBranchesList(cityIds).pipe(map((d) => d?.data));
  }
}
