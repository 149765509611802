<div class="container flex flex-col">
  <div class="container-title px-2 mx-auto mb-8 mb-md-12 text-center">
    <h1 class="vcim-headline-1 mb-3">
      {{ 'website.dashboard.services.title' | translate }}
    </h1>
    <span class="vcim-subtitle">
      {{ 'website.dashboard.services.subtitle' | translate }}
    </span>
  </div>

  <ng-container *ngIf="list.length > 1; else simple">
    <div class="px-4 px-xl-0 services-carousel">
      <owl-carousel-o [options]="customOptions">
        <ng-container *ngFor="let slide of list; let index">
          <ng-template class="slide" carouselSlide [id]="index.toString()">
            <app-services-slide [list]="slide"></app-services-slide
          ></ng-template>
          <!--  -->
        </ng-container>
      </owl-carousel-o>
    </div>
  </ng-container>

  <!-- ...service, -->

  <ng-template #simple>
    <div class="">
      <app-services-slide [list]="list[0]"></app-services-slide>
    </div>
  </ng-template>
</div>
