import { Injectable } from '@angular/core';
import { CustomPageDataI } from '@shared/interfaces';
import { map, Observable } from 'rxjs';
import { CustomPagesApiService } from '../api/custom-pages.api.service';

@Injectable({
  providedIn: 'root',
})
export class CustomPagesService {
  constructor(private readonly srv: CustomPagesApiService) {}

  getCustomPageBySlug(slug: string): Observable<CustomPageDataI> {
    return this.srv.getCustomPageBySlug(slug).pipe(map((d) => d?.data));
  }
}
