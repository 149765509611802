<div class="footer px-md-1 pt-8 pt-md-16 pt-xl-27">
  <div class="flex flex-col sm-flex-row pb-5 pb-md-10 footer-top vcim-container">
    <div class="flex flex-col">
      <img
        [routerLink]="'/' | localize"
        class="footer-logo mb-8 mb-md-12 pointer"
        alt="vardanants"
        [src]="'/assets/icons/logo_white_' + settings.getLanguage() + '.svg'" />

    
      <div class="d-none sm-d-flex lg-d-none mt-auto">
        <ng-container *ngTemplateOutlet="rightBlock"> </ng-container>
      </div>
    </div>

    <div class="footer-working-hours flex-grow pl-sm-10 px-md-37 px-lg-10 pl-xl-37 mx-auto mb-10 mb-sm-0">
      <app-contacts-info></app-contacts-info>
    </div>
    <div class="sm-d-none lg-d-flex">
      <ng-container *ngTemplateOutlet="rightBlock"> </ng-container>
    </div>
  </div>

  <div class="pt-4 pb-12 py-md-8 px-5 px-lg-8 text-center vcim-body-2">
    {{ 'website.layout.footer.copyright' | translate }}
    <a class="underline pointer" [routerLink]="'/help/terms-of-use' | localize">
      {{ 'website.layout.footer.terms-and-conditions' | translate }}
    </a>
    |
    <a class="underline pointer" [routerLink]="'/help/privacy-policy' | localize">
      {{ 'website.layout.footer.privacy-policy' | translate }}
    </a>
  </div>
</div>

<ng-template #socialIcons>
  <div class="flex mx--2 mb-10">
    <ng-container *ngIf="companyInfo$ | async as companyInfo">
      <a
        class="footer-social-icon mx-2 flex items-center justify-center pointer"
        rel="noreferrer"
        target="_blank"
        aria-label="facebook"
        [href]="companyInfo.facebook">
        <i class="f-20 vcim-icon ic-facebook"></i>
      </a>
      <a
        class="footer-social-icon mx-2 flex items-center justify-center pointer"
        rel="noreferrer"
        target="_blank"
        aria-label="twitter"
        [href]="companyInfo.twitter">
        <i class="f-20 vcim-icon ic-twitter"></i>
      </a>
      <a
        class="footer-social-icon mx-2 flex items-center justify-center pointer"
        rel="noreferrer"
        target="_blank"
        aria-label="instagram"
        [href]="companyInfo.instagram">
        <i class="f-20 vcim-icon ic-instagram"></i>
      </a>
      <a
        class="footer-social-icon mx-2 flex items-center justify-center pointer"
        rel="noreferrer"
        target="_blank"
        aria-label="linkedin"
        [href]="companyInfo.linkedin">
        <i class="f-20 vcim-icon ic-linkedin"></i>
      </a>
      <a
        class="footer-social-icon mx-2 flex items-center justify-center pointer"
        rel="noreferrer"
        target="_blank"
        aria-label="youtube"
        [href]="companyInfo.youtube">
        <i class="f-20 vcim-icon ic-youtube"></i>
      </a>
    </ng-container>
  </div>
</ng-template>

<ng-template #rightBlock>
  <div class="d-flex flex-col items-center sm-items-start lg-items-end mx-auto">
    <div class="mb-10 mt-auto">
      <app-translate></app-translate>
    </div>
    <div class="d-flex mb-8">
      <app-city-select></app-city-select>

      <ng-container *ngIf="isLoggedin$ | async; else showLoginBtn">
        <button mat-flat-button color="primary" class="vcim-button sm-button color-primary ml-4" (click)="logout()">
          {{ 'website.layout.header.logout' | translate }}
        </button>
      </ng-container>
    </div>
    <ng-container *ngTemplateOutlet="socialIcons"> </ng-container>
  </div>
</ng-template>

<ng-template #showLoginBtn>
  <button mat-flat-button color="primary" class="vcim-button sm-button color-primary ml-4" [routerLink]="'/login' | localize">
    {{ 'website.layout.header.login' | translate }}
  </button>
</ng-template>
