<div class="container dashboard-doctors flex flex-col">
  <div class="container-title px-2 mx-auto mb-8 mb-md-12 text-center">
    <h1 class="vcim-headline-1 mb-3">
      {{ "website.dashboard.doctors.title" | translate }}
    </h1>
    <span class="vcim-subtitle">
      {{ "website.dashboard.doctors.subtitle" | translate }}
    </span>
  </div>

  <ng-container *ngIf="!isLoading">
      <ng-container
        *ngTemplateOutlet="
          doctorsList.length > 4 ? carousel : simple;
          context: { doctorsList: doctorsList }
        "
      >
      </ng-container>
  </ng-container>

  <button
    [routerLink]="'/' + Paths.Doctors| localize"
    mat-stroked-button
    color="primary"
    class="vcim-button md-button color-primary mx-auto w-full w-sm-auto"
  >
    {{ "website.actions.see-more" | translate }}
  </button>
</div>

<ng-template #carousel let-doctorsList="doctorsList">
  <div
    class="slider-container mb-4 mb-sm-6 mb-lg-10 mx-0 px-4 mx-lg--8 px-xl-0"
  >
  <owl-carousel-o [options]="customOptions" (changed)="ChangePage($event)">
    <ng-container *ngFor="let slide of doctorsList; let index; fieldTrackBy">
      <ng-template class="slide" carouselSlide [id]="index.toString()">
        <div class="px-16 px-xs-4 px-lg-8 px-xl-6">
          <app-doctors-card [breakName]="true" *ngIf="slide" [doctor]="slide"></app-doctors-card>
        </div>
      </ng-template>
    </ng-container>
  </owl-carousel-o>
  </div>
</ng-template>

<ng-template #simple let-doctorsList="doctorsList">
  <div
    class="px-16 px-sm-0 vcim-grid vcim-grid-size-1 vcim-grid-size-2-xs vcim-grid-size-4-sm gap-4 gap-md-10 gap-xl-16 mb-16"
  >
    <div class="" *ngFor="let doctor of doctorsList; let i = index">
      <app-doctors-card  [breakName]="true" [doctor]="doctor"></app-doctors-card>
    </div>
  </div>
</ng-template>

<ng-template #showSpinner>
  <div style="height: 100px" class="my-10 d-flex justify-center">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
