<div class="app-container-wrap">
  <!-- Header Above -->

  <mat-sidenav-container
    (window:resize)="onResize()"
    class=""
    (backdropClick)="isSidenavOpen = !isSidenavOpen"
  >
    <mat-sidenav
      style="position: fixed !important"
      [opened]="isSidenavOpen"
      [mode]="'over'"
      position="end"
    >
      <app-sidenav-menu></app-sidenav-menu>
    </mat-sidenav>

    <mat-sidenav-content>
        <app-header *ngIf="!isErrPage" class="d-none lg-d-block"> </app-header>

        <app-header-mobile class="d-block lg-d-none" (triggerSidenav)="isSidenavOpen = !isSidenavOpen">
        </app-header-mobile>

      <main class="flex flex-col main-padding">
        <router-outlet></router-outlet>
      </main>
      <app-footer></app-footer>
    </mat-sidenav-content>
  </mat-sidenav-container>

  <!-- Demo purposes only -->
  <!-- <app-customizer (optionsChange)="receiveOptions($event)"></app-customizer> -->
</div>
