import { HaaderMenuItem, Paths } from '@shared';

export const headerLinks: HaaderMenuItem[] = [
  {
    titleKey: 'website.layout.header.links.about-us',
    children: [
      {
        titleKey: 'website.layout.header.links.our-visions',
        link: '/help/about-us',
      },
      {
        titleKey: 'website.layout.header.links.seminars',
        link: '/' + Paths.Seminars,
      },
      {
        titleKey: 'website.layout.header.links.requisites',
        link: '/help/requisites',
      },
      {
        titleKey: 'website.layout.header.links.opinions',
        link: '/help/opinions',
      },

      {
        titleKey: 'website.layout.header.links.news',
        link: '/' + Paths.News,
      },
      {
        titleKey: 'website.layout.header.links.faq',
        link: '/help/frequently-asked-questions',
      },
    ],
  },
  {
    titleKey: 'website.layout.header.links.services',
    children: [
      {
        titleKey: 'website.layout.header.links.services',
        link: '/' + Paths.Services,
      },
      {
        titleKey: 'website.layout.header.links.personal-packages',
        link: '/' + Paths.PersonalPackages,
      },
    ],
  },
  {
    titleKey: 'website.layout.header.links.pricing',
    link: '/' + Paths.PriceList,
  },
  {
    titleKey: 'website.layout.header.links.doctors',
    link: '/' + Paths.Doctors,
  },
  // {
  //   titleKey: 'website.layout.header.links.attachment',
  //   link: '/' + Paths.Attachments,
  // },

  {
    titleKey: 'website.layout.header.links.vacancies',
    link: '/' + Paths.Vacancies,
  },
  {
    titleKey: 'website.layout.header.links.contact',
    link: 'help/contact-us',
  },
];
