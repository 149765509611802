export interface CheckPatientFormI {
  foreignCitizen: boolean;
  ssn: string;
  name: string;
  surname: string;
  birthDate: string;
}

export enum EnterCodeType {
  email = 'email',
  phone = 'phone',
}
export interface ActivationCodeFormI {
  ssn: string;
  contact: string;
}
export interface ActivationCodeDto {
  codeExpiredAt: number;
  codeSentTo: EnterCodeType;
}
export interface ActivateFormI {
  code: string;
  ssn: string;
}
export interface SignUpFormI {
  name: string;
  surname: string;
  ssn: string;
  birthDate: string;
  email: string;
  phone: string;
  username: string;
  password: string;
  rePassword: string;
}

export interface ResetPassFormI {
  username: string;
  codeExpiredAt: number;
  codeSentTo: EnterCodeType;
  ssn: string;
}

export interface AuthDataLocal {
  name?: string;
  surname?: string;
  birthDate?: string;
  ssn?: string;
  foreignCitizen?: false;
  email?: string;
  phone?: string;
  codeSentTo?: EnterCodeType;
  codeExpiredAt?: number;
  case: number;
  codeData?: number | null;
  codeSent?: boolean;
  verificated?: boolean;
}
