import { ChangeDetectionStrategy, Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable, debounceTime, fromEvent } from 'rxjs';

@Component({
  selector: 'app-opinion-body',
  templateUrl: './opinion-body.component.html',
  styleUrls: ['./opinion-body.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OpinionBodyComponent implements OnInit {
  @Input() data: string = '';
 expanded$ = new BehaviorSubject(false);
  showSeeMore$ = new BehaviorSubject(false);
  @ViewChild('itemElement') itemElement!: any;
  constructor() {}

  ngOnInit() {
    fromEvent(window, 'resize')
      .pipe(debounceTime(200))
      .subscribe(event => {
        this.CalculateHeight();
      });
    this.CalculateHeight();
  }
  CalculateHeight(): void {
    this.showSeeMore$.next(false);
    this.expanded$.next(true);
    setTimeout(() => {
      this.showSeeMore$.next(this.itemElement.nativeElement.offsetHeight > 75);
      this.expanded$.next(false);
    }, 100);
  }
}
