<form
  [formGroup]="leaveReviewForm"
  class="form p-5 p-md-8 flex flex-col"
  (ngSubmit)="saveForm()"
>
  <div class="mb-6">
    <mat-form-field appearance="fill" subscriptSizing="dynamic" class="w-full">
      <input
        matInput
        [placeholder]="'website.help.get-in-touch.full-name' | translate"
        formControlName="fullName"
      />

      <mat-error *ngIf="leaveReviewForm.get('fullName')?.hasError('required')">
        {{
          "website.validations.required_field"
            | translate
              : {
                  field: translate.instant(
                    "website.help.get-in-touch.full-name"
                  )
                }
        }}
      </mat-error>
    </mat-form-field>
  </div>
  <div class="mb-6">
    <mat-form-field appearance="fill" subscriptSizing="dynamic" class="w-full">
      <input
        matInput
        [placeholder]="'website.help.get-in-touch.email' | translate"
        formControlName="email"
      />

      <mat-error *ngIf="leaveReviewForm.get('email')?.hasError('required')">
        {{
          "website.validations.required_field"
            | translate
              : { field: translate.instant("website.help.get-in-touch.email") }
        }}
      </mat-error>
      <mat-error *ngIf="leaveReviewForm.get('email')?.hasError('email')">
        {{ "website.validations.invalid_email" | translate }}
      </mat-error>
    </mat-form-field>
  </div>
  <div class="mb-6">
    <mat-form-field appearance="fill" subscriptSizing="dynamic" class="w-full">
      <input
        matInput
        [placeholder]="'website.help.get-in-touch.phone' | translate"
        formControlName="phone"
        mask="+000 (00) 00 00 00"
      />

      <mat-error *ngIf="leaveReviewForm.get('phone')?.hasError('required')">
        {{
          "website.validations.required_field"
            | translate
              : { field: translate.instant("website.help.get-in-touch.phone") }
        }}
      </mat-error>

      <mat-error *ngIf="leaveReviewForm.get('phone')?.hasError('mask')">
        {{ "website.validations.phone-format" | translate }}
      </mat-error>
    </mat-form-field>
  </div>
  <div class="mb-6">
    <mat-form-field appearance="fill" subscriptSizing="dynamic" class="w-full">
      <textarea
        matInput
        cdkTextareaAutosize
        #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="20"
        [placeholder]="'website.help.get-in-touch.message' | translate"
        formControlName="message"
      ></textarea>

      <mat-error *ngIf="leaveReviewForm.get('message')?.hasError('required')">
        {{
          "website.validations.required_field"
            | translate
              : {
                  field: translate.instant("website.help.get-in-touch.message")
                }
        }}
      </mat-error>
    </mat-form-field>
  </div>
  <div class="mb-6">
    <star-rating
      (ratingChange)="onRatingChange($event)"
      [rating]="leaveReviewForm.get('rate')?.value"
    ></star-rating>
  </div>
  <button
    type="submit"
    mat-flat-button
    [disabled]="!!leaveReviewForm.invalid"
    color="primary"
    class="vcim-button md-button color-grey-primary mt-auto w-full"
  >
    {{ "website.help.get-in-touch.send-message" | translate }}
  </button>
</form>
