import { Injectable } from '@angular/core';
import { ApplicationHttpClient } from '@s';
import { NewsDataI, NewsI, PaginationI, ResponseDto } from '@shared';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NewsApiService {
  constructor(private http: ApplicationHttpClient) {}

  getNewsList(searchOptions: PaginationI): Observable<ResponseDto<NewsI[]>> {
    return this.http.get<ResponseDto<NewsI[]>>('/portal/api/v1/news', {
      params: {
        page: searchOptions.pageNumber + 1,
        size: searchOptions.pageSize,
      },
    });
  }
  
  getNewsBySlug(slug: string): Observable<ResponseDto<NewsDataI>> {
    const encodedRoute = encodeURIComponent(slug);
    return this.http.get<ResponseDto<NewsDataI>>(
      `/portal/api/v1/news/bySlug/${encodedRoute}`
    );
  }
  getNewsRelatedById(id: number): Observable<ResponseDto<NewsI[]>> {
    return this.http.get<ResponseDto<NewsI[]>>(
      `/portal/api/v1/news/${id}/related-news`
    );
  }

  
}
