import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CitiesService } from '@shared/services/pages/cities.service';
import { CityPopupComponent } from './city-popup/city-popup.component';

@Component({
  selector: 'app-city-select',
  templateUrl: './city-select.component.html',
  styleUrls: ['./city-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CitySelectComponent implements OnInit {
  constructor(private readonly dialog: MatDialog, private readonly srv: CitiesService) {}

  ngOnInit() {}
  openPopup(): void {
    const dialogRef = this.dialog.open(CityPopupComponent, {
      data: {
        list: this.srv.allCities || [],
        selected: this.srv.selectedCities,
      },
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.srv.selectedCities = result;
      }
    });
  }
}
