import { Component, ChangeDetectionStrategy, OnInit, EventEmitter, Output, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { GlobalSearchI, GlobalSearchTypes } from '@front/app/shared';
import { GlobalService } from '@front/app/shared/services/global.service';
import { SettingsService } from '@s';
import { BehaviorSubject, Observable, debounceTime, map, of, switchMap, tap } from 'rxjs';

@Component({
  selector: 'app-header-mobile',
  templateUrl: './header-mobile.component.html',
  styleUrls: ['./header-mobile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderMobileComponent implements OnInit {
  @Output() readonly triggerSidenav = new EventEmitter();

  searchText$ = new BehaviorSubject<string>('');

  searchResult$: Observable<GlobalSearchI[]>;

  selectLoading$ = new BehaviorSubject<boolean>(true);

  constructor(
    private readonly srv: GlobalService,
    public readonly settings: SettingsService,
    private _sanitizer: DomSanitizer,
    private readonly router: Router
  ) {
    this.searchResult$ = this.searchText$.pipe(
      debounceTime(300),
      switchMap(searchText => {
        return searchText.length > 2
          ? this.srv.globalSearch(searchText).pipe(
              map(i =>
                i.map(item => {
                  return {
                    ...item,
                    title: this.stripTags(item.title) || '',
                    description: this.stripTags(item.description) || '',
                  };
                })
              ),
              tap(() => this.selectLoading$.next(false))
            )
          : of([]).pipe(tap(() => this.selectLoading$.next(false)));
      })
    );
  }

  ngOnInit() {}

  stripTags(html: string): string {
    const str = this._sanitizer.sanitize(SecurityContext.HTML, this._sanitizer.bypassSecurityTrustHtml(html));
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = str || '';
    return tempDiv.textContent || '';
  }

  ChangeSearchText(event: string): void {
    this.selectLoading$.next(true);
    this.searchText$.next(event);
  }

  selectSearchItem(event: GlobalSearchI) {
    switch (event.path) {
      case GlobalSearchTypes.services:
        this.router.navigate(['/' + this.settings.getLanguage() + '/services/' + event.slug + '/price']);
        break;

      case GlobalSearchTypes.doctors:
        this.router.navigate(['/' + this.settings.getLanguage() + '/doctors/' + event.slug]);
        break;

      case GlobalSearchTypes.specializations:
        if (event.type === 'personalPackage') {
          this.router.navigate(['/' + this.settings.getLanguage() + '/personal-packages/' + event.slug]);
        } else {
          this.router.navigate(['/' + this.settings.getLanguage() + '/services/' + event.slug]);
        }
        break;

      case GlobalSearchTypes.news:
        this.router.navigate(['/' + this.settings.getLanguage() + '/news/' + event.slug]);
        break;

      case GlobalSearchTypes.seminars:
        this.router.navigate(['/' + this.settings.getLanguage() + '/seminars/' + event.slug]);
        break;

      case GlobalSearchTypes.vacancies:
        this.router.navigate(['/' + this.settings.getLanguage() + '/vacancies/' + event.slug]);
        break;

      // case GlobalSearchTypes.companyDescription:
      //   this.router.navigate(['/' + this.settings.getLanguage() + '/help/description']);
      //   break;

      case GlobalSearchTypes.faqs:
        this.router.navigate(['/' + this.settings.getLanguage() + '/help/frequently-asked-questions']);
        break;
    }
  }
}
