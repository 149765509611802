import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, switchMap } from 'rxjs';
import { AppointmentCreateApiService } from '../api/appointment-create.api.service';
import { DoctorDataI, DoctorsFilterI, DoctorsListI, PaginationDto, PriceListItemI } from '../../interfaces';
import {
  AppointmentCreatePolicies,
  AppointmentCreateStateI,
  AppointmentsTableI,
  OrderConfirmModel,
  RatingFormI,
  RatingList,
  SheduleTimesFilterI,
  SheduleTimesI,
  SurveyDataI,
  SurveyFormI,
  TaskCreateFormI,
  TasksTableI,
} from '../../interfaces/personal-data.interface';
import { SettingsService } from '@s';
import { Router } from '@angular/router';
import { DoctorsApiService } from '../api/doctors.api.service';
import * as moment from 'moment';
import { PersonalDataApiService } from '../api/personal-data.api.service';

@Injectable({
  providedIn: 'root',
})
export class AppointmentCreateService {
  _appointmentCreateState$ = new BehaviorSubject<AppointmentCreateStateI>({ step: 0 });
  constructor(
    private readonly srv: AppointmentCreateApiService,
    public readonly settings: SettingsService,
    private readonly router: Router,
    private readonly doctorsSrv: DoctorsApiService,
    private readonly pdSrv: PersonalDataApiService
  ) { }

  set appointmentCreateState(value: AppointmentCreateStateI) {
    this._appointmentCreateState$.next(value);
  }

  get appointmentCreateState(): AppointmentCreateStateI {
    return this._appointmentCreateState$.value;
  }

  get appointmentCreateStateObs() {
    return this._appointmentCreateState$ as Observable<AppointmentCreateStateI>;
  }

  setSelectedPriceItem(item: PriceListItemI): void {
    this.appointmentCreateState = {
      ...this.appointmentCreateState,
      item,
      step: 1,
    };
    this.router.navigate(['/' + this.settings.getLanguage() + '/personal-data/appointments/create/step2']);
  }

  getAppointmentPolicies(serviceId: number): Observable<AppointmentCreatePolicies[]> {
    return this.srv.getAppointmentPolicies(serviceId).pipe(map(d => d?.data));
  }

  getDoctorsList(searchOptions: DoctorsFilterI): Observable<PaginationDto<DoctorsListI>> {
    return this.doctorsSrv.getDoctorsForCreateList(searchOptions).pipe(
      map(d => {
        return { list: d?.data || [], total: d?.total || 0 };
      })
    );
  }
  getScheduleDates(doctorId: number): Observable<string[]> {
    return this.srv.getScheduleDates(doctorId).pipe(map(d => d?.data));
  }
  getScheduleTimes(searchOptions: SheduleTimesFilterI): Observable<SheduleTimesI[]> {
    return this.srv.getScheduleTimes(searchOptions).pipe(map(d => d?.data));
  }
  createAppointment(): Observable<AppointmentsTableI> {
    const date = moment(this.appointmentCreateState.date!);
    const time = this.appointmentCreateState.time!;

    const [hours, minutes] = time.split(':').map(Number);

    date.hours(hours);
    date.minutes(minutes);
    const unix = date.valueOf();
    const insuranceData = this.appointmentCreateState.policy!.company
      ? {
        insuranceData: {
          insuranceCompany: this.appointmentCreateState.policy!.company || '',
          workingCompany: this.appointmentCreateState.policy!.companyId || '',
        },
      }
      : {};
    return this.srv
      .createAppointment({
        serviceId: this.appointmentCreateState.item!.id,
        branchId: this.appointmentCreateState.item!.branchId,
        doctorId: this.appointmentCreateState.doctorId!,
        policy: this.appointmentCreateState.policy!.policy,
        date: unix,
        ...insuranceData,
      })
      .pipe(map(d => d?.data));
  }
  getAppointmentDetails(id: string): Observable<AppointmentsTableI> {
    return this.pdSrv.getAppointments().pipe(switchMap(() => this.srv.getAppointmentDetails(id).pipe(map(d => d?.data))));
  }

  getAppointmentByOrderId(orderId: string): Observable<AppointmentsTableI> {
    return this.pdSrv.getAppointmentByOrderId(orderId);
  }

  orderPaymentConfirmed(order: OrderConfirmModel): Observable<AppointmentsTableI> {
    return this.pdSrv.orderPaymentConfirmed(order);
  }

  createTask(body: TaskCreateFormI): Observable<TasksTableI> {
    return this.srv.createTask(body).pipe(map(d => d?.data));
  }

  getDoctorBySlug(slug: string): Observable<PaginationDto<DoctorsListI>> {
    return this.doctorsSrv.getDoctorBySlug(slug).pipe(
      map(d => {
        return { list: [d?.data] || [], total: 1 };
      })
    );
  }
  cancelAppointment(id: string): Observable<void> {
    return this.srv.cancelAppointment(id).pipe(map(d => d?.data));
  }
  getCompanies(text: string): Observable<any> {
    return this.srv.getCompanies(text).pipe(map(d => d?.data || []));
  }
  getInsuranceCompanies(): Observable<any> {
    return this.srv.getInsuranceCompanies().pipe(map(d => d?.data || []));
  }
  createRating(id: string, body: RatingFormI): Observable<RatingFormI> {
    return this.srv.createRating(id, body).pipe(map(res => res.data));
  }
  getReadyRating(id: string): Observable<RatingFormI> {
    return this.srv.getReadyRating(id).pipe(map(res => res.data));
  }
  getSurveyForm(id: string): Observable<SurveyDataI> {
    return this.srv.getSurveyForm(id).pipe(map(res => res.data));
  }
  getSurveyFormPublic(slug: string): Observable<SurveyDataI> {
    return this.srv.getSurveyFormPublic(slug).pipe(map(res => res.data));
  }
  saveSurveyForm(id: string, body: SurveyFormI, files: any[] = []): Observable<void> {
    const formData = new FormData();
    // create json file from body and append this file to formData
    formData.append('data', new Blob([JSON.stringify(body)], { type: 'application/json' }), 'data.json');
    files.forEach(file => formData.append('files', file.answer, file.renameTo));
    return this.srv.saveSurveyForm(id, formData).pipe(map(res => res.data));
  }
  saveSurveyFormPublic(id: string, body: SurveyFormI, files: any[] = []): Observable<void> {
    const formData = new FormData();
    // create json file from body and append this file to formData
    formData.append('data', new Blob([JSON.stringify(body)], { type: 'application/json' }), 'data.json');
    files.forEach(file => formData.append('files', file.answer, file.renameTo));
    return this.srv.saveSurveyFormPublic(id, formData).pipe(map(res => res.data));
  }
}
