import { ChangeDetectionStrategy, Component, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { environment } from '@env/environment';
import { BaseComponent } from '@front/app/base-component';
import { SettingsService } from '@s';
import { CustomPageDataI } from '@shared';
import { CustomPagesService } from '@shared/services/pages/custom-pages.service';
import { Observable, filter } from 'rxjs';

@Component({
  selector: 'app-custom-pages',
  templateUrl: './custom-pages.component.html',
  styleUrls: ['./custom-pages.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomPagesComponent extends BaseComponent implements OnInit {
  @Input() slug?: string;
  @Input() fullWidth?: string;
  
  environment = environment;
  pageInfo$: Observable<CustomPageDataI | undefined>;
  constructor(
    private readonly srv: CustomPagesService,
    public readonly route: ActivatedRoute,
    private readonly router: Router,
    private cdr: ChangeDetectorRef,
    private readonly settings: SettingsService,
  ) {
    super();
    this.router.events
      .pipe(this.takeUntilDestroy(), filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        if (this.route.parent?.routeConfig?.path === 'pages') {
          const category = this.route.snapshot.queryParams.from;
          const slug = this.route.snapshot.queryParams.slug;
          if (category === 'news') {
            this.router.navigate(['/' + this.settings.getLanguage() + `/news/${slug}/pages/${this.route.snapshot.params.page}`], { replaceUrl: true });
          } else if (category === 'services') {
            this.router.navigate(['/' + this.settings.getLanguage() + '/services/pages/' + this.route.snapshot.params.page], { replaceUrl: true });
          } else {
            this.pageInfo$ = this.srv.getCustomPageBySlug(this.slug || this.route.snapshot.params.page);
            this.cdr.detectChanges();
          }
        }
      });
    this.pageInfo$ = this.srv.getCustomPageBySlug(
      this.slug || this.route.snapshot.params.page
    );
  }

  ngOnInit() { }
}
