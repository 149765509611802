import { Component, ElementRef, HostListener, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { SpecializationsI } from '@front/app/shared';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-services-slide',
  templateUrl: './services-slide.component.html',
  styleUrls: ['./services-slide.component.scss'],
})
export class ServicesSlideComponent implements OnInit {
  list$ = new BehaviorSubject<SpecializationsI[]>([]);

  @Input() set list(event: SpecializationsI[]) {
    this.list$.next(event);
  }
  @ViewChildren('itemElement') itemElements!: QueryList<ElementRef>;
  constructor() {}

  ngOnInit() {}
  ngAfterViewInit() {
    this.CalculateHeight();
  }
  @HostListener('window:resize')
  onWindowResize() {
    this.CalculateHeight();
  }

  CalculateHeight(): void {
    const itemHeights: number[] = [];

    this.itemElements.forEach(itemElement => {
      itemElement.nativeElement.style.height = 'auto';
    });
    this.itemElements.forEach(itemElement => {
      const height = itemElement.nativeElement.clientHeight;
      itemHeights.push(height);
    });

    const maxHeight = Math.max(...itemHeights);
    this.itemElements.forEach(itemElement => {
      itemElement.nativeElement.style.height = `${maxHeight / 16}rem`;
    });
  }
}
