import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { faqDescriptionI, faqListDto, faqListI, getInTouchFormI } from '@shared/interfaces/help.interface';
import { AboutUsService } from '@shared/services/pages/about-us.service';
import { BaseComponent } from '@front/app/base-component';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, switchMap, tap } from 'rxjs';
import { PaginationI } from '@front/app/shared';
import { PageEvent } from '@angular/material/paginator';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FaqComponent extends BaseComponent implements OnInit {
  faqInfos$: Observable<faqListI[]>;
  faqDescription$: Observable<faqDescriptionI>;
  total = 0;
  fulldata: faqListDto[] = [];
  pageSize = 5;
  private searchOptions$ = new BehaviorSubject<PaginationI>({
    pageNumber: 0,
    pageSize: this.pageSize,
  });
  constructor(
    private readonly srv: AboutUsService,
    private readonly toastr: ToastrService,
    private readonly translate: TranslateService,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {
    super();
    this.srv
      .getFaqInfo()
      .pipe(
        tap(i => {
          this.total = i.total;
          this.fulldata = i.list;
          this.searchOptions$.next({ ...this.searchOptions$.value, pageNumber: 0 });
        })
      )
      .subscribe();
    this.faqDescription$ = this.srv.getFaqDescription();
    this.faqInfos$ = this.searchOptions$.pipe(
      switchMap(searchOptions => {
        const items =
          this.fulldata.slice(searchOptions.pageNumber * searchOptions.pageSize, (searchOptions.pageNumber + 1) * searchOptions.pageSize) ||
          [];
        const groupedData: faqListI[] = items.reduce((acc: any, obj) => {
          const category = obj.category;

          const existingCategory = acc.find((c: any) => c.category === category);
          if (existingCategory) {
            existingCategory.list.push(obj);
          } else {
            acc.push({ category, list: [obj] });
          }

          return acc;
        }, []);
        return [groupedData];
      })
    );
  }

  ngOnInit() {}

  createMessage(e: getInTouchFormI): void {
    this.recaptchaV3Service.execute('importantAction').subscribe((token: string) => {
      this.srv
        .createFaqQuestion({ ...e, clientRecaptchaResponse: token })
        .pipe(this.takeUntilDestroy())
        .subscribe(() => {
          this.toastr.success(this.translate.instant('website.help.faq.message-saved'));
        });
    });
  }
  onPageChanged(event: PageEvent): void {
    this.searchOptions$.next({ ...this.searchOptions$.value, pageNumber: event.pageIndex });
  }
}
