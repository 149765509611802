import { Injectable } from '@angular/core';
import { BehaviorSubject, merge, of } from 'rxjs';
import { catchError, map, share, switchMap, tap } from 'rxjs/operators';
import { TokenService } from './token.service';
import { LoginService } from './login.service';
import { isEmptyObject } from './helpers';
import { User } from '@shared';
import { Router } from '@angular/router';
import { SettingsService } from '@s';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private user$ = new BehaviorSubject<User | null>(null);
  private change$ = merge(
    this.tokenService.change(),
    this.tokenService.refresh().pipe(switchMap(() => this.refresh()))
  ).pipe(
    switchMap(() => this.assignUser()),
    share()
  );

  constructor(
    private loginService: LoginService,
    private tokenService: TokenService,
    private settings: SettingsService,
    private readonly router: Router
  ) {}

  init() {
    return new Promise<void>((resolve) =>
      this.change$.subscribe(() => resolve())
    );
  }

  change() {
    return this.change$;
  }

  check() {
    return this.tokenService.valid();
  }

  login(username: string, password: string) {
    return this.loginService.login(username, password).pipe(
      map((token) => {
        this.tokenService.set(token.data);
        return {
          data: this.check(),
        };
      })
    );
  }

  refresh() {
    return this.loginService.refresh().pipe(
      catchError(() => of(undefined)),
      tap((token) => this.tokenService.set(token)),
      map(() => this.check())
    );
  }

  logout() {
    return this.loginService.logout().pipe(
      tap(() => this.tokenService.clear()),
      tap(() => this.router.navigate(['/' + this.settings.getLanguage()])),
      map(() => !this.check())
    );
  }

  user() {
    return this.user$.pipe(share());
  }
  userValue() {
    return this.user$.value;
  }

  // menu() {
  //   return iif(() => this.check(), this.loginService.menu(), of([]));
  // }

  private assignUser() {
    if (!this.check()) {
      return of(null).pipe(tap((user) => this.user$.next(user)));
    }

    if (!isEmptyObject(this.user$.getValue())) {
      return of(this.user$.getValue());
    }
    return this.loginService.me().pipe(
      tap((user) => {
        this.user$.next(user.data);
      }),
      // in case of error clear token
      catchError((e) => {
        if (e === -11) {
          this.tokenService.clear();
        }
        return of(null);
      })
    );
  }

  refreshPage(lang?: (typeof this.settings.langs)[number], urlLink?: string) {
    const language = lang?.short || this.settings.getLanguage();
    const url = urlLink !== undefined ? `/${language}/` + urlLink : this.router.url;
    const prev = this.router.routeReuseStrategy.shouldReuseRoute;
    const prevOSN = this.router.onSameUrlNavigation;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';

    this.router.navigate([url]);
    setTimeout(() => {
      this.router.routeReuseStrategy.shouldReuseRoute = prev;
      this.router.onSameUrlNavigation = prevOSN;
    }, 100);
  }
}
