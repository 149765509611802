import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { NewsI, Paths } from '@shared';

@Component({
  selector: 'app-dashboard-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class DashboardNewsComponent implements OnInit {
  Paths = Paths;
  @Input() public list: NewsI[] = [];
  constructor() {}

  ngOnInit() {}
}
