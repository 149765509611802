import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { SurveyItemI, SurveyTreeNodeI } from '@front/app/shared/interfaces/personal-data.interface';
import { ItemType } from '@s';

@Component({
  selector: 'app-survey-item',
  templateUrl: './survey-item.component.html',
  styleUrls: ['./survey-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SurveyItemComponent implements OnInit {
  @HostBinding('class') class = 'display-contents';

  @Input() node!: SurveyTreeNodeI;
  @Input() form!: FormGroup;
  @Input() list: SurveyItemI[] = [];
  formAnswerGroup: any; //AbstractControl<any, any> | undefined;
  formAnswerControl: AbstractControl<any, any> | undefined;
  SurveyItemType = ItemType;
  constructor() {}

  ngOnInit() {
    this.formAnswerGroup =
      (this.form.get('answers') as FormArray)!.controls.find(i => i.get('questionId')?.value === this.node.questionId)?.get('answer') ||
      undefined;

    this.formAnswerGroup?.addControl(
      this.node.id,
      new FormControl(this.node.itemType === this.SurveyItemType.CheckBox ? 'false' : '', Validators.compose([Validators.required]))
    );
    this.formAnswerControl = this.formAnswerGroup?.get(this.node.id);
  }

  changeValue(event: any, isFile = false) {
    if (!isFile) {
      this.formAnswerControl?.setValue(event.target.value);
      this.formAnswerControl?.markAsTouched();
    } else {
      const file: File = event.target.files[0];
      if(file) {
        this.formAnswerControl?.setValue(file);
        this.formAnswerControl?.markAsTouched();
      }
    }
  }

  changeRadio() {
    const titles = this.list
      .filter(i => i.questionId === this.node.questionId && i.itemType === this.SurveyItemType.RadioButton)
      .map(i => i.id);
    titles.forEach(title => {
      this.formAnswerGroup.get(title)?.setValue(false);
      this.formAnswerGroup.get(title)?.markAsTouched();
    });
    this.formAnswerControl?.setValue(true);
  }
  changeCheckbox(event: MatCheckboxChange) {
    const titles = this.list
      .filter(i => i.questionId === this.node.questionId && i.itemType === this.SurveyItemType.CheckBox)
      .map(i => i.id);
    titles.forEach(title => {
      this.formAnswerGroup.get(title)?.markAsTouched();
    });
    this.formAnswerControl?.setValue(event.checked);
  }
}
