import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { PrivacyPolicyDataI } from "@shared";
import { AboutUsService } from "@shared/services/pages/about-us.service";
import { Observable } from "rxjs";

@Component({
  selector: "app-privacy-policy",
  templateUrl: "./privacy-policy.component.html",
  styleUrls: ["./privacy-policy.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class PrivacyPolicyComponent implements OnInit {

  policyInfo$: Observable<PrivacyPolicyDataI>;
  constructor(
    // private readonly route: ActivatedRoute,
    private readonly srv: AboutUsService
  ) {
    this.policyInfo$ = this.srv.getPrivacyPolicy();
  }

  ngOnInit() {

  }
}
