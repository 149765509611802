import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { BranchesListI, DoctorsFilterI, DoctorsListI, SpecializationsFilterI, SpecializationsI } from '@shared';
import { CitiesService } from '@shared/services/pages/cities.service';
import { DoctorsService } from '@shared/services/pages/doctors.service';
import { BaseComponent } from '@front/app/base-component';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { switchMap, debounceTime, tap, map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-doctors-list',
  templateUrl: './doctors-list.component.html',
  styleUrls: ['./doctors-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DoctorsListComponent extends BaseComponent implements OnInit {
  doctorsList$: Observable<DoctorsListI[]>;
  specializationsList$: Observable<SpecializationsI[]>;

  branchesList$: Observable<BranchesListI[]>;
  private pageSize = 12;
  private total = 0;

  private items: DoctorsListI[] = [];
  doctorsScrollDisabled: boolean = false;

  private specializationsPageSize = 40;
  private specializationsTotal = 0;
  private specializationsItems: SpecializationsI[] = [];
  specializationsScrollDisabled: boolean = false;

  searchOptions$ = new BehaviorSubject<DoctorsFilterI>({
    pageNumber: 0,
    pageSize: this.pageSize,
  });
  private specializationsSearchOptions$ = new BehaviorSubject<SpecializationsFilterI>({
    pageNumber: 0,
    pageSize: this.specializationsPageSize,
  });

  constructor(
    private readonly srv: DoctorsService,
    private readonly citiesSrv: CitiesService,
    private readonly route: ActivatedRoute,
    private readonly router: Router
  ) {
    super();
    this.doctorsList$ = this.searchOptions$.pipe(
      switchMap(searchOptions => {
        return this.srv.getDoctorsList(searchOptions).pipe(
          tap(i => {
            this.total = i.total;
            this.items = [...this.items, ...i.list];
            this.doctorsScrollDisabled = false;
          }),
          map(i => {
            return this.items;
          })
        );
      })
    );

    this.specializationsList$ = this.specializationsSearchOptions$.pipe(
      switchMap(searchOptions => {
        return this.srv.getSpecializationsList(searchOptions).pipe(
          tap(i => {
            this.specializationsTotal = i.total;
            this.specializationsItems = [...this.specializationsItems, ...i?.list];
            this.specializationsScrollDisabled = false;
          }),
          map(i => {
            return this.specializationsItems;
          })
        );
      })
    );

    this.branchesList$ = this.citiesSrv.selectedCitiesObs.pipe(switchMap(i => (i.length ? this.srv.getBranchesList(i) : of([]))));
    this.citiesSrv.selectedCitiesObs
      .pipe(
        this.takeUntilDestroy(),
        tap(i => {
          this.items = [];
          this.searchOptions$.next({
            ...this.searchOptions$.value,
            pageNumber: 0,
            pageSize: this.pageSize,
            branchIds: [],
            specializationIds: [],
            cityIds: i,
          });
          this.specializationsItems = [];
          this.specializationsSearchOptions$.next({
            ...this.specializationsSearchOptions$.value,
            pageNumber: 0,
            pageSize: this.specializationsPageSize,
            cityIds: i,
          });
        })
      )
      .subscribe();
  }

  ngOnInit() {
    const specId = this.route.snapshot.queryParams.specIds
      ? Array.isArray(this.route.snapshot.queryParams.specIds)
        ? this.route.snapshot.queryParams.specIds
        : [this.route.snapshot.queryParams.specIds]
      : [];

    const branchIds = this.route.snapshot.queryParams.branchIds
      ? Array.isArray(this.route.snapshot.queryParams.branchIds)
        ? this.route.snapshot.queryParams.branchIds
        : [this.route.snapshot.queryParams.branchIds]
      : [];

    this.searchOptions$.next({
      ...this.searchOptions$.value,
      specializationIds: specId || [],
      branchIds: branchIds || [],
      searchText: this.route.snapshot.queryParams.searchText || '',
    });
  }

  onSpecialityChange(event: number[]): void {
    this.items = [];
    this.searchOptions$.next({
      ...this.searchOptions$.value,
      pageNumber: 0,
      pageSize: this.pageSize,
      specializationIds: event,
    });
    this.router.navigate([], {
      queryParams: {
        specIds: event,
      },
      queryParamsHandling: 'merge',
    });
  }

  onBranchChange(event: number[]): void {
    this.items = [];
    this.searchOptions$.next({
      ...this.searchOptions$.value,
      pageNumber: 0,
      pageSize: this.pageSize,
      branchIds: event,
    });
    this.router.navigate([], {
      queryParams: {
        branchIds: event,
      },
      queryParamsHandling: 'merge',
    });
  }

  ChangeSearchText(event: string): void {
    this.items = [];
    this.searchOptions$.next({
      ...this.searchOptions$.value,
      searchText: event,
    });
    this.router.navigate([], {
      queryParams: {
        searchText: event,
      },
      queryParamsHandling: 'merge',
    });
  }
  toggleDoctorsScrolled(): void {
    this.doctorsScrollDisabled = true;
    if (this.total > this.pageSize * (this.searchOptions$.value.pageNumber + 1)) {
      this.searchOptions$.next({
        ...this.searchOptions$.value,
        pageNumber: this.searchOptions$.value.pageNumber + 1,
      });
    }
  }
  toggleSpecializationsScrolled(): void {
    this.specializationsScrollDisabled = true;
    if (this.specializationsTotal > this.specializationsPageSize * (this.specializationsSearchOptions$.value.pageNumber + 1)) {
      this.specializationsSearchOptions$.next({
        ...this.specializationsSearchOptions$.value,
        pageNumber: this.specializationsSearchOptions$.value.pageNumber + 1,
      });
    }
  }
}
