import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { ErrorCodeComponent } from './components/error-code/error-code.component';

import { MaterialModule } from '../material.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgxPermissionsModule } from 'ngx-permissions';
import { DoctorsCardComponent } from './components/doctors-card/doctors-card.component';
import { NewsCardComponent } from './components/news-card/news-card.component';
import { ServicesCardComponent } from './components/services-card/services-card.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { LoadingImageComponent } from './components/loading-image/loading-image.component';
import { GetInTouchFormComponent } from './components/get-in-touch-form/get-in-touch-form.component';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { ToastrModule } from 'ngx-toastr';
import { FullScreenImageComponent } from './components/full-screen-image/full-screen-image.component';
import { CheckboxesFilterComponent } from './components/checkboxes-filter/checkboxes-filter.component';
import { NoImageComponent } from './components/no-image/no-image.component';
import { OurResultsComponent } from './components/results/results.component';
import { provideEnvironmentMeta } from '@ngx-meta/core';
import { SvgIconComponent } from './components/icon/icon.component';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { RadioFilterComponent } from './components/radio-filter/radio-filter.component';
import { ThousandsPipe } from './pipes/thousands.pipe';
import { ContactsInfoComponent } from './components/contacts-info/contacts-info.component';
import { PriceListComponent } from './components/price-list/price-list.component';
import { CodeInputComponent } from './components/code-input/code-input.component';
import { DynamicRouterLinkComponent } from './components/dynamic-link.component';
import { DynamicRouterLinkParser } from '@shared/services/dynamic-router.service';
import { DynamicHooksModule, HookParserEntry } from 'ngx-dynamic-hooks';
import { UserAvatarComponent } from './components/user-avatar/user-avatar.component';
import { ShareButtonComponent } from './components/share-button/share-button.component';
import { SelectServicePopupComponent } from './components/personal-data/services-popup/services-popup.component';
import { CreateRequestsTasksPopupComponent } from './components/personal-data/requests-tasks-popup/requests-tasks-popup.component';
import { ConfirmPopupComponent } from './components/confirm-popup/confirm-popup.component';
import { CustomPagesComponent } from '../routes/custom-pages/custom-pages/custom-pages.component';
import { FillSurveyComponent } from '../routes/personal-data/appointments/fill-survey/fill-survey.component';
import { SurveyItemComponent } from '../routes/personal-data/appointments/fill-survey/survey-item/survey-item.component';
import { SurveyCompletedComponent } from '../routes/personal-data/survey-completed/survey-completed.component';
import { Map3dComponent } from '../routes/3d-map/3d-map.component';

const componentParsers: Array<HookParserEntry> = [DynamicRouterLinkParser];

const MODULES: any[] = [
  CommonModule,
  RouterModule,
  ReactiveFormsModule,
  FormsModule,
  MaterialModule,
  TranslateModule,
  NgxPermissionsModule,
  InfiniteScrollModule,
  LocalizeRouterModule,
  NgxMaskDirective,
  NgxMaskPipe,
  DynamicHooksModule.forRoot({
    globalParsers: componentParsers,
  }),
  ToastrModule.forRoot({
    positionClass: 'toast-top-full-width',
  }),
];
const COMPONENTS_DYNAMIC: any[] = [];
const COMPONENTS: any[] = [
  FillSurveyComponent,
  SurveyCompletedComponent,
  Map3dComponent,
  SurveyItemComponent,
  ErrorCodeComponent,
  CustomPagesComponent,
  DynamicRouterLinkComponent,
  DoctorsCardComponent,
  NewsCardComponent,
  ServicesCardComponent,
  LoadingImageComponent,
  NoImageComponent,
  GetInTouchFormComponent,
  FullScreenImageComponent,
  CheckboxesFilterComponent,
  OurResultsComponent,
  ShareButtonComponent,
  SvgIconComponent,
  RadioFilterComponent,
  ContactsInfoComponent,
  ThousandsPipe,
  PriceListComponent,
  CodeInputComponent,
  UserAvatarComponent,
  SelectServicePopupComponent,
  CreateRequestsTasksPopupComponent,
  ConfirmPopupComponent,
];
@NgModule({
  imports: [...MODULES],
  exports: [...MODULES, ...COMPONENTS],
  declarations: [...COMPONENTS],
  providers: [provideEnvironmentMeta(), provideNgxMask()],
})
export class SharedModule {}
