<ng-container class="d-flex flex-col w-full" [ngSwitch]="node.itemType">
  <!-- titles -->
  <ng-container *ngSwitchCase="SurveyItemType.QuestionGroup">
    <div class="d-flex flex-col my-4 w-full">
      <mat-label class="d-flex vcim-headline-3 mb-2">{{ node.title }}</mat-label>
      <ng-container *ngTemplateOutlet="children"></ng-container>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="SurveyItemType.Question">
    <mat-label class="d-flex vcim-body-1 mb-2 mt-4">{{ node.title }}</mat-label>
    <ng-container *ngTemplateOutlet="children"></ng-container>
  </ng-container>

  <!-- displays -->
  <ng-container *ngSwitchCase="SurveyItemType.Row">
    <div class="d-flex flex-col w-full px-sm-4">
      <ng-container *ngTemplateOutlet="children"></ng-container>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="SurveyItemType.Column">
    <div class="d-flex flex-col sm-flex-row mx-sm--4">
      <ng-container *ngTemplateOutlet="children"></ng-container>
    </div>
  </ng-container>

  <!-- fields -->

  <!-- TextField -->
  <ng-container *ngSwitchCase="SurveyItemType.TextField">
    <div class="d-flex flex-col mb-4 w-full">
      <mat-form-field
        [ngClass]="{
          'mdc-text-field--invalid': formAnswerControl?.hasError('required') && formAnswerControl?.touched
        }"
        appearance="outline"
        subscriptSizing="dynamic"
        class="w-full vcim-survey-field">
        <input (change)="changeValue($event)" matInput [placeholder]="node.title" type="text" />
      </mat-form-field>

      <mat-error class="mt-2" *ngIf="formAnswerControl?.hasError('required') && formAnswerControl?.touched">
        {{ 'website.validations.required' | translate }}
      </mat-error>
      <ng-container *ngTemplateOutlet="children"></ng-container>
    </div>
  </ng-container>

  <!-- TextArea -->
  <ng-container *ngSwitchCase="SurveyItemType.TextArea">
    <div class="d-flex flex-col mb-4 w-full">
      <mat-form-field
        [ngClass]="{
          'mdc-text-field--invalid': formAnswerControl?.hasError('required') && formAnswerControl?.touched
        }"
        appearance="outline"
        subscriptSizing="dynamic"
        class="w-full vcim-survey-field">
        <textarea
          (change)="changeValue($event)"
          cdkAutosizeMinRows="10"
          cdkAutosizeMaxRows="20"
          matInput
          cdkTextareaAutosize
          #autosize="cdkTextareaAutosize"
          [placeholder]="node.title"></textarea>
      </mat-form-field>

      <mat-error class="mt-2" *ngIf="formAnswerControl?.hasError('required') && formAnswerControl?.touched">
        {{ 'website.validations.required' | translate }}
      </mat-error>
      <ng-container *ngTemplateOutlet="children"></ng-container>
    </div>
  </ng-container>

  <!-- Number -->
  <ng-container *ngSwitchCase="SurveyItemType.Number">
    <div class="d-flex flex-col mb-4 w-full">
      <mat-form-field
        [ngClass]="{
          'mdc-text-field--invalid': formAnswerControl?.hasError('required') && formAnswerControl?.touched
        }"
        appearance="outline"
        subscriptSizing="dynamic"
        class="w-full vcim-survey-field">
        <input (change)="changeValue($event)" type="number" matInput [placeholder]="node.title" />
      </mat-form-field>

      <mat-error class="mt-2" *ngIf="formAnswerControl?.hasError('required') && formAnswerControl?.touched">
        {{ 'website.validations.required' | translate }}
      </mat-error>
      <ng-container *ngTemplateOutlet="children"></ng-container>
    </div>
  </ng-container>

  <!-- Date -->
  <ng-container *ngSwitchCase="SurveyItemType.Date">
    <div class="d-flex flex-col mb-4 w-full">
      <mat-form-field
        [ngClass]="{
          'mdc-text-field--invalid': formAnswerControl?.hasError('required') && formAnswerControl?.touched
        }"
        appearance="outline"
        subscriptSizing="dynamic"
        class="w-full vcim-survey-field">
        <input (dateChange)="changeValue($event)" matInput [placeholder]="node.title" [matDatepicker]="picker" />
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker startView="multi-year"></mat-datepicker>
      </mat-form-field>

      <mat-error class="mt-2" *ngIf="formAnswerControl?.hasError('required') && formAnswerControl?.touched">
        {{ 'website.validations.required' | translate }}
      </mat-error>
      <ng-container *ngTemplateOutlet="children"></ng-container>
    </div>
  </ng-container>

  <!-- CheckBox -->
  <ng-container *ngSwitchCase="SurveyItemType.CheckBox">
    <div class="w-full mb-2">
      <mat-checkbox (change)="changeCheckbox($event)" class="vcim-checkbox color-cyan" color="primary">
        {{ node.title }}
      </mat-checkbox>
      <mat-error class="mt-2" *ngIf="formAnswerControl?.hasError('required') && formAnswerControl?.touched">
        {{ 'website.validations.required' | translate }}
      </mat-error>
    </div>
    <ng-container *ngTemplateOutlet="children"></ng-container>
  </ng-container>

  <!-- RadioButton -->
  <ng-container *ngSwitchCase="SurveyItemType.RadioButton">
    <div class="w-full mb-2">
      <mat-radio-button (change)="changeRadio()" [name]="node.questionId" [value]="node.title">{{ node.title }}</mat-radio-button>

      <mat-error class="mt-2" *ngIf="formAnswerControl?.hasError('required') && formAnswerControl?.touched">
        {{ 'website.validations.required' | translate }}
      </mat-error>
    </div>
    <ng-container *ngTemplateOutlet="children"></ng-container>
  </ng-container>

  <!-- FileUpload -->

  <ng-container *ngSwitchCase="SurveyItemType.FileUpload">
    <div class="d-flex flex-col mb-4 w-full">
      <mat-form-field
        [ngClass]="{
          'mdc-text-field--invalid': formAnswerControl?.hasError('required') && formAnswerControl?.touched
        }"
        appearance="outline"
        subscriptSizing="dynamic"
        class="w-full vcim-survey-field">
        <button type="button" mat-icon-button matPrefix (click)="f_input.click()">
          <mat-icon>attach_file</mat-icon>
        </button>
        <input type="text" #inputText readonly matInput [placeholder]="node.title" (click)="f_input.click()" />

        <input
          type="file"
          (change)="changeValue($event, true); inputText.value = $any($event.target)?.files[0]?.name"
          accept=".pdf, .jpg, .jpeg, .png, .doc, .docx"
          class="visually-hidden"
          #f_input />
      </mat-form-field>

      <mat-error class="mt-2" *ngIf="formAnswerControl?.hasError('required') && formAnswerControl?.touched">
        {{ 'website.validations.required' | translate }}
      </mat-error>
      <ng-container *ngTemplateOutlet="children"></ng-container>
    </div>
  </ng-container>

  <ng-container *ngSwitchDefault></ng-container>
</ng-container>

<ng-template #children>
  <ng-container *ngIf="node.children && node.children.length > 0">
    <ng-container *ngFor="let child of node.children">
      <app-survey-item [list]="list" [form]="form" [node]="child"></app-survey-item>
    </ng-container>
  </ng-container>
</ng-template>
