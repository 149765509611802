import { Component, OnInit, ChangeDetectionStrategy, Input, EventEmitter, Output, SecurityContext } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, FormArray } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'checkboxes-filter',
  templateUrl: './checkboxes-filter.component.html',
  styleUrls: ['./checkboxes-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxesFilterComponent implements OnInit {
  @Input() list: { title: string; id: number }[] = [];
  @Input() title: string = '';
  @Input() type: 'accordion' | 'block' = 'block';
  @Output() save = new EventEmitter<number[]>();
  @Input() set selectedIds(ids: number[]) {
    if (ids) {
      this.ids.clear();
      ids.forEach(id => {
        this.ids.push(new FormControl(id));
      });
    }
  }

  form: FormGroup;

  constructor(private readonly fb: FormBuilder, public readonly translate: TranslateService, private _sanitizer: DomSanitizer) {
    this.form = this.fb.group({
      ids: this.fb.array([]),
    });
  }

  get ids(): FormArray {
    return this.form.get('ids') as FormArray;
  }

  public handleCheckbox(event: any): void {
    if (event.checked) {
      this.ids.push(new FormControl(event.source.value));
    } else {
      this.ids.removeAt(this.ids.value.indexOf(event.source.value));
    }
    this.save.emit(this.ids.value);
  }
  ngOnInit() {}
  
  stripTags(html: string): string {
    const str = this._sanitizer.sanitize(SecurityContext.HTML, this._sanitizer.bypassSecurityTrustHtml(html));
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = str || '';
    return tempDiv.textContent || '';
  }

  removeHtmlTags(html: string): string {
    return this.stripTags(html)
  }
}
