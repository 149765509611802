import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { AdminLayoutComponent } from './admin-layout/admin-layout.component';
import { HeaderComponent } from './header/header.component';

import { TranslateComponent } from './widgets/translate/translate.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderMobileComponent } from './header/header-mobile/header-mobile.component';
import { SidenavMenuComponent } from './sidenav-menu/sidenav-menu.component';
import { CitySelectComponent } from './widgets/city/city-select.component';
import { CityPopupComponent } from './widgets/city/city-popup/city-popup.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MobileTranslateComponent } from './sidenav-menu/mobile-translate/mobile-translate.component';
import { MobileCitySelectComponent } from './sidenav-menu/mobile-city-select/mobile-city-select.component';

@NgModule({
  declarations: [
    AdminLayoutComponent,
    HeaderComponent,
    HeaderMobileComponent,
    FooterComponent,
    TranslateComponent,
    CitySelectComponent,
    CityPopupComponent,
    SidenavMenuComponent,
    MobileTranslateComponent,
    MobileCitySelectComponent
  ],
  imports: [SharedModule, MatAutocompleteModule],
})
export class LayoutModule {}
