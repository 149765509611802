import { Component, Input, OnInit } from "@angular/core";
import { environment } from "@env/environment";

@Component({
  selector: "app-user-avatar",
  templateUrl: "./user-avatar.component.html",
  styleUrls: ["./user-avatar.component.scss"]
})

export class UserAvatarComponent implements OnInit {
  @Input() img: string | null = null;
  @Input() imgPath: string = '';
  @Input() editable: boolean = false;

  environment = environment;
  constructor() { 

  }

  ngOnInit() {

  }
}
