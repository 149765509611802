
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { environment } from '@env/environment';
import {
  LocalizeRouterModule,
  LocalizeParser,
  LocalizeRouterSettings,
  ManualParserLoader,
} from '@gilsdav/ngx-translate-router';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { TranslatesService } from '@core/translates';
import { routes } from '@front/app/routes/routes';
import { Error403Component } from './sessions/403.component';
import { Error404Component } from './sessions/404.component';
import { Error500Component } from './sessions/500.component';
import { AdminLayoutComponent } from '@front/app/theme/admin-layout/admin-layout.component';
import { AuthGuard } from '../core';


export function createTranslateLoader(
  translate: TranslateService,
  location: Location,
  settings: LocalizeRouterSettings
) {
  settings.defaultLangFunction = function (locales: string[], cachedLang?: string, browserLang?: string) {
    return cachedLang || locales[0];
  }
  return new ManualParserLoader(
    translate,
    location,
    settings,
    ['hy', 'en', 'ru'],
    'ROUTES.'
  );
}
// TODO: crete them as standalone so routes could be imported outside of project

function getRoutes() {
  if (environment.fullVersion) {
    routes.push(
      {
        path: 'payment-result',
        redirectTo: 'payment/payment-result',
        data: { ssr: false, skipRouteLocalization: { localizeRedirectTo: true } },
      },
      {
        path: 'auth',
        loadChildren: () =>
          import('../theme/auth-layout/auth.module').then((m) => m.AuthModule),
        data: { ssr: false },
        // canLoad: [AuthGuard]
      },
    );
    routes[0].children!.push(
      {
        path: 'login',
        loadChildren: () =>
          import('./login/login.module').then((m) => m.LoginModule),
        data: { ssr: false },
      },
      {
        path: 'personal-data',
        loadChildren: () =>
          import('./personal-data/personal-data.module').then(
            (m) => m.PersonalDataModule
          ),
        data: { ssr: false },
        canLoad: [AuthGuard]
      },
      {
        path: 'payment',
        loadChildren: () =>
          import('./payment/payment.module').then((m) => m.PaymentModule),
        data: { ssr: false },
        canLoad: [AuthGuard]
      },
      {
        path: 'payment-result',
        redirectTo: 'payment/payment-result',
        data: { ssr: false },
      },
    )
  }
  routes[0].component = AdminLayoutComponent;
  routes[0]!.children!.find(route => route.path === '403')!.component = Error403Component
  routes[0]!.children!.find(route => route.path === '404')!.component = Error404Component
  routes[0]!.children!.find(route => route.path === '500')!.component = Error500Component
  return routes;
}

@NgModule({
  imports: [
    RouterModule.forRoot(getRoutes(), {
      useHash: environment.useHash,
      initialNavigation: 'disabled',
    }),
    LocalizeRouterModule.forRoot(routes, {
      parser: {
        provide: LocalizeParser,
        useFactory: createTranslateLoader,
        deps: [
          TranslateService,
          Location,
          LocalizeRouterSettings,
          TranslatesService,
        ],
      },
      initialNavigation: true,
    }),
  ],
  exports: [RouterModule, LocalizeRouterModule],
})
export class RoutesRoutingModule {}
