<div class="container w-full flex flex-col items-center">
  <div class="container-title px-2 mx-auto mb-8 mb-md-12 text-center">
    <h1 class="vcim-headline-1 mb-3">
      {{ "website.news.title" | translate }}
    </h1>
    <span class="vcim-subtitle">
      {{ "website.news.subtitle" | translate }}
    </span>
  </div>

  <div
    class="w-full mb-16 vcim-grid vcim-grid-size-1 vcim-grid-size-2-sm vcim-grid-size-3-md vcim-grid-size-4-xl gap-4 gap-md-10 gap-xl-10"
  >
    <div *ngFor="let news of list; let i = index" class="item">
      <app-news-card [news]="news"></app-news-card>
    </div>
  </div>
  
  <button
    mat-stroked-button
    [routerLink]="'/' + Paths.News | localize"
    color="primary"
    class="vcim-button md-button color-primary mx-auto w-full w-sm-auto"
  >
    {{ "website.news.see-more" | translate }}
  </button>
</div>
