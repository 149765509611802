import { Injectable, InjectionToken } from '@angular/core';
import { ApplicationHttpClient } from '@s';
import { ResponseDto } from '@shared/interfaces';
import { Observable } from 'rxjs';
export const IS_CRAWLER = new InjectionToken<boolean>('IsCrawler');

export interface AppointmentPayment {
  "paymentType": "idram" | "card" | ""
  "appointmentId": string
}
@Injectable({
  providedIn: 'root',
})
export class PaymentApiService {
  
  constructor(private http: ApplicationHttpClient) {}

  startPayment(body: AppointmentPayment): Observable<string> {
    return this.http.Post<string>('/patients/api/v1/payments/startAppointmentPayment', body);
  }

  check(body: AppointmentPayment): Observable<ResponseDto<any>> {
    return this.http.Post<ResponseDto<any>>('/payment-result', body);
  }
}
