import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";

@Component({
  selector: "map-3d",
  templateUrl: "./3d-map.component.html",
  styleUrls: ["./3d-map.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class Map3dComponent implements OnInit {
  
  constructor() { 

  }

  ngOnInit() {

  }
}
