import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@core';
import { HaaderMenuItem, User } from '@shared';
import { headerLinks } from '@theme/header/header-links';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-sidenav-menu',
  templateUrl: './sidenav-menu.component.html',
  styleUrls: ['./sidenav-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidenavMenuComponent {
  links = headerLinks;
  user$: Observable<User | null>;
  public startRoute = this.router.url.substring(this.router.url.indexOf('/', 1));
  constructor(private readonly auth: AuthService, private readonly router: Router) {
    this.user$ = this.auth.user();
  }
  logout(): void {
    this.auth.logout().subscribe();
  }
  isOpened(links: HaaderMenuItem[]): boolean {
    return links.some(link => this.startRoute === link.link);
  }
}
