import { Injectable } from '@angular/core';
import {
  SpecializationsFilterI,
  SpecializationsI,
  DoctorsFilterI,
  DoctorsListI,
  PaginationDto,
  PriceListFilterI,
  PriceListI,
  BranchesListI,
  PriceListItemI,
  PriceFullListFilterI,
} from '@shared/interfaces';
import { map, Observable } from 'rxjs';
import { CitiesApiService } from '../api/cities.api.service';
import { DoctorsApiService } from '../api/doctors.api.service';
import { ServicesApiService } from '../api/services.api.service';
import { SpecializationsApiService } from '../api/specializations.api.service';

@Injectable({
  providedIn: 'root',
})
export class ServicesService {
  constructor(
    private readonly serv: ServicesApiService,
    private readonly specsSrv: SpecializationsApiService,
    private readonly doctorsSrv: DoctorsApiService,
    private readonly citiesSrv: CitiesApiService
  ) {}

  getDoctorsList(searchOptions: DoctorsFilterI): Observable<PaginationDto<DoctorsListI>> {
    return this.doctorsSrv.getDoctorsList(searchOptions).pipe(
      map(d => {
        return { list: d?.data || [], total: d?.total || 0 };
      })
    );
  }

  getSpecializationsList(searchOptions: SpecializationsFilterI): Observable<PaginationDto<SpecializationsI>> {
    return this.specsSrv.getSpecializationsList(searchOptions).pipe(
      map(d => {
        return { list: d?.data || [], total: d?.total || 0 };
      })
    );
  }
  getPriceFullList(searchOptions: PriceFullListFilterI): Observable<PriceListItemI[]> {
    return this.serv.getPriceFullList(searchOptions).pipe(
      map(d => {
        return d?.data || [];
      })
    );
  }
  getSpecializationsBySlug(slug: string): Observable<SpecializationsI> {
    return this.specsSrv.getSpecializationBySlug(slug).pipe(map(d => d?.data));
  }
  getSpecializationDescription(): Observable<SpecializationsI> {
    return this.specsSrv.getSpecializationDescription().pipe(map(i => i?.data));
  }
  getPriceListDescription(): Observable<SpecializationsI> {
    return this.specsSrv.getPriceListDescription().pipe(map(i => i?.data));
  }
  getPersonalPackagesDescription(): Observable<SpecializationsI> {
    return this.specsSrv.getPersonalPackagesDescription().pipe(map(i => i?.data));
  }
  getPriceList(searchOptions: PriceListFilterI): Observable<PriceListI[]> {
    return this.serv.getPriceList(searchOptions).pipe(map(d => d?.data));
  }
  getBranchesList(cityIds: number[]): Observable<BranchesListI[]> {
    return this.citiesSrv.getBranchesList(cityIds).pipe(map(d => d?.data));
  }
  getPersonalPackagesList(): Observable<SpecializationsI[]> {
    return this.specsSrv.getPersonalPackagesList().pipe(map(d => d?.data.sort((a, b) => a.sortOrder - b.sortOrder) || []));
  }
  getPriceListItem(slug: string): Observable<PriceListItemI> {
    return this.serv.getPriceListItem(slug).pipe(map(d => {
      return d?.data && {...d.data, branchNames: d.data.branches.map(i => i.title)};
    }));
  }
}
