<header class="header flex flex-col px-0 h-auto">
  <div class="header-top flex">
    <div class="flex items-center justify-between flex-row vcim-container py-4 w-full">
      <span class="header-contacts vcim-body-2 f-14">
        <ng-container *ngIf="companyInfo$ | async as companyInfo">
          <a class="fw-700 mr-4 mr-xl-8" rel="noreferrer" target="_blank" [href]="'mailto:' + companyInfo.email">
            <i class="vcim-icon ic-mail f-20 pr-2"> </i> {{ companyInfo.email }}
          </a>
          <a class="fw-700 mr-4 mr-xl-8" rel="noreferrer" target="_blank" [href]="'tel:' + companyInfo.phone">
            <i class="vcim-icon ic-phone f-20 pr-2"> </i> {{ companyInfo.phone }}
          </a>
          <!-- <button mat-flat-button class="menu-button color-primary" color="primary" [routerLink]="'/3d-map' | localize">
            <div class="d-flex items-center">
              <mat-icon class="vcim-icon  f-20 pr-2">layers</mat-icon>
              <span class="mat-body-2 fw-700"> {{ 'website.layout.header.3d-map' | translate }} </span>
            </div>
          </button> -->
        </ng-container>
      </span>
      <div class="flex mx--3">
        <div class="px-3 d-flex">
          <app-city-select></app-city-select>
        </div>
        <div class="header-top-search d-flex items-center">
          <mat-form-field class="vcim-search px-3" subscriptSizing="dynamic" appearance="fill">
            <input
              class="py-1"
              [matAutocomplete]="auto"
              (input)="ChangeSearchText($any($event.target).value)"
              type="text"
              matInput
              [placeholder]="'website.layout.header.search-for' | translate" />
            <i class="vcim-icon ic-search pl-4 f-20"></i>
          </mat-form-field>

          <mat-autocomplete #auto="matAutocomplete">
            <ng-container *ngIf="searchResult$ | async as searchResult">
              <mat-option
                (click)="selectSearchItem(item)"
                class="f-14 fw-600 vcim-headline-5 py-2"
                *ngFor="let item of searchResult"
                [value]="item.title">
                <ngx-dynamic-hooks
                  [options]="{ sanitize: false }"
                  class="vcim-inner-html inner-html-title"
                  [content]="item.title"></ngx-dynamic-hooks>
              </mat-option>
            </ng-container>
            <div class="py-2 d-flex justify-center" *ngIf="selectLoading$ | async">
              <mat-spinner style="height: 40px"></mat-spinner>
            </div>
          </mat-autocomplete>
        </div>
        <div class="d-flex items-center">
          <app-translate></app-translate>
        </div>

        <ng-container *ngIf="user$ | async; else showLoginBtn">
          <button
            mat-flat-button
            color="primary"
            [routerLink]="'/personal-data' | localize"
            class="vcim-button color-primary mx-3 sm-button px-3 py-2">
            <i class="vcim-icon ic-user mr-2 f-20"></i>
            {{ 'website.layout.header.my-account' | translate }}
          </button>

          <button mat-flat-button color="primary" class="vcim-button color-primary mr-3 sm-button px-3 py-2" (click)="logout()">
            {{ 'website.layout.header.logout' | translate }}
          </button>
        </ng-container>
      </div>
    </div>
  </div>
  <div
    class="header-bottom flex animated"
    [ngClass]="{
      'fixed-header': isNavbarFixed
    }">
    <div class="flex items-center justify-between py-4 flex-row vcim-container w-full">
      <a class="pointer" [routerLink]="'/' | localize">
        <img class="header-bottom-logo mr-4 pointer" alt="vardanants" [src]="'/assets/icons/logo_' + settings.getLanguage() + '.svg'" />
      </a>
      <div class="header-bottom-links flex items-center mx--3 mx-xl--4">
        <div *ngFor="let link of links">
          <div *ngIf="link.children">
            <button
              class="mx-3 header-bottom-links-item whitespace-nowrap vcim-body-2 fw-500 flex items-center cursor-pointer"
              [matMenuTriggerFor]="menu">
              {{ link.titleKey | translate }}
              <i class="vcim-icon ic-arrow-down ml-2"></i>
            </button>

            <mat-menu #menu="matMenu" xPosition="after">
              <div class="header-bottom-links-menu py-1 flex flex-col">
                <a
                  *ngFor="let child of link.children"
                  routerLinkActive="active"
                  [routerLink]="child.link! | localize"
                  class="header-bottom-links-menu-item pl-3 pr-8 py-1 animated vcim-body-2 fw-500">
                  {{ child.titleKey | translate }}
                </a>
              </div>
            </mat-menu>
          </div>

          <a
            class="mx-3 header-bottom-links-item whitespace-nowrap vcim-body-2 fw-500"
            *ngIf="!link.children"
            routerLinkActive="active"
            [routerLink]="link.link! | localize">
            {{ link.titleKey | translate }}
          </a>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="isNavbarFixed" style="height: 90px"></div>
</header>

<ng-template #showLoginBtn>
  <button mat-flat-button color="primary" class="vcim-button color-primary mx-3 sm-button" [routerLink]="'/login' | localize">
    {{ 'website.layout.header.login' | translate }}
  </button>
</ng-template>
