<section class="vcim-container pt-8 pt-lg-16 mb-6 pb-10 pb-xl-16">
  <div *ngIf="priceListItem$ | async as priceListItem; else showSpinner" class="container w-full d-flex flex-col mx-auto">
    <div class="vcim-headline-3 mb-16">
      <ngx-dynamic-hooks
        [options]="{ sanitize: false }"
        class="vcim-inner-html inner-html-title"
        [content]="priceListItem.title"></ngx-dynamic-hooks>
    </div>
    <div class="flex flex-col mb-16">
      <div class="border-item flex justify-between vcim-body-1 mb-4">
        <span class="grey pr-2"> {{ 'website.personal-data.appointments.create.popup.branch' | translate }} </span>
        <span class="pr-2 fw-600 text-right">
          <ngx-dynamic-hooks
            [options]="{ sanitize: false }"
            class="vcim-inner-html inner-html-title"
            [content]="removeHtmlTags(priceListItem.branchNames.join(', '))"></ngx-dynamic-hooks>
        </span>
      </div>
      <div class="border-item flex justify-between vcim-body-1 mb-4">
        <span class="grey pr-2"> {{ 'website.personal-data.appointments.create.popup.price' | translate }} </span>
        <span class="pr-2 fw-600 text-right">
          {{ priceListItem.price | thousands }}
          AMD
        </span>
      </div>
    </div>
    <button
      *ngIf="auth.userValue()"
      mat-flat-button
      color="primary"
      class="mt-4 vcim-button color-primary md-button w-full"
      [disabled]="!auth.userValue()?.verified"
      (click)="selectService(priceListItem)">
      {{
        (priceListItem.personalPackage
          ? 'website.personal-data.appointments.create.popup.buy-package'
          : 'website.personal-data.appointments.create.popup.create-appointment'
        ) | translate
      }}
    </button>
    <button
      *ngIf="!auth.userValue()"
      [queryParams]="{ serviceSlug: priceListItem.slug }"
      [routerLink]="'/login' | localize"
      mat-flat-button
      color="primary"
      class="mt-4 vcim-button color-primary md-button w-full">
      {{
        (priceListItem.personalPackage
          ? 'website.personal-data.appointments.create.popup.buy-package'
          : 'website.personal-data.appointments.create.popup.create-appointment'
        ) | translate
      }}
    </button>
  </div>
</section>

<ng-template #showSpinner>
  <div style="height: 100px" class="my-10 d-flex justify-center">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
