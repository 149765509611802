import { Inject, Injectable, Renderer2 } from '@angular/core';
import { environment } from '@env/environment';
import { MimeToExtension } from '@front/app/core';
import { MetaService } from '@ngx-meta/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiToRoute, ProtocolService, SettingsService } from '@s';
import { CitiesService } from './pages/cities.service';
import { GlobalService } from './global.service';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
// import { routes } from "@front/app/routes/routes-routing.module";

// metadata type.
export type Metadata = {
    title?: string;
    description?: string;
    canonicalUrl?: string;
    robots?: string;
    author?: string;
    ogAuthor?: string;
    ogUrl?: string;
    ogType?: string;
    ogImage?: {
        data?: ImageData;
    };
};

// image data type.
export type ImageData = {
    attributes: {
        url: string;
        height?: number;
        width?: number;
    };
};

/**
 * Service used to take apply provided Metadata to the current page.
 */
@Injectable({ providedIn: 'root' })
export class MetaDataService {
    constructor(
        private meta: MetaService,
        public protocolService: ProtocolService,
        public readonly settings: SettingsService,
        public readonly translate: TranslateService,
        private readonly srv: GlobalService,
        @Inject(DOCUMENT) private _document: Document,
        private readonly citiesService: CitiesService,
        private router: Router,

    ) { }
    renderer!: Renderer2;
    title = '';
    description = '';
    keywords = '';
    ['og:image'] = '';

    public clearSeoCache() {
        this.title = '';
        this.description = '';
        this.keywords = '';
        this['og:image'] = '';
    }

    /**
     * Applies metadata from  to the current page.
     * @param { Metadata } data - metadata to apply.
     * @returns { void }
     */
    public apply(data: Record<string, any>, objectInfo?: Record<string, any>): void {
        if (this.description) {
            delete data.description;
        }
        if (this.keywords) {
            delete data.keywords;
        }
        if (data?.title) {
            this.title = data?.title;
        }
        if (data?.description) {
            this.description = data?.description;
        }
        if (data?.keywords) {
            this.keywords = data?.keywords;
        }
        if (data?.['og:image']) {
            this['og:image'] = data?.['og:image'];
        }

        let titleParsed = this.title || this.translate.instant('website.common.website-title');
        let descriptionParsed = this.description || this.translate.instant('website.common.website-title');

        const tempDiv = document.createElement('div');

        tempDiv.innerHTML = titleParsed;
        titleParsed = tempDiv.textContent || '';

        tempDiv.innerHTML = descriptionParsed;
        descriptionParsed = tempDiv.textContent || '';

        // clean title and description from html tags using 

        const img = this['og:image'] || this.protocolService.getUrl(environment.website + '/assets/images/logo-vertical-full.png');
        const extention = img && img.split('.').at(-1);

        this.meta.setTitle(titleParsed);
        this.meta.setTag('twitter:card', 'summary_large_image');
        this.meta.setTag('og:image', img);
        this.meta.setTag('og:image:secure_url', img);
        this.meta.setTag('og:image:type', MimeToExtension[extention as keyof typeof MimeToExtension]);
        this.meta.setTag('og:type', 'article');
        (this.meta as any).meta.updateTag({ property: 'fb:app_id', content: '845217403389659' });
        this.meta.setTag('og:url', 'https://' + environment.website + this.router.url);
        this.meta.setTag('og:site_name', this.translate.instant('website.common.website-title'));

        this.meta.setTag('description', descriptionParsed);
        this.meta.setTag('twitter:site', '@CenterVcim');
        this.meta.setTag('twitter:image', img);
        this.meta.setTag('twitter:image:alt', titleParsed);
        this.meta.setTag('twitter:description', descriptionParsed);
        this.meta.setTag('twitter:title', titleParsed);

        const object = ApiToRoute.find((r) => this.router.url.includes(r.value));
        // check if route is ending with one of ApiToRoute map values (with or without slash at the end)
        if (ApiToRoute.find((r) => this.router.url.endsWith(r.value) || this.router.url.endsWith(r.value + '/'))) {
            this.generateCommonJson(titleParsed, descriptionParsed, img);
        }
        // if ApiToRoute values are substring of the route
        else if (object) {
            this.generateArticleJson(titleParsed, descriptionParsed, img, object.value, data);
        } else {
            this.generateCommonJson(titleParsed, descriptionParsed, img);
        }
        if (!data) return;
        const { title, description, id, global, route, photo, photoPath, tags, include, ...metaData } = data;

        if (data.tags) {
            this.meta.setTag('keywords', data.tags);
        }
        Object.entries(metaData).forEach(([key, value]) => value && this.meta.setTag(key, value));
    }

    generateArticleJson(title: string, description: string, image: string, object: string, objectInfo?: Record<string, any>) {
        let json: Record<string, any>;
        const route = 'https://' + environment.website + this.router.url;
        if (object === 'doctors') {
            json = {
                '@context': 'http://schema.org',
                '@type': 'Person',
                name: objectInfo?.firstName + ' ' + objectInfo?.lastName,
                jobTitle: 'Doctor',
                affiliation: {
                    '@type': 'MedicalClinic',
                    name: this.translate.instant('website.common.website-title'),
                    address: {
                        '@type': 'PostalAddress',
                        streetAddress: 'Vardanants 18/1',
                        addressLocality: 'Yerevan',
                        postalCode: '0010',
                        addressCountry: 'Armenia',
                    },
                },
                image: {
                    '@type': 'ImageObject',
                    url: image,
                },
            };
            this.setJsonLd(JSON.stringify(json));
        } else if (object === 'news') {
            json = {
                '@context': 'http://schema.org',
                '@type': 'NewsArticle',
                mainEntityOfPage: {
                    '@type': 'WebPage',
                    '@id': 'https://' + environment.website + this.router.url,
                },
                headline: title,
                image: {
                    '@type': 'ImageObject',
                    url: image,
                },
                author: {
                    '@type': 'Organization',
                    logo: {
                        '@type': 'ImageObject',
                        url: 'https://' + environment.website + '/assets/images/logo.png',
                    },
                    name: 'Vardanants',
                },
                publisher: {
                    '@type': 'Organization',
                    name: 'Vardanants',
                    logo: {
                        '@type': 'ImageObject',
                        url: 'https://' + environment.website + '/assets/images/logo.png',
                    },
                },
                datePublished: new Date(objectInfo?.newsDate || new Date()).toISOString(),
                dateModified: new Date(objectInfo?.newsDate || new Date()).toISOString(),
                description
            };
        } else {
            json = {
                '@context': 'http://schema.org',
                '@type': 'WebPage',
                url: route,
                name: title,
                description,
                publisher: {
                    '@type': 'Organization',
                    name: 'Vardanants',
                    logo: {
                        '@type': 'ImageObject',
                        url: 'https://' + environment.website + '/assets/images/logo.png',
                    },
                },
                image: {
                    '@type': 'ImageObject',
                    url: image,
                }
            };
        }
        this.setJsonLd(JSON.stringify(json));
    }

    generateCommonJson(title: string, description: string, image: string) {

        const ci = this.srv.companyInfo$.value;
        // const cities = this.citiesService._allCities$.value;
        const seo = {
            title,
            description,
            image,
        };
        // const address = cities.find((c) => c.slug === 'kentronakan-klinika')?.address;
        const route = 'https://' + environment.website + this.router.url;
        const json = {
            '@context': 'http://www.schema.org',
            '@type': 'MedicalOrganization',
            '@id': route,
            name: this.escapeHtml(this.translate.instant('website.common.website-title')),
            legalName: this.escapeHtml(this.translate.instant('website.common.website-title')),
            description: this.escapeHtml(seo.description),
            logo: ['https://' + environment.website + '/assets/images/logo.png'],
            isAcceptingNewPatients: true,
            url: route,
            telephone: ci.phone,
            email: ci.email,
            priceRange: '$$',
            openingHours: [
                'mo 08:30-20:00',
                'Tu 08:30-20:00',
                'We 08:30-20:00',
                'Th 08:30-20:00',
                'Fr 08:30-20:00',
                'Sa 09:00-18:00',
                'Su 09:00-15:00',
            ],
            photo: [seo.image],
            image: [seo.image],
            sameAs: [ci.facebook, ci.instagram],
            hasMap: 'https://www.google.com/maps/@40.1790316,44.5172566z',
            address: {
                '@type': 'PostalAddress',
                streetAddress: 'Vardanants 18/1',
                addressLocality: 'Yerevan',
                postalCode: '0010',
                addressCountry: 'Armenia',
            },
            contactPoint: {
                '@type': 'ContactPoint',
                contactType: 'customer support',
                telephone: ci.phone,
                email: ci.email,
            },
            geo: {
                '@type': 'GeoCoordinates',
                latitude: '40.1790316',
                longitude: '44.5172566',
            },
        };
        this.setJsonLd(JSON.stringify(json));
    }

    escapeHtml(unsafe: string) {
        return unsafe
            .replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .replace(/"/g, "&quot;")
            .replace(/'/g, "&#039;");
    }

    /**
     * Set JSON-LD Microdata on the Document Body.
     *
     * @param renderer2             The Angular Renderer
     * @param data                  The data for the JSON-LD script
     * @returns                     Void
     */
    public setJsonLd(data: any): void {
        // remove previous JSON-LD script
        const previousJsonLd = this._document.querySelector('script[type="application/ld+json"]');
        if (previousJsonLd) {
            previousJsonLd.remove();
        }
        let script = this.renderer.createElement('script');
        script.type = 'application/ld+json';
        script.text = data;

        this.renderer.appendChild(this._document.body, script);
    }
    setTags(key: string, value: string) {
        this.meta.setTag(key, value);
    }

    removeTag(key: string) {
        this.meta.removeTag(key);
    }
}