import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@env/environment';
import { BaseComponent } from '@front/app/base-component';
import { AuthService } from '@front/app/core';
import { CreateRequestsTasksPopupComponent } from '@front/app/shared/components/personal-data/requests-tasks-popup/requests-tasks-popup.component';
import { SelectServicePopupComponent } from '@front/app/shared/components/personal-data/services-popup/services-popup.component';
import { AppointmentCreateService } from '@front/app/shared/services/pages/appointment-create.service';
import { SettingsService } from '@s';
import { PriceListItemI, SpecializationsI } from '@shared';
import { ServicesService } from '@shared/services/pages/services.service';
import { BehaviorSubject, Observable, map, of, switchMap, tap } from 'rxjs';

@Component({
  selector: 'app-personal-packages-list',
  templateUrl: './personal-packages-list.component.html',
  styleUrls: ['./personal-packages-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PersonalPackagesListComponent extends BaseComponent implements OnInit {
  environment = environment;
  specializationsList$: Observable<SpecializationsI[]>;
  private specializationsItems: SpecializationsI[] = [];
  specializationsScrollDisabled: boolean = false;
  spacializationsReady = false;
  showNotFound$ = new BehaviorSubject(false);
  
  specializationInfo$: Observable<{
    item: SpecializationsI;
    isDefault: boolean;
  } | null> = of(null);

  selectedId$ = new BehaviorSubject<number | undefined>(undefined);
  userVerified = false;
  user$ = this.auth.user().subscribe(i => (this.userVerified = i?.verified || false));

  searchText$ = new BehaviorSubject('');

  constructor(
    private readonly srv: ServicesService,
    private readonly router: Router,
    private readonly settings: SettingsService,
    private readonly route: ActivatedRoute,
    private readonly auth: AuthService,
    private readonly dialog: MatDialog,
    private readonly appSrv: AppointmentCreateService
  ) {
    super();
    this.specializationsList$ = this.srv.getPersonalPackagesList().pipe(
      tap(i => {
        this.spacializationsReady = true;
        this.specializationsItems = i;
      })
    );

    
    this.specializationInfo$ = this.route.params.pipe(
      this.takeUntilDestroy(),
      switchMap(snapshot => {
        if (snapshot.slug) {
          const item = this.specializationsItems.find(i => i.slug === snapshot.slug);
          if (item) {
            this.selectedId$.next(item.id);
            return of({
              item: item,
              isDefault: false,
            });
          } else {
            return this.srv.getSpecializationsBySlug(snapshot.slug).pipe(
              tap(i => {
                  this.selectedId$.next(i?.id);
              }),
              map(i => ({ item: i, isDefault: false }))
            );
          }
        } else {
          return this.srv.getPersonalPackagesDescription().pipe(map(i => ({ item: i || {}, isDefault: true })));
        }
      })
    );

  }

  ngOnInit() {}

  changeSpeciality(event: number) {
    const slug = this.specializationsItems.find(i => i.id === event)?.slug;
    this.router.navigate(['/' + this.settings.getLanguage() + '/personal-packages/' + slug]);
  }

  selectService(item: PriceListItemI): void {
    if (this.userVerified) {
      if (item.task) {
        this.dialog.open(CreateRequestsTasksPopupComponent, {
          data: item,
          autoFocus: false,
        });
      } else {
        const dialogRef = this.dialog.open(SelectServicePopupComponent, {
          data: item,
          autoFocus: false,
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.appSrv.setSelectedPriceItem(item);
          }
        });
      }
    } else {
      this.router.navigate(['/' + this.settings.getLanguage() + '/services/' + item.slug + '/price']);
    }
  }
}
