import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PersonalPackagesListComponent } from './personal-packages-list/personal-packages-list.component';

export const ppRoutes = [
  { path: '', component: PersonalPackagesListComponent },
  { path: ':slug', component: PersonalPackagesListComponent },
];
@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild(ppRoutes),
  ],
  exports: [
    RouterModule
  ]
})
export class PersonalPackagesRoutingModule {}
