export type DeepPartial<T> = {
    [K in keyof T]?: T[K] extends object ? DeepPartial<T[K]> | null : T[K] | null;
};

export const Crawlers = [
    'googlebot',
    'issemantic',
    'bingbot',
    'Google-InspectionTool',
    'yandexbot',
    'yandex',
    'lighthouse',
    'duckduckbot',
    'twitterbot',
    'facebookexternalhit',
    'facebookcatalog',
    'FB_IAB',
    'facebook',
    'FB4A',
    'FBAV',
    'FBAN',
    ';FB',
    'linkedinbot',
    'embedly',
    'baiduspider',
    'pinterest',
    'slackbot',
    'vkshare',
    'facebot',
    'outbrain',
    'w3c_validator',
    "bot", "crawler", "spider", "80legs", "baidu", "yahoo! slurp", "ia_archiver", "mediapartners-google",
    "lwp-trivial", "nederland.zoek", "ahoy", "anthill", "appie", "arale", "araneo",
    "atn_worldwide", "atomz", "bjaaland", "ukonline", "calif", "combine", "cosmos", "cusco",
    "cyberspyder", "digger", "grabber", "downloadexpress", "ecollector", "ebiness", "esculapio",
    "esther", "felix ide", "hamahakki", "kit-fireball", "fouineur", "freecrawl", "desertrealm",
    "gcreep", "golem", "griffon", "gromit", "gulliver", "gulper", "whowhere", "havindex", "hotwired",
    "htdig", "ingrid", "informant", "inspectorwww", "iron33", "teoma", "ask jeeves", "jeeves",
    "image.kapsi.net", "kdd-explorer", "label-grabber", "larbin", "linkidator", "linkwalker",
    "lockon", "marvin", "mattie", "mediafox", "merzscope", "nec-meshexplorer", "udmsearch", "moget",
    "motor", "muncher", "muninn", "muscatferret", "mwdsearch", "sharp-info-agent", "webmechanic",
    "netscoop", "newscan-online", "objectssearch", "orbsearch", "packrat", "pageboy", "parasite",
    "patric", "pegasus", "phpdig", "piltdownman", "pimptrain", "plumtreewebaccessor", "getterrobo-plus",
    "raven", "roadrunner", "robbie", "robocrawl", "robofox", "webbandit", "scooter", "search-au",
    "searchprocess", "senrigan", "shagseeker", "site valet", "skymob", "slurp", "snooper", "speedy",
    "curl_image_client", "suke", "www.sygol.com", "tach_bw", "templeton", "titin", "topiclink", "udmsearch",
    "urlck", "valkyrie libwww-perl", "verticrawl", "victoria", "webscout", "voyager", "crawlpaper",
    "webcatcher", "t-h-u-n-d-e-r-s-t-o-n-e", "webmoose", "pagesinventory", "webquest", "webreaper",
    "webwalker", "winona", "occam", "robi", "fdse", "jobo", "rhcs", "gazz", "dwcp", "yeti", "fido", "wlm",
    "wolp", "wwwc", "xget", "legs", "curl", "webs", "wget", "sift", "cmc"
];

export enum ItemType {
    TextField = "TextField",
    TextArea = "TextArea",
    Number = "Number",
    CheckBox = "CheckBox",
    RadioButton = "RadioButton",
    Select = "Select",
    Row = "Row",
    Empty = "Empty",
    Column = "Column",
    Date = "Date",
    QuestionGroup = "QuestionGroup",
    Question = "Question",
    FileUpload = 'FileUpload',
}

export const ApiToRoute = [
    { key: 'branches', value: 'branches' },
    { key: 'doctors', value: 'doctors' },
    { key: 'news', value: 'news' },
    { key: 'vacancies', value: 'vacancies' },
    { key: 'seminars', value: 'seminars' },
    { key: 'customPages', value: 'pages' },
    { key: 'personalPackages', value: 'personal-packages' },
    { key: 'services', value: 'price-list' },
    { key: 'surveys', value: 'survey' },
]

export const mapRouteToApiFront = {
    "branches": "branches",
    "pages": "custom-pages",
    "doctors": "doctors",
    "news": "news",
    "price-list": "specializations",
    "services": "services"
}
