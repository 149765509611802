import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DoctorsListComponent } from './doctors-list/doctors-list.component';
import { DoctorViewComponent } from './single-doctor-view/single-doctor-view.component';

export const childRoutes = [
  { path: '', component: DoctorsListComponent },
  { path: ':slug', component: DoctorViewComponent },
]
@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild(childRoutes),
  ],
  exports: [
    RouterModule
  ]
})
export class DoctorsRoutingModule {}
