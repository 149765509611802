import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '@front/app/base-component';
import { AuthService } from '@front/app/core';
import { MetaDataService } from '@front/app/shared/services/meta-data.service';
import { AppointmentCreateService } from '@front/app/shared/services/pages/appointment-create.service';
import { ProtocolService, SettingsService } from '@s';
import { DoctorDataI } from '@shared';
import { DoctorsService } from '@shared/services/pages/doctors.service';
import { Observable, switchMap, tap } from 'rxjs';

@Component({
  selector: 'app-doctor-view',
  templateUrl: './single-doctor-view.component.html',
  styleUrls: ['./single-doctor-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DoctorViewComponent extends BaseComponent implements OnInit {
  doctorInfo$: Observable<DoctorDataI>;
  user$ = this.auth.user();
  constructor(
    private readonly route: ActivatedRoute,
    private readonly srv: DoctorsService,
    private readonly auth: AuthService,
    private readonly appSrv: AppointmentCreateService,
    private readonly router: Router,
    private readonly meta: MetaDataService,
    public protocolService: ProtocolService,
    private readonly settings: SettingsService,
  ) {
    super()
    this.doctorInfo$ = this.srv.getDoctorBySlug(
      this.route.snapshot.params.slug
    );

    this.doctorInfo$ = this.route.params.pipe(
      this.takeUntilDestroy(),
      switchMap(_ => {
        return this.srv.getDoctorBySlug(
          this.route.snapshot.params.slug
        ).pipe(
          tap(async (data) => {
            if (data === undefined) {
              this.router.navigate([`/${this.settings.getLanguage()}/` + '/404']);
              return;
            }
          }))
      })
    );
  }

  ngOnInit() {
  }
  createAppointment(id: number, slug: string): void {
    this.appSrv.appointmentCreateState = { step: 0, doctorId: id, startDoctorSlug: slug };
    this.router.navigate(['/' + this.settings.getLanguage() + '/personal-data/appointments/create']);
  }
}
