import { NgModule } from '@angular/core';
import {
  HttpClientModule,
  HttpClient,
} from '@angular/common/http';
import {
  BrowserModule,
  BrowserTransferStateModule,
} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';

import { CoreModule } from '@core/core.module';
import { LayoutModule } from '@theme/theme.module';
import { SharedModule } from '@shared/shared.module';
import { NgxPermissionsModule } from 'ngx-permissions';

import { environment } from '@env/environment';
import { httpInterceptorProviders } from '@core';

import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { InMemDataService } from '@shared/in-mem/in-mem-data.service';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { TranslatesBrowserModule } from '@core/translates/translates-browser';
import { RoutesRoutingModule } from './routes/routes-routing.module';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { appInitializerProviders } from '@core/initializers';
import { RoutesModule } from '@front/app/routes/routes.module';
import { ApplicationHttpClient, SModule } from '@s';
import ru from '@angular/common/locales/ru';
import hy from '@angular/common/locales/hy';
import { registerLocaleData } from '@angular/common';
import { BASE_URL } from '../../../shared/src/lib/services/protocol.service';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
registerLocaleData(hy, 'hy');
registerLocaleData(ru, 'ru');

// the Request object only lives on the server
export function getRequest(): any {
  return { headers: { cookie: {} } };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    TransferHttpCacheModule,
    BrowserTransferStateModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CoreModule,
    LayoutModule,
    SharedModule,
    SModule,
    CarouselModule,
    RoutesModule,
    NgxPermissionsModule.forRoot(),
    TranslatesBrowserModule,
    RoutesRoutingModule,
    RecaptchaV3Module,
    HttpClientInMemoryWebApiModule.forRoot(InMemDataService, {
      dataEncapsulation: false,
      passThruUnknownUrl: true,
    }),
  ],
  providers: [
    { provide: BASE_URL, useValue: environment.baseUrl },
    httpInterceptorProviders,
    appInitializerProviders,
    {
      provide: HttpClient,
      useClass: ApplicationHttpClient,
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.gcaptchakey,
    },
    
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
