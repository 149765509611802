import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { getInTouchFormI } from '@shared';
import { AboutUsService } from '@shared/services/pages/about-us.service';
import { BaseComponent } from '@front/app/base-component';
import { ToastrService } from 'ngx-toastr';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'app-dashboard-get-in-touch',
  templateUrl: './get-in-touch.component.html',
  styleUrls: ['./get-in-touch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardGetInTouchComponent
  extends BaseComponent
  implements OnInit
{
  getInTouchForm: FormGroup;
  constructor(
    private readonly fb: FormBuilder,
    readonly translate: TranslateService,
    private readonly srv: AboutUsService,
    private readonly toastr: ToastrService,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {
    super();
    this.getInTouchForm = this.fb.group({
      fullName: ['', Validators.compose([Validators.required])],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      phone: ['', Validators.compose([Validators.required])],
      message: ['', Validators.compose([Validators.required])],
    });
  }

  createMessage(e: getInTouchFormI): void {

    this.recaptchaV3Service.execute('importantAction')
    .subscribe((token: string) => {
       this.srv
      .createContactQuestion({...e, clientRecaptchaResponse: token})
      .pipe(this.takeUntilDestroy())
      .subscribe((i) => {
        this.toastr.success(
          this.translate.instant('website.help.contact-us.message-saved')
        );
      });
    });


   
  }

  ngOnInit() {}
}
