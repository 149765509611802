import { Component, OnInit, ChangeDetectionStrategy, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '@env/environment';
import { WorkshopsFilterI, WorkshopsListI } from '@shared';
import { WorkshopsService } from '@shared/services/pages/workshops.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { switchMap, debounceTime, tap, map } from 'rxjs/operators';

@Component({
  selector: 'app-workshops-list',
  templateUrl: './workshops-list.component.html',
  styleUrls: ['./workshops-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkshopsListComponent implements OnInit {
  environment = environment;

  workshopsList$: Observable<WorkshopsListI[]>;
  private pageSize = 12;
  private total = 0;

  private items: WorkshopsListI[] = [];
  workshopsScrollDisabled: boolean = false;

  categoriesScrollDisabled: boolean = false;

  private searchOptions$ = new BehaviorSubject<WorkshopsFilterI>({
    pageNumber: 0,
    pageSize: this.pageSize,
  });

  constructor(private readonly srv: WorkshopsService, private _sanitizer: DomSanitizer) {
    this.workshopsList$ = this.searchOptions$.pipe(
      switchMap(searchOptions => {
        return this.srv.getWorkshopsList(searchOptions).pipe(
          tap(i => {
            this.total = i.total;
            this.items = [...this.items, ...i.list];
            this.workshopsScrollDisabled = false;
          }),
          map(i => {
            return this.items;
          })
        );
      })
    );
  }

  ngOnInit() {}

  toggleWorkshopsScrolled(): void {
    this.workshopsScrollDisabled = true;
    if (this.total > this.pageSize * (this.searchOptions$.value.pageNumber + 1)) {
      this.searchOptions$.next({
        ...this.searchOptions$.value,
        pageNumber: this.searchOptions$.value.pageNumber + 1,
      });
    }
  }

  stripTags(html: string): string {
    const str = this._sanitizer.sanitize(SecurityContext.HTML, this._sanitizer.bypassSecurityTrustHtml(html));
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = str || '';
    return tempDiv.textContent || '';
  }
  removeHtmlTags(html: string): string {
    return this.stripTags(html)
  }
}
