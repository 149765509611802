<section class="mb-16 mb-md-37">
  <app-dashboard-banner></app-dashboard-banner>
</section>

<ng-container>
  <section class="vcim-container mb-16 mb-md-37">
    <app-dashboard-services></app-dashboard-services>
  </section>
</ng-container>

<section class="vcim-container mb-16 mb-md-37">
  <app-dashboard-branches></app-dashboard-branches>
</section>

<section class="vcim-container mb-16 mb-md-37">
  <app-dashboard-doctors></app-dashboard-doctors>
</section>

<section class="vcim-container mb-16 mb-md-37">
  <app-our-results></app-our-results>
</section>

<ng-container *ngIf="newsList$ | async as newsList; else showSpinner">
  <section *ngIf="newsList.length" class="vcim-container mb-16 mb-md-37">
    <app-dashboard-news [list]="newsList"></app-dashboard-news>
  </section>
</ng-container>

<!-- <section class="vcim-container mb-16 mb-md-37">
  <app-dashboard-partners></app-dashboard-partners>
</section> -->
<section class="vcim-container mb-16 mb-md-37">
  <app-dashboard-get-in-touch></app-dashboard-get-in-touch>
</section>

<ng-template #showSpinner>
  <div style="height: 100px" class="my-10 d-flex justify-center">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
