import { Component, OnInit, ChangeDetectionStrategy, Input, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '@env/environment';
import { NewsI } from '@shared/interfaces';

@Component({
  selector: 'app-news-card',
  templateUrl: './news-card.component.html',
  styleUrls: ['./news-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewsCardComponent implements OnInit {
  environment = environment;
  // @Input() public news!: NewsI;
  private _news!: NewsI;
  @Input()
  get news(): NewsI {
    this._news.title = this.removeHtmlTags(this._news.title);
    return this._news;
  }
  set news(news: NewsI) {
    this._news = news;
  }

  constructor(private _sanitizer: DomSanitizer) {}
  ngOnInit() {}

  stripTags(html: string): string {
    const str = this._sanitizer.sanitize(SecurityContext.HTML, this._sanitizer.bypassSecurityTrustHtml(html));
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = str || '';
    return tempDiv.textContent || '';
  }

  removeHtmlTags(html: string, crop?: boolean): string {
    return crop ? this.stripTags(html).substring(0, 150) : this.stripTags(html);
  }
}
