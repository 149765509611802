import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '@env/environment';
import { SettingsService } from '@s';
import { DynamicRouterLinkComponent } from '@shared/components/dynamic-link.component';
import { HookParser, HookPosition, HookValue, HookComponentData, HookBindings, HookFinder } from 'ngx-dynamic-hooks';

@Injectable({
  providedIn: 'root',
})
export class DynamicRouterLinkParser implements HookParser {
  linkOpeningTagRegex;
  linkClosingTagRegex;
  hrefAttrRegex;
  wholeHrefUrlRegex;
  classNameRegex;
  content!: string;

  constructor(private hookFinder: HookFinder, private router: Router, private route: ActivatedRoute, private settings: SettingsService) {
    // Lets assemble a regex that finds the opening <a>-tags for internal links
    // dommain name and port

    const domainName = this.escapeRegExp(environment.website);
    const internalUrl = '(?:(?:https?:)?\\/\\/(?:www\\.)?' + domainName + '|(?!(?:https?:)?\\/\\/))([^\\"]*?)';
    const hrefAttr = '\\s+href=\\"' + internalUrl + '\\"';
    const anyOtherAttr = '\\s+[a-zA-Z]+\\=\\"[^\\"]*?\\"';
    const linkOpeningTag = '\\<a(?:' + anyOtherAttr + ')*?' + hrefAttr + '(?:' + anyOtherAttr + ')*?\\>';

    // Transform into proper regex objects and save for later
    this.linkOpeningTagRegex = new RegExp(linkOpeningTag, 'gim');
    this.linkClosingTagRegex = new RegExp('<\\/a>', 'gim');
    this.hrefAttrRegex = new RegExp(hrefAttr, 'im');
    this.wholeHrefUrlRegex = new RegExp('\\s+href=\\"([^\\"]*?)\\"', 'im');
    this.classNameRegex = new RegExp('\\s+class=\\"([^\\"]*?)\\"', 'im');

    // this.textBetweenTagsRegex = new RegExp('(?<=<a[^>]*>)(.*?)(?=<\\/a>)', 'gim');
  }

  public findHooks(content: string, context: any): Array<HookPosition> {
    // With the regexes we prepared, we can simply use findEnclosingHooks() to retrieve
    // the HookPositions of all internal <a>-elements from the content string
    this.content = content;
    return this.hookFinder.findEnclosingHooks(content, this.linkOpeningTagRegex, this.linkClosingTagRegex, true);
  }

  public loadComponent(hookId: number, hookValue: HookValue, context: any, childNodes: Array<Element>): HookComponentData {
    // Simply return the component class here
    return {
      component: DynamicRouterLinkComponent,
    };
  }

  extractString(inputString: string): { cat: string; slug: string } | null {
    const startString = `/${this.settings.getLanguage()}/`;
    const endString = '/';

    const startIndex = inputString.indexOf(startString);
    if (startIndex !== -1) {
      const endIndex = inputString.indexOf(endString, startIndex + startString.length);
      if (endIndex !== -1) {
        return {
          cat: inputString.substring(startIndex + startString.length, endIndex),
          slug: inputString.substring(endIndex),
        };
      }
    }

    return null; // Return null if either start or end string is not found
  }

  public getBindings(hookId: number, hookValue: HookValue, context: any): HookBindings {
    const fromPage = this.extractString(this.router.url);
    const category = fromPage ? `?from=${fromPage.cat}&slug=${fromPage.slug}` : '';
    // We can reuse the hrefAttrRegex here as its first capture group is the relative part of the url,
    // e.g. '/jedi/windu' from 'https://www.mysite.com/jedi/windu', which is what we need
    const hrefAttrMatch = hookValue.openingTag.match(this.hrefAttrRegex);
    let relativeLink = hrefAttrMatch ? hrefAttrMatch[1] : '';
    relativeLink = relativeLink + category;

    // The relative part of the link may still contain the query string and the
    // anchor fragment, so we need to split it up accordingly
    const anchorFragmentSplit = relativeLink.split('#');
    relativeLink = anchorFragmentSplit[0];
    const anchorFragment = anchorFragmentSplit.length > 1 ? anchorFragmentSplit[1] : null;

    const queryParamsSplit = relativeLink.split('?');
    relativeLink = queryParamsSplit[0];
    const queryParams = queryParamsSplit.length > 1 ? this.parseQueryString(queryParamsSplit[1]) : {};

    if (relativeLink.startsWith('/')) {
      relativeLink = relativeLink.slice(1);
    }

    // if not starting with any of keys of environment.baseUrl with/without / at start
    if (!relativeLink.startsWith('http')) {
      const baseUrlKeys = Object.keys(environment.baseUrl);
      const startsWithBaseUrl = baseUrlKeys.some(key => relativeLink.startsWith(key) || relativeLink.startsWith('/' + key));
      // starts with lang short
      if (this.settings.langs.map(lang => lang.short).some(lang => relativeLink.startsWith(lang))) {
        relativeLink = '/' + relativeLink;
      } else if (!startsWithBaseUrl && !relativeLink.startsWith('mailto') && !relativeLink.startsWith('tel')) {
        relativeLink = '/' + this.settings.getLanguage() + '/' + relativeLink;
      } else {
        relativeLink = hookValue.openingTag.match(this.wholeHrefUrlRegex)?.[1] || relativeLink;
      }
    }

    // Get the classList from the opening tag
    const classListMatch = hookValue.openingTag.match(this.classNameRegex);
    const classList = classListMatch ? classListMatch[1] : '';

    // Give all of these to our DynamicRouterLinkComponent as inputs and we're done!
    return {
      inputs: {
        link: relativeLink,
        queryParams: queryParams,
        anchorFragment: anchorFragment,
        classList,
      },
    };
  }

  /**
   * A helper function that safely escapes the special regex chars of any string so it
   * can be used literally in a Regex.
   * Approach by coolaj86 & Darren Cook @ https://stackoverflow.com/a/6969486/3099523
   *
   * @param string - The string to escape
   */
  private escapeRegExp(string: string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  /**
   * A helper function that transforms a query string into a QueryParams object
   * Approach by Wolfgang Kuehn @ https://stackoverflow.com/a/8649003/3099523
   *
   * @param queryParamString - The queryString to parse
   */
  private parseQueryString(queryParamString: string): { [key: string]: any } {
    return JSON.parse('{"' + decodeURI(queryParamString).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');
  }
}
