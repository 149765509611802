<section class="vcim-container container pt-8 pt-lg-16 mb-6 pb-10 pb-xl-16">
  <ng-container *ngIf="specializationInfo$ | async as specializationInfo; else showSpinner">
    <ng-container *ngIf="specializationInfo.item; else showNotFoundTemp">
      <div class="container-title px-2 mx-auto mb-8 mb-md-12 text-center" *ngIf="!route.snapshot.params.page">
        <h1 class="vcim-headline-1 mb-3" *ngIf="specializationInfo.isDefault; else showTitle">
          {{ 'website.services.title' | translate }}
        </h1>
        <ng-template #showTitle>
          <h1 class="mb-3 vcim-headline-1">
            <ngx-dynamic-hooks
              [options]="{ sanitize: false }"
              class="vcim-inner-html inner-html-title"
              [content]="specializationInfo.item.title"></ngx-dynamic-hooks>
          </h1>
        </ng-template>
        <span class="vcim-subtitle flex mx-auto" *ngIf="specializationInfo.isDefault">
          {{ 'website.services.subtitle' | translate }}
        </span>
      </div>

      <div class="row wrap-reverse md-flex-wrap mx-0 mx-md--4 mx-lg--8 mb-3 mb-md-6">
        <div class="col-12 col-md-5 col-lg-4 px-0 px-md-4 px-lg-8 mt-8 mt-md-0">
          <div class="w-full mb-8">
            <mat-form-field class="vcim-search md-field" subscriptSizing="dynamic" appearance="fill">
              <input
                type="text"
                matInput
                (input)="ChangeSearchText($any($event.target).value)"
                [placeholder]="'website.services.search-for-services' | translate" />
              <i class="vcim-icon ic-search pl-4 f-20"></i>
            </mat-form-field>
          </div>
          <div
            infinite-scroll
            [infiniteScrollDistance]="1"
            [infiniteScrollThrottle]="300"
            (scrolled)="toggleSpecializationsScrolled()"
            [infiniteScrollDisabled]="specializationsScrollDisabled"
            *ngIf="specializationsList$ | async as specializationsList; else showSpinner">
            <div class="container-radio">
              <app-radio-filter
                [selected]="(selectedId$ | async) || undefined"
                [list]="specializationsList"
                (save)="changeSpeciality($event)">
              </app-radio-filter>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-7 col-lg-8 px-0 px-md-4 px-lg-8">
          <div class="specialization-info d-flex flex-col">
            <ng-container *ngIf="route.snapshot.params.page">
              <app-custom-pages fullWidth></app-custom-pages>
            </ng-container>
            <ng-container *ngIf="specializationInfo.item && !route.snapshot.params.page">
              <div class="d-flex justify-end" *ngIf="!specializationInfo.isDefault">
                <app-share-button appId="845217403389659" icon="ic-facebook" button="facebook" class="d-flex ml-2"></app-share-button>
                <app-share-button icon="ic-linkedin" button="linkedin" class="d-flex ml-2"></app-share-button>
                <app-share-button icon="ic-twitter" button="twitter" class="d-flex ml-2"></app-share-button>

                <button mat-icon-button class="d-flex items-center justify-center ml-2" [cdkCopyToClipboard]="link" (click)="copy()">
                  <i class="vcim-icon d-flex ic-share f-20"> </i>
                </button>
                <textarea [(ngModel)]="link" class="visually-hidden"></textarea>
              </div>

              <ng-container
                *ngTemplateOutlet="
                  image;
                  context: {
                    photo: specializationInfo.isDefault ? specializationInfo.item.photo : specializationInfo.item.coverPhoto
                  }
                ">
              </ng-container>

              <ng-template #image let-photo="photo">
                <div class="image mb-5" *ngIf="photo">
                  <loading-image
                    [image]="'/portal/' + specializationInfo.item.photoPath + (photo.endsWith('.svg') ? '/' : '/medium_') + photo"
                    [styles]="{
                      'object-fit': 'cover',
                      'object-position': 'center'
                    }"
                    class="w-full h-full">
                  </loading-image>
                </div>
              </ng-template>

              <h2 *ngIf="specializationInfo.isDefault && specializationInfo.item.title" class="vcim-headline-2 mb-8 text-center">
                <ngx-dynamic-hooks
                  [options]="{ sanitize: false }"
                  class="vcim-inner-html inner-html-title"
                  [content]="specializationInfo.item.title"></ngx-dynamic-hooks>
              </h2>

              <div class="mb-10" *ngIf="specializationInfo.item.description">
                <ngx-dynamic-hooks
                  [options]="{ sanitize: false }"
                  *ngIf="specializationInfo.item.description"
                  class="vcim-body-1 vcim-inner-html mb-5"
                  [content]="specializationInfo.item.description"></ngx-dynamic-hooks>
              </div>

              <ng-container *ngIf="!specializationInfo.isDefault">
                <app-services-doctors [specId]="[specializationInfo.item.id]"></app-services-doctors>
                <div class="pricing">
                  <app-service-price-list
                    [searchText]="(searchText$ | async) || ''"
                    [selectable]="true"
                    (onSelect)="selectService($event)"
                    [specId]="specializationInfo.item.id"></app-service-price-list>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</section>

<ng-template #showSpinner>
  <div style="height: 100px" class="my-10 d-flex justify-center">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>

<ng-template #showNotFoundTemp>
  <div class="container-title px-2 mx-auto mb-8 mb-md-12 text-center">
    <h1 class="vcim-headline-1 mb-3">
      {{ 'website.services.title' | translate }}
    </h1>
    <span class="vcim-subtitle">
      {{ 'website.services.subtitle' | translate }}
    </span>
  </div>
  <div style="height: 100px" class="my-10 d-flex justify-center vcim-headline-4">
    {{ 'website.services.not-found' | translate }}
  </div>
</ng-template>
