import { Injectable } from '@angular/core';
import { ApplicationHttpClient } from '@s';
import { PriceFullListFilterI, PriceListFilterI, PriceListI, PriceListItemI, ResponseDto, ServicesI } from '@shared';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ServicesApiService {
  constructor(private http: ApplicationHttpClient) {}

  getOnlineServicesList(): Observable<ResponseDto<any[]>> {
    return this.http.get<ResponseDto<any[]>>('/portal/api/v1/online-services');
  }
  getPriceList(searchOptions: PriceListFilterI): Observable<ResponseDto<PriceListI[]>> {
    const tasks: {} = searchOptions.task === false || searchOptions.task ? { task: searchOptions.task } : {};
    return this.http.get<ResponseDto<PriceListI[]>>('/portal/api/v1/services/branch-services', {
      params: {
        specializationId: searchOptions.specializationId || '',
        cities: searchOptions.cityIds || '',
        doctorId: searchOptions.doctorId || '',
        text: searchOptions.searchText || '',
        ...tasks,
      },
    });
  }
  getPriceFullList(searchOptions: PriceFullListFilterI): Observable<ResponseDto<PriceListItemI[]>> {
    return this.http.get<ResponseDto<PriceListItemI[]>>('/portal/api/v1/services', {
      params: {
        text: searchOptions.searchText || '',
        cities: searchOptions.cityIds || '',
        page: searchOptions.pageNumber + 1,
        size: searchOptions.pageSize,
      },
    });
  }
  getPriceListItem(slug: string): Observable<ResponseDto<PriceListItemI>> {
    const encodedRoute = encodeURIComponent(slug);
    return this.http.get<ResponseDto<PriceListItemI>>(`/portal/api/v1/services/bySlugWithBranches/${encodedRoute}`);
  }
  
}
