import { HttpClient, HttpHeaders } from '@angular/common/http';
import { makeStateKey, StateKey, TransferState } from '@angular/platform-browser';

import { TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Observable, catchError, forkJoin, map, of, switchMap } from 'rxjs';

export class TranslatesBrowserLoaderService implements TranslateLoader {
  constructor(
    private prefix: string = 'i18n',
    private suffix: string = '.json',
    private transferState: TransferState,
    private http: HttpClient,
    private baseUrl: Record<string, string>
  ) {}

  public getTranslation(lang: string): Observable<any> {
    return this.http.get(`${this.prefix}${lang}${this.suffix}`).pipe(
      catchError(() => {
          console.log('no translation created');
          return this.http.get(`assets/i18n/${lang}_old${this.suffix}`);
      })
    );
  }
}
