import { PLATFORM_ID, Inject, Injectable, Optional, InjectionToken } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { environment } from '@env/environment';

export const BASE_URL = new InjectionToken<string>('BASE_URL');

export function checkApi(api: string = '') {
  return new RegExp(`^\/${api}\/`)
}

export function prependBaseUrl(host: string, url: string) {
  return [host?.replace(/\/$/g, ''), url.replace(/^\.?\//, '')]
    .filter(val => val)
    .join('/');
}

export function getHost(baseUrl: {[key: string]: string} = {}, url: string) {
  let path = '';
  baseUrl && Object.entries(baseUrl).forEach(([key, value]) => {
    // wrong regex
    if (checkApi(key).test(url)) {
      path = value || '';
    }
  })
  return path;
}

@Injectable({
  providedIn: 'root',
})
export class ProtocolService {
  private protocol: string;

  constructor(
    @Optional() @Inject(REQUEST) private request: Request, @Inject(PLATFORM_ID) private platformId: Object,
    @Optional() @Inject(BASE_URL) private baseUrl?: {[key: string]: string}
  ) {
    if (isPlatformServer(this.platformId)) {
      // Server-side rendering (SSR)
      // Access the protocol from the server request
      this.protocol = this.getServerProtocol();
    } else {
      // Browser rendering
      // Access the protocol from the window object
      this.protocol = this.getBrowserProtocol();
    }
  }

  getProtocol(): string {
    return this.protocol;
  }

  removeLeadingSlash(url: string) {
    if (url.startsWith('/')) {
      return url.substring(1);
    }
    return url;
  }

  getUrl(link: string) {
    return this.protocol + '://' + prependBaseUrl(getHost(this.baseUrl, link), this.removeLeadingSlash(link));
  }

  private getServerProtocol(): string {
    return environment.protocol;
  }

  private getBrowserProtocol(): string {
    // Get the protocol from the window object in the browser
    return window.location.protocol.replace(':', '');
  }
}