import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { environment } from '@env/environment';
import { OurMissionDataI, OurStoryDataI, OurValuesDataI } from '@shared';
import { AboutUsService } from '@shared/services/pages/about-us.service';
import { BehaviorSubject, Observable, tap } from 'rxjs';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AboutUsComponent implements OnInit, AfterViewInit {
  environment = environment;
  @ViewChild('textarea') elementView!: any;
  ourMissionInfo$: Observable<OurMissionDataI>;
  ourValuesInfo$: Observable<OurValuesDataI>;
  ourStoryInfo$: Observable<OurStoryDataI>;

  constructor(private readonly srv: AboutUsService) {
    this.ourMissionInfo$ = this.srv.getOurMission();
    this.ourValuesInfo$ = this.srv.getOurValues();
    this.ourStoryInfo$ = this.srv.getOurStory();
  }

  ngOnInit() {}
  ngAfterViewInit() {}
}
