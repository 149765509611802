import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CityPopupComponent } from '../../widgets/city/city-popup/city-popup.component';
import { CitiesService } from '@front/app/shared/services/pages/cities.service';
import { FormGroup, FormBuilder, FormArray, FormControl } from '@angular/forms';
import { CitiesListI } from '@front/app/shared';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-mobile-city-select',
  templateUrl: './mobile-city-select.component.html',
  styleUrls: ['./mobile-city-select.component.scss'],
})
export class MobileCitySelectComponent {
  form: FormGroup;

  constructor(
    private readonly dialog: MatDialog,
    public readonly srv: CitiesService,
    private readonly fb: FormBuilder,
    public readonly translate: TranslateService,
    private readonly toastr: ToastrService
  ) {
    this.form = this.fb.group({
      ids: this.fb.array(this.srv.selectedCities.map(i => i.toString())),
    });
  }

  get ids(): FormArray {
    return this.form.get('ids') as FormArray;
  }

  public handleCheckbox(event: any): void {
    if (event.checked) {
      this.ids.push(new FormControl(event.source.value));
    } else {
      this.ids.removeAt(this.ids.value.indexOf(event.source.value));
    }
    if (this.ids.length < 1) {
      this.toastr.error(this.translate.instant('website.layout.location.min-length-cities'));
    } else {
      this.srv.selectedCities = this.form.value.ids.map((i: string) => +i);
    }
  }
}
