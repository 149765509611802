import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CustomPagesComponent } from './custom-pages/custom-pages.component';
export const pagesRoutes = [{ path: ':page', component: CustomPagesComponent }];
@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild(pagesRoutes),
  ],
  exports: [RouterModule],
})
export class CustomPagesRoutingModule {}
