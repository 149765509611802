import { ServicesService } from '@shared/services/pages/services.service';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@env/environment';
import { SpecializationsI, SpecializationsFilterI, PriceListItemI } from '@front/app/shared';
import { ProtocolService, SettingsService } from '@s';
import { Observable, BehaviorSubject, switchMap, tap, map, of } from 'rxjs';
import { CitiesService } from '@front/app/shared/services/pages/cities.service';
import { BaseComponent } from '@front/app/base-component';
import { AuthService } from '@front/app/core';
import { MatDialog } from '@angular/material/dialog';
import { CreateRequestsTasksPopupComponent } from '@front/app/shared/components/personal-data/requests-tasks-popup/requests-tasks-popup.component';
import { AppointmentCreateService } from '@front/app/shared/services/pages/appointment-create.service';
import { SelectServicePopupComponent } from '@front/app/shared/components/personal-data/services-popup/services-popup.component';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-price-list-page',
  templateUrl: './price-list-page.component.html',
  styleUrls: ['./price-list-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PriceListPageComponent extends BaseComponent implements OnInit {
  environment = environment;
  specializationsList$: Observable<SpecializationsI[]>;
  private specializationsPageSize = 40;
  private specializationsTotal = 0;
  private specializationsItems: SpecializationsI[] = [];
  specializationsScrollDisabled: boolean = false;
  spacializationsReady = false;
  specializationInfo$: Observable<{
    item: SpecializationsI;
    isDefault: boolean;
  } | null> = of(null);

  private specializationsSearchOptions$ = new BehaviorSubject<SpecializationsFilterI>({
    pageNumber: 0,
    pageSize: this.specializationsPageSize,
  });
  selectedId$ = new BehaviorSubject<number | undefined>(undefined);

  userVerified = false;
  user$ = this.auth.user().subscribe(i => (this.userVerified = i?.verified || false));

  searchText$ = new BehaviorSubject('');

  link = '';
  isCopying = false;

  constructor(
    private readonly srv: ServicesService,
    private readonly citiesSrv: CitiesService,
    private readonly router: Router,
    private readonly settings: SettingsService,
    public readonly route: ActivatedRoute,
    private readonly auth: AuthService,
    private readonly dialog: MatDialog,
    private readonly appSrv: AppointmentCreateService,
    private readonly toastr: ToastrService,
    private readonly translate: TranslateService,
    public protocolService: ProtocolService,
  ) {
    super();

    this.specializationsList$ = this.specializationsSearchOptions$.pipe(
      switchMap(searchOptions => {
        return this.srv.getSpecializationsList({ ...searchOptions }).pipe(
          tap(i => {
            this.spacializationsReady = true;
            this.specializationsTotal = i.total;
            this.specializationsItems = [...this.specializationsItems, ...i.list];
            this.specializationsScrollDisabled = false;
          }),
          map(i => {
            return this.specializationsItems;
          })
        );
      })
    );

    this.citiesSrv.selectedCitiesObs
      .pipe(
        this.takeUntilDestroy(),
        tap(i => {
          this.specializationsItems = [];
          this.specializationsSearchOptions$.next({
            ...this.specializationsSearchOptions$.value,
            pageNumber: 0,
            pageSize: this.specializationsPageSize,
            cityIds: i,
          });
        })
      )
      .subscribe();

    this.specializationInfo$ = this.route.params.pipe(
      this.takeUntilDestroy(),
      switchMap(snapshot => {
        if (snapshot.slug) {
          const item = this.specializationsItems.find(i => i.slug === snapshot.slug);
          if (item) {
            this.selectedId$.next(item.id);
            return of({
              item: item,
              isDefault: false,
            });
          } else {
            return this.srv.getSpecializationsBySlug(snapshot.slug).pipe(
              tap(i => {
                  this.selectedId$.next(i?.id);
              }),
              map(i => ({ item: i, isDefault: false }))
            );
          }
        } else {
          return this.srv.getPriceListDescription().pipe(map(i => ({ item: i || {}, isDefault: true })));
        }
      })
    );
  }

  ChangeSearchText(event: string): void {
    this.searchText$.next(event);
    this.specializationsItems = [];
    this.specializationsSearchOptions$.next({
      ...this.specializationsSearchOptions$.value,
      pageNumber: 0,
      pageSize: this.specializationsPageSize,
      serviceSearchText: event,
    });
  }

  ngOnInit() {
    this.route.params.pipe(this.takeUntilDestroy()).subscribe(() => {
      this.link = this.protocolService.getUrl(environment?.website + this.router.url);
    });
  }

  toggleSpecializationsScrolled(): void {
    this.specializationsScrollDisabled = true;
    if (this.specializationsTotal > this.specializationsPageSize * (this.specializationsSearchOptions$.value.pageNumber + 1)) {
      this.specializationsSearchOptions$.next({
        ...this.specializationsSearchOptions$.value,
        pageNumber: this.specializationsSearchOptions$.value.pageNumber + 1,
      });
    }
  }

  changeSpeciality(event: number) {
    const slug = this.specializationsItems.find(i => i.id === event)?.slug;
    this.router.navigate(['/' + this.settings.getLanguage() + '/price-list/' + slug]);
  }

  selectService(item: PriceListItemI): void {
    if (this.userVerified) {
      if (item.task) {
        this.dialog.open(CreateRequestsTasksPopupComponent, {
          data: item,
          autoFocus: false,
        });
      } else {
        const dialogRef = this.dialog.open(SelectServicePopupComponent, {
          data: item,
          autoFocus: false,
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.appSrv.setSelectedPriceItem(item);
          }
        });
      }
    } else {
      this.router.navigate(['/' + this.settings.getLanguage() + '/services/' + item.slug + '/price']);
    }
  }
  copy(): void {
    if (!this.isCopying) {
      this.isCopying = true;
      this.toastr.info(this.translate.instant('website.workshops.text-copied'));
      setTimeout(() => {
        this.isCopying = false;
      }, 5000);
    }
  }
}
