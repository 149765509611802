import { Component, OnInit, ChangeDetectionStrategy, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@env/environment';
import { BaseComponent } from '@front/app/base-component';
import { MimeToExtension } from '@front/app/core';
import { MetaDataService } from '@front/app/shared/services/meta-data.service';
import { TranslateService } from '@ngx-translate/core';
import { ProtocolService, SettingsService } from '@s';
import { NewsShareType, VacanciesCategoriesI, VacanciesFilterI, VacanciesListI, VacancyDataI } from '@shared';
import { VacanciesService } from '@shared/services/pages/vacancies.service';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { switchMap, debounceTime, tap, map } from 'rxjs/operators';

@Component({
  selector: 'app-vacancies-list',
  templateUrl: './vacancies-list.component.html',
  styleUrls: ['./vacancies-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VacanciesListComponent extends BaseComponent implements OnInit {
  vacanciesList$: Observable<VacanciesListI[]>;
  categoriesList$: Observable<VacanciesCategoriesI[]>;
  foundVacalcies$: Observable<VacanciesListI[]>;
  searchText$ = new BehaviorSubject('');
  slug$ = new BehaviorSubject<string | null>(null);
  private pageSize = 12;
  private total = 0;

  NewsShareType = NewsShareType;
  link = '';

  isCopying = false;

  private items: VacanciesListI[] = [];

  vacanciesScrollDisabled: boolean = false;
  categoriesScrollDisabled: boolean = false;

  private searchOptions$ = new BehaviorSubject<VacanciesFilterI>({
    pageNumber: 0,
    pageSize: this.pageSize,
  });

  loading$ = new BehaviorSubject(true);

  vacancyInfo$: Observable<{ item: VacancyDataI; isDefault: boolean } | null>;

  constructor(
    private readonly srv: VacanciesService,
    private readonly route: ActivatedRoute,
    private readonly toastr: ToastrService,
    private readonly translate: TranslateService,
    private readonly meta: MetaDataService,
    public protocolService: ProtocolService,
    public readonly settings: SettingsService,
    private readonly router: Router,
    private _sanitizer: DomSanitizer
  ) {
    super();
    this.vacanciesList$ = this.searchOptions$.pipe(
      debounceTime(300),
      switchMap(searchOptions => {
        return this.srv.getVacanciesList(searchOptions).pipe(
          tap(i => {
            this.total = i.total;
            this.items = [...this.items, ...i.list];
            this.vacanciesScrollDisabled = false;
            this.loading$.next(false);
          }),
          map(i => {
            return this.items;
          })
        );
      })
    );

    this.categoriesList$ = this.srv.getCategoriesList();
    this.vacancyInfo$ = this.slug$.pipe(
      switchMap(slug => {
        if (slug) {
          return this.srv.getVacancyBySlug(this.route.snapshot.params.slug).pipe(
            map(i => ({
              item: i,
              isDefault: false,
            }))
          );
        }
        return this.srv.getVacancyDescription().pipe(
          map(i => ({
            item: i,
            isDefault: true,
          }))
        );
      })
    );
    this.foundVacalcies$ = this.searchText$.pipe(
      debounceTime(300),
      switchMap(searchText => {
        return this.srv.getVacanciesList({ pageNumber: 0, pageSize: 30, searchText }).pipe(
          map(i => {
            return i.list;
          })
        );
      })
    );
  }

  copy(): void {
    if (!this.isCopying) {
      this.isCopying = true;
      this.toastr.info(this.translate.instant('website.news.text-copied'));
      setTimeout(() => {
        this.isCopying = false;
      }, 5000);
    }
  }
  
  ngOnInit() {
    this.link = this.protocolService.getUrl(environment?.website + this.router.url);

    this.route.params.pipe(this.takeUntilDestroy()).subscribe(snapshot => {
      this.slug$.next(snapshot.slug);
    });
  }

  onCategoryChange(event: number[]): void {
    this.loading$.next(true);
    this.items = [];
    this.searchOptions$.next({
      ...this.searchOptions$.value,
      pageNumber: 0,
      pageSize: this.pageSize,
      categoriesIds: event,
    });
  }

  toggleVacanciesScrolled(): void {
    this.vacanciesScrollDisabled = true;
    if (this.total > this.pageSize * (this.searchOptions$.value.pageNumber + 1)) {
      this.searchOptions$.next({
        ...this.searchOptions$.value,
        pageNumber: this.searchOptions$.value.pageNumber + 1,
      });
    }
  }

  ChangeSearchText(event: string): void {
    this.searchText$.next(event);
  }

  stripTags(html: string): string {
    const str = this._sanitizer.sanitize(SecurityContext.HTML, this._sanitizer.bypassSecurityTrustHtml(html));
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = str || '';
    return tempDiv.textContent || '';
  }

  removeHtmlTags(html: string): string {
    return this.stripTags(html)
  }
}
