import { Component, OnInit, ChangeDetectionStrategy, Inject, SecurityContext } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NewsDataI, NewsShareType } from '@shared';
import { NewsService } from '@shared/services/pages/news.service';
import { BaseComponent } from '@front/app/base-component';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { environment } from '@env/environment';
import { MetaDataService } from '@front/app/shared/services/meta-data.service';
import { DomSanitizer } from '@angular/platform-browser';
import { BASE_URL, ProtocolService, SettingsService } from '@s';
import * as moment from 'moment';

@Component({
  selector: 'app-news-view',
  templateUrl: './news-view.component.html',
  styleUrls: ['./news-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewsViewComponent extends BaseComponent implements OnInit {
  environment = environment;
  newsInfo$: Observable<NewsDataI | undefined>;
  slug$ = new BehaviorSubject<string | null>(null);
  NewsShareType = NewsShareType;
  link = '';

  isCopying = false;

  constructor(
    private readonly srv: NewsService,
    public readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly toastr: ToastrService,
    private readonly translate: TranslateService,
    private readonly meta: MetaDataService,
    private _sanitizer: DomSanitizer,
    public protocolService: ProtocolService,
    public readonly settings: SettingsService,
    @Inject(BASE_URL) public baseUrl?: { [key: string]: string }
  ) {
    super();
    this.newsInfo$ = this.slug$.pipe(
      switchMap((slug) => {
        if (slug) {
          return this.srv.getNewsBySlug(slug).pipe(
            tap(async (data) => {
              if (data === undefined) {
                this.router.navigate([`/${this.settings.getLanguage()}/` + '/404']);
                return;
              }
            })
          );
        }
        return of({} as NewsDataI);
      })
    );
  }

  // TODO: ssr issue
  getImageDimensions(url: string): Promise<{ width: number, height: number }> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve({ width: img.naturalWidth, height: img.naturalHeight });
      img.onerror = reject;
      img.src = url;
    });
  }

  isEmpty(data: NewsDataI) {
    return Object.keys(data).length === 0;
  }

  ngOnInit() {
    this.route.params.pipe(this.takeUntilDestroy()).subscribe(snapshot => {
      this.slug$.next(snapshot.slug);
      if (this.route.snapshot.params.page) {
        this.meta.setTags('robots', 'noindex');
      }
      this.link = this.protocolService.getUrl(environment?.website + this.router.url);
    });
  }
  copy(): void {
    if (!this.isCopying) {
      this.isCopying = true;
      this.toastr.info(this.translate.instant('website.news.text-copied'));
      setTimeout(() => {
        this.isCopying = false;
      }, 5000);
    }
  }

  stripTags(html: string): string {
    // find li tags and add new line before them only if there is no new line already
    const htmlFixedLi = html.replace(/><li/g, '>\n<li');
    
    const str = this._sanitizer.sanitize(SecurityContext.HTML, this._sanitizer.bypassSecurityTrustHtml(htmlFixedLi));
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = str || '';
    return tempDiv.textContent || '';
  }

  removeHtmlTags(html: string, crop?: boolean): string {
    return crop ? this.stripTags(html).substring(0, 150) : this.stripTags(html);
  }
}
