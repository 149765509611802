import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PriceListPageComponent } from './price-list-page/price-list-page.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

export const childRoutes = [
  { path: '', component: PriceListPageComponent },
  { path: ':slug', component: PriceListPageComponent },
];
@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(childRoutes)],
  exports: [RouterModule, MatAutocompleteModule],
})
export class PriceListRoutingModule {}
