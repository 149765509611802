<ng-container *ngIf="companyInfo$ | async as companyInfo">
  <a class="mb-4 d-block f-16 fw-600" rel="noreferrer" target="_blank" [href]="'mailto:' + companyInfo.email">
    <i class="vcim-icon ic-mail f-20 pr-2"> </i> {{ companyInfo.email }}
  </a>
  <a class="mb-12 d-block f-16 fw-600" rel="noreferrer" target="_blank" [href]="'tel:' + companyInfo.phone">
    <i class="vcim-icon ic-phone f-20 pr-2"> </i> {{ companyInfo.phone }}
  </a>
</ng-container>

<div class="row" *ngIf="branchesList$ | async as branchesList">
  <ng-container *ngFor="let branch of branchesList">
    <div *ngIf="branch.address || branch.workingHours.length" class="pr-4 col-12 col-xs-6 col-sm-12 col-lg-6 mb-10">
      <p class="mb-3 flex" *ngIf="branch.address">
        <i class="vcim-icon ic-location f-20 pr-2"> </i>
        <span class="fw-700 f-18">
          {{ branch.address }}
        </span>
      </p>
      <span class="flex" *ngIf="branch.workingHours.length">
        <i class="vcim-icon ic-time f-20 pr-2"> </i>
        <div class="fw-600 f-16 d-flex flex-col">
          <span *ngFor="let item of branch.workingHours; let i = index" class="mb-1"> {{ item.title }} {{ item.description }} </span>
        </div>
      </span>
    </div>
  </ng-container>
</div>
