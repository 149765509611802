import {
    Component,
    OnInit,
    Input,
  } from '@angular/core';
import { environment } from '@env/environment';
  import { DoctorsCardI } from '@shared';
  
  @Component({
    selector: 'app-doctors-card',
    templateUrl: './doctors-card.component.html',
    styleUrls: ['./doctors-card.component.scss'],
  })
  export class DoctorsCardComponent implements OnInit {
    environment = environment;
    @Input() size: 'medium' | 'small' = 'medium';
    @Input() public doctor!: DoctorsCardI;
    @Input() public nowrap: boolean = false;
    @Input() public breakName: boolean = false;

    
  
    ngOnInit() {}
  }
  