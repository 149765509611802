import { ChangeDetectionStrategy, Component, Inject, OnInit, SecurityContext } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { PriceListItemI } from '@front/app/shared';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-services-popup',
  templateUrl: './services-popup.component.html',
  styleUrls: ['./services-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectServicePopupComponent implements OnInit {
  showForm$ = new BehaviorSubject(false);

  constructor(
    public readonly dialogRef: MatDialogRef<SelectServicePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PriceListItemI,
    private _sanitizer: DomSanitizer
  ) {
    if (!data.branchId && data.branches.length > 0) {
      if (data.branches.length === 1) {
        data.branchId = data.branches[0].id;
        data.branchName = data.branches[0].title;
      } else {
        this.showForm$.next(true);
      }
    }
  }

  ngOnInit() {}
  stripTags(html: string): string {
    const str = this._sanitizer.sanitize(SecurityContext.HTML, this._sanitizer.bypassSecurityTrustHtml(html));
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = str || '';
    return tempDiv.textContent || '';
  }

  removeHtmlTags(html: string): string {
    return this.stripTags(html);
  }
}
