import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { map, Observable, of, switchMap } from 'rxjs';
import { AttachmentsApiService } from '../api/attachments.api.service';
import { AttachmentsPageI } from '../../interfaces';

@Injectable({
  providedIn: 'root',
})
export class AttachmentsService {
  constructor(private readonly srv: AttachmentsApiService) {}

  getAttachments(): Observable<AttachmentsPageI[]> {
    return this.srv.getAttachments().pipe(map((d) => d?.data));
  }
  submitAttachment(file: File, form: any): Observable<void> {
    return this.srv.submitAttachment(file, form).pipe(map((d) => d?.data));
  }
}
