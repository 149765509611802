<div class="p-2 popup d-flex flex-col">
  <div class="d-flex justify-end">
    <button mat-icon-button type="button" class="d-flex items-center justify-center ml-2" (click)="dialogRef.close()">
      <i class="vcim-icon ic-close f-16"> </i>
    </button>
  </div>
  <div class="px-2 px-sm-8 mb-10">
    <h1 class="vcim-headline-4 mb-8 px-2">
      {{ 'website.layout.location.title' | translate }}
    </h1>

    <form class="flex flex-col" [formGroup]="form">
      <div *ngFor="let checkbox of data.list; index as i">
        <mat-checkbox
          (change)="handleCheckbox($event)"
          [value]="checkbox.id.toString()"
          [checked]="ids.value.includes(checkbox.id.toString())"
          class="vcim-checkbox color-cyan"
          color="primary">
          <span class="vcim-body-1">
            <ngx-dynamic-hooks
              [options]="{ sanitize: false }"
              class="vcim-inner-html inner-html-title"
              [content]="checkbox.title"></ngx-dynamic-hooks>
          </span>
        </mat-checkbox>
      </div>
    </form>
  </div>
  <div class="px-6 px-sm-12 pb-4 pb-sm-10">
    <button
      type="submit"
      [disabled]="!!form.invalid"
      mat-flat-button
      color="primary"
      class="vcim-button color-primary sm-button mt-auto w-full"
      (click)="save()">
      {{ 'website.layout.location.submit' | translate }}
    </button>
  </div>
</div>
