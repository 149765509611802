<form class="flex flex-col" [formGroup]="form">
  <mat-radio-group formControlName="id" (change)="change()" class="vcim-hidden-radio d-flex flex-col" [value]="form.get('id')?.value">
    <mat-radio-button [value]="item.id.toString()" class="mb-3" *ngFor="let item of list; index as i">
      <span class="vcim-headline-4">
        <ngx-dynamic-hooks
          [options]="{ sanitize: false }"
          class="vcim-inner-html inner-html-title"
          [content]="removeHtmlTags(item.title)"></ngx-dynamic-hooks>
      </span>
    </mat-radio-button>
  </mat-radio-group>
</form>
