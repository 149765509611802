import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { NewsI, SpecializationsI } from '@shared';
import { DashboardService } from '@shared/services/pages/dashboard.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardComponent implements OnInit {
  //, AfterViewInit, OnDestroy {

  newsList$: Observable<NewsI[]>;

  constructor(
    private readonly srv: DashboardService,
  ) {
    this.newsList$ = this.srv.getNewsList();
  }

  ngOnInit() {
    
  }
}

// notifySubscription!: Subscription;

// constructor(
// private settings: SettingsService
// ) {}

// ngOnInit() {
// this.notifySubscription = this.settings.notify.subscribe(res => {
//   console.log(res);
// });
// }

// ngAfterViewInit() {
// }

// ngOnDestroy() {
//   this.notifySubscription.unsubscribe();
// }
