<div
  class="container w-full flex flex-col items-center p-8 py-sm-6 py-md-12 px-sm-4"
>
  <h1 class="vcim-headline-2 mb-8 text-center">
    {{ "website.our-results.title" | translate }}
  </h1>

  <div
    class="w-full vcim-grid vcim-grid-size-1 vcim-grid-size-4-sm"
    *ngIf="resultsData$ | async as resultsData; else showSpinner"
  >
    <div class="container-item text-center px-2 pb-4 pb-sm-0 mb-4 mb-sm-0">
      <h1 class="vcim-headline-1"> {{ resultsData.customerSatisfaction }} </h1>
      <div class="vcim-body-1">
        {{ "website.our-results.customer-satisfaction" | translate }}
      </div>
    </div>
    <div class="container-item text-center px-2 pb-4 pb-sm-0 mb-4 mb-sm-0">
      <h1 class="vcim-headline-1"> {{ resultsData.patients }} </h1>
      <div class="vcim-body-1"> {{ "website.our-results.patients" | translate }} </div>
    </div>
    <div class="container-item text-center px-2 pb-4 pb-sm-0 mb-4 mb-sm-0">
      <h1 class="vcim-headline-1"> {{ resultsData.doctors }} </h1>
      <div class="vcim-body-1"> {{ "website.our-results.doctors" | translate }} </div>
    </div>
    <div class="container-item text-center px-2">
      <h1 class="vcim-headline-1"> {{ resultsData.companyGrowth }} </h1>
      <div class="vcim-body-1">
        {{ "website.our-results.company-growth" | translate }}
      </div>
    </div>
  </div>
</div>

<ng-template #showSpinner>
  <div style="height: 100px" class="my-10 d-flex justify-center">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
