<div
  class="container flex flex-col items-center animated h-full pointer"
  [routerLink]="doctor.slug ? ['/doctors/' + doctor.slug | localize] : []">
  <span
    class="container-image"
    [ngClass]="{
    'backgrounds': !doctor.photo,
  }">
    <loading-image
      *ngIf="doctor.photo"
      [image]="'/portal/' + doctor.photoPath + (doctor.photo.endsWith('.svg') ? '/ ' : '/' + size + '_') + doctor.photo"
      [alt]="doctor.firstName"
      [styles]="{
        'object-fit': 'contain',
        'object-position': 'center bottom',
        height: '100%'
      }"
      class="w-full h-full">
    </loading-image>
  </span>
  <div
    *ngIf="doctor.firstName || doctor.position"
    class="container-content d-flex items-center flex-col mt-5"
    [ngClass]="{
      'whitespace-nowrap': nowrap
    }">
    <div *ngIf="doctor.firstName" class="vcim-headline-4 text-center mb-2 pointer">
      {{ doctor.firstName }} <br *ngIf="breakName" />
      {{ doctor.lastName }}
    </div>
    <span class="vcim-body-2 text-center" *ngIf="doctor.position">
      {{ doctor.position }}
    </span>
  </div>
</div>
