import { Injectable } from '@angular/core';
import { ApplicationHttpClient } from '@s';
import {
  ResponseDto,
  WorkshopsFilterI,
  WorkshopsListI,
  WorkshopDataI,
} from '@shared/interfaces';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WorkshopsApiService {
  constructor(private http: ApplicationHttpClient) {}

  getWorkshopsList(
    searchOptions: WorkshopsFilterI
  ): Observable<ResponseDto<WorkshopsListI[]>> {
    return this.http.get<ResponseDto<WorkshopsListI[]>>(
      '/portal/api/v1/seminars',
      {
        params: {
          page: searchOptions.pageNumber + 1,
          size: searchOptions.pageSize,
        },
      }
    );
  }

  getWorkshopBySlug(slug: string): Observable<ResponseDto<WorkshopDataI>> {
    const encodedRoute = encodeURIComponent(slug);
    return this.http.get<ResponseDto<WorkshopDataI>>(
      `/portal/api/v1/seminars/bySlug/${encodedRoute}`
    );
  }
  
  getWorkshopsRelatedById(id: number): Observable<ResponseDto<WorkshopsListI[]>> {
    return this.http.get<ResponseDto<WorkshopsListI[]>>(
      `/portal/api/v1/seminars/${id}/related-seminars`
    );
  }
}
