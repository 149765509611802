import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  EventEmitter,
  Output,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { getInTouchFormI } from '@shared/interfaces/help.interface';

@Component({
  selector: 'get-in-touch-form',
  templateUrl: './get-in-touch-form.component.html',
  styleUrls: ['./get-in-touch-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GetInTouchFormComponent implements OnInit {
  getInTouchForm: FormGroup;
  @Output() save = new EventEmitter<getInTouchFormI>();

  constructor(
    private readonly fb: FormBuilder,
    readonly translate: TranslateService
  ) {
    this.getInTouchForm = this.fb.group({
      fullName: ['', Validators.compose([Validators.required])],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      phone: ['', Validators.compose([Validators.required])],
      message: ['', Validators.compose([Validators.required])],
    });
  }
  saveForm(): void {
    this.save.emit(this.getInTouchForm.value);

    this.getInTouchForm.reset();
    for (var name in this.getInTouchForm.controls) {
        this.getInTouchForm.controls[name].markAsUntouched();
      this.getInTouchForm.controls[name].setErrors([]);
    }
  }
  ngOnInit() {}
}
