import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import { DoctorsListI } from '@shared';
import { CitiesService } from '@shared/services/pages/cities.service';
import { ServicesService } from '@shared/services/pages/services.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-services-doctors',
  templateUrl: './doctors.component.html',
  styleUrls: ['./doctors.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServicesDoctorsComponent implements OnInit {
  @Input() set specId(event: number[]) {
    this._specId.next(event);
  }

  doctorsList$: Observable<DoctorsListI[]>;
  _specId = new BehaviorSubject<number[]>([]);

  constructor(
    private readonly srv: ServicesService,
    private readonly citiesSrv: CitiesService
  ) {
    this.doctorsList$ = combineLatest({
      specId: this._specId,
      cityIds: this.citiesSrv.selectedCitiesObs,
    }).pipe(
      switchMap(({ specId, cityIds }) => {
        return this.srv
          .getDoctorsList({
            pageNumber: 0,
            pageSize: 50,
            specializationIds: specId,
            cityIds: cityIds,
          })
          .pipe(map((i) => i.list));
      })
    );
  }

  customOptions: OwlOptions = {
    loop: false,
    autoplay: false,
    center: false,
    dots: false,
    nav: true,
    autoHeight: true,
    autoWidth: true,
    navText: [
      '<i class="vcim-icon ic-arrow-down"></i>',
      '<i class="vcim-icon ic-arrow-down"></i>',
    ],
    responsive: {
      0: {
        items: 1,
        slideBy: 1,
      },
      400: {
        items: 3,
        slideBy: 1,
      },
      800: {
        items: 4,
        slideBy: 1,
      },
    },
  };

  fieldTrackBy(_index: number, field: DoctorsListI) {
    return field.id;
  }

  ngOnInit() {}
}
