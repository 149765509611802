import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { NewsI, PaginationI } from '@shared';
import { NewsService } from '@shared/services/pages/news.service';
import { BehaviorSubject, map, Observable, switchMap, tap } from 'rxjs';

@Component({
  selector: 'app-news-list',
  templateUrl: './news-list.component.html',
  styleUrls: ['./news-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewsListComponent implements OnInit {
  newsList$: Observable<NewsI[]>;
  private pageSize = 9;
  private total = 0;
  private items: NewsI[] = [];
  newsScrollDisabled: boolean = false;

  private searchOptions$ = new BehaviorSubject<PaginationI>({
    pageNumber: 0,
    pageSize: this.pageSize,
  });
  constructor(private readonly srv: NewsService) {
    this.newsList$ = this.searchOptions$.pipe(
      switchMap((searchOptions) => {
        return this.srv.getNewsList(searchOptions).pipe(
          tap((i) => {
            this.total = i.total;
            this.items = [...this.items, ...i.list];
            this.newsScrollDisabled = false;
          }),
          map((i) => {
            return this.items;
          })
        );
      })
    );
  }

  ngOnInit() {}
  
  toggleNewsScrolled(): void {
    this.newsScrollDisabled = true;
    if (
      this.total >
      this.pageSize * (this.searchOptions$.value.pageNumber + 1)
    ) {
      this.searchOptions$.next({
        ...this.searchOptions$.value,
        pageNumber: this.searchOptions$.value.pageNumber + 1,
      });
    }
  }
}
