import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, isFormControl } from '@angular/forms';
import { environment } from '@env/environment';
import { AttachmentsPageI } from '@front/app/shared';
import { AttachmentsService } from '@front/app/shared/services/pages/attachments.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, tap } from 'rxjs';

@Component({
  selector: 'app-attachments-page',
  templateUrl: './attachments-page.component.html',
  styleUrls: ['./attachments-page.component.scss'],
})
export class AttachmentsPageComponent implements OnInit {
  attachments$: Observable<AttachmentsPageI[]>;
  file?: File;
  form: FormGroup;
  attachments: AttachmentsPageI[] = [];
  @ViewChild('fileDropRef') fileDropRef: any;

  environment = environment;
  constructor(
    private readonly srv: AttachmentsService,
    private readonly fb: FormBuilder,
    public readonly translate: TranslateService,
    private readonly toastr: ToastrService
  ) {
    this.attachments$ = this.srv.getAttachments().pipe(tap(i => (this.attachments = i)));

    this.form = this.fb.group({
      parentFullName: [''],
      childFullName: [''],
    });
  }

  ngOnInit() {}
  onFileDropped($event: any) {
    const allowedFormats = [
      'application/pdf',
      'image/jpeg',
      'image/png',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ];
    const file = $event.target.files[0];

    if (allowedFormats.includes(file.type)) {
      this.file = file;
    } else {
      this.toastr.error(this.translate.instant('website.attachments.invalid-file'));
    }
  }

  saveForm() {
    this.file &&
      this.srv.submitAttachment(this.file, this.form.value).subscribe(() => {
        this.toastr.success(this.translate.instant('website.attachments.saved'));
        this.file = undefined;
        this.form.reset();
      });
  }
  changePage(event: number) {
    this.fileDropRef.nativeElement.value = null;
    this.file = undefined;
    if (this.attachments[event].adult) {
      this.form.removeControl('childFullName');
      this.form.removeControl('parentFullName');
    } else {
      this.form.setControl('childFullName', this.fb.control(null, [Validators.required, Validators.pattern('^[a-zA-Z-]{2,40}$')]));
      this.form.setControl('parentFullName', this.fb.control(null, [Validators.required, Validators.pattern('^[a-zA-Z-]{2,40}$')]));
    }
  }
}
