import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BranchViewComponent } from './branch-view/branch-view.component';
import { BranchesListComponent } from './branches-list/branches-list.component';

export const childRoutes = [
  { path: '', component: BranchesListComponent },
  { path: ':slug', component: BranchViewComponent },
];
@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild(childRoutes),
  ],
  exports: [
    RouterModule
  ]
})
export class BranchesRoutingModule {}
