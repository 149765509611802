import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminLayoutComponent {
  isSidenavOpen: boolean = false;
  isErrPage: boolean = false;
  onResize(): void {
    this.isSidenavOpen = false;
  }
  constructor(private readonly router: Router) {
    this.ifErrorPage();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.ifErrorPage();
        this.isSidenavOpen = false;
      }
    });
  }

  ifErrorPage () {
    if (this.router.routerState.snapshot.url.endsWith('404')) {
      this.isErrPage = true;
    } else {
      this.isErrPage = false;
    }
  }
}
