import { Injectable } from '@angular/core';
import { ApplicationHttpClient } from '@s';
import { DoctorDataI, DoctorsFilterI, DoctorsListI, ResponseDto } from '@shared';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DoctorsApiService {
  constructor(private http: ApplicationHttpClient) {}

  getDoctorsList(searchOptions: DoctorsFilterI): Observable<ResponseDto<DoctorsListI[]>> {
    return this.http.get<ResponseDto<DoctorsListI[]>>('/portal/api/v1/doctors', {
      params: {
        page: searchOptions.pageNumber + 1,
        size: searchOptions.pageSize,
        specializationIds: searchOptions.specializationIds?.join(',') || '',
        text: searchOptions.searchText || '',
        branchIds: searchOptions.branchIds?.join(',') || '',
        cities: searchOptions.cityIds?.join(',') || '',
        serviceId: searchOptions.serviceId || '',
      },
    });
  }
  getDoctorsForCreateList(searchOptions: DoctorsFilterI): Observable<ResponseDto<DoctorsListI[]>> {
    return this.http.get<ResponseDto<DoctorsListI[]>>('/patients/api/v1/appointments/doctors', {
      params: {
        page: searchOptions.pageNumber + 1,
        size: searchOptions.pageSize,
        serviceId: searchOptions.serviceId || '',
        policy: searchOptions.policy || '',
      },
    });
  }
  
  getDoctorBySlug(slug: string): Observable<ResponseDto<DoctorDataI>> {
    const encodedRoute = encodeURIComponent(slug);
    return this.http.get<ResponseDto<DoctorDataI>>(`/portal/api/v1/doctors/bySlug/${encodedRoute}`);
  }

  
}
