import { Injectable } from '@angular/core';
import { ApplicationHttpClient } from '@s';
import { Observable } from 'rxjs';
import { AttachmentsPageI, ResponseDto } from '../../interfaces';

@Injectable({
  providedIn: 'root',
})
export class AttachmentsApiService {
  constructor(private http: ApplicationHttpClient) {}

  getAttachments(): Observable<ResponseDto<AttachmentsPageI[]>> {
    return this.http.get<ResponseDto<AttachmentsPageI[]>>('/portal/api/v1/patient-attachments', {});
  }
  submitAttachment(file: File, form: any): Observable<ResponseDto<void>> {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post<ResponseDto<void>>('/portal/api/v1/patient-attachments/submit', formData, {
      params: form,
    });
  }
}
