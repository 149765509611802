<section class="vcim-container container pt-8 pt-lg-16 mb-6 pb-10 pb-xl-16">
  <ng-container *ngIf="specializationInfo$ | async as specializationInfo; else showSpinner">
    <ng-container *ngIf="specializationInfo.item; else showNotFoundTemp">

      <div class="container-title px-2 mx-auto mb-8 mb-md-12 text-center">
        <h1 class="vcim-headline-1 mb-3" *ngIf="specializationInfo.isDefault; else showTitle">
          {{ 'website.personal-packages.title' | translate }}
        </h1>
        <ng-template #showTitle>
          <div class="mb-3 vcim-headline-1">
            <ngx-dynamic-hooks
              [options]="{ sanitize: false }"
              class="vcim-inner-html inner-html-title"
              [content]="specializationInfo.item.title"></ngx-dynamic-hooks>
          </div>
        </ng-template>
        <span class="vcim-subtitle flex mx-auto" *ngIf="specializationInfo.isDefault">
          {{ 'website.personal-packages.subtitle' | translate }}
        </span>
      </div>


      <div class="row wrap-reverse md-flex-wrap mx-0 mx-md--4 mx-lg--8 mb-3 mb-md-6">
        <div class="col-12 col-md-5 col-lg-4 px-0 px-md-4 px-lg-8 mt-8 mt-md-0">
          <div *ngIf="specializationsList$ | async as specializationsList; else showSpinner">
            <div class="container-radio">
              <app-radio-filter
                [selected]="(selectedId$ | async) || undefined"
                [list]="specializationsList"
                (save)="changeSpeciality($event)">
              </app-radio-filter>
            </div>
          </div>
        </div>

        <div class="col-12 col-md-7 col-lg-8 px-0 px-md-4 px-lg-8">
          <div class="specialization-info d-flex flex-col">
            <ng-container *ngIf="specializationInfo.item">
              <h1 *ngIf="specializationInfo.isDefault && specializationInfo.item.title" class="vcim-headline-2 mb-8 text-center">
                <ngx-dynamic-hooks
                  [options]="{ sanitize: false }"
                  class="vcim-inner-html inner-html-title"
                  [content]="specializationInfo.item.title"></ngx-dynamic-hooks>
              </h1>
              <div class="image mb-5" *ngIf="specializationInfo.item.coverPhoto">
                <loading-image
                  [image]="
                    '/portal/' +
                    specializationInfo.item.photoPath +
                    (specializationInfo.item.coverPhoto.endsWith('.svg') ? '/' : '/medium_') +
                    specializationInfo.item.coverPhoto
                  "
                  [styles]="{
                    'object-fit': 'cover',
                    'object-position': 'center'
                  }"
                  class="w-full h-full">
                </loading-image>
              </div>

              <div class="mb-10" *ngIf="specializationInfo.item.description">
                <ngx-dynamic-hooks
                  [options]="{ sanitize: false }"
                  *ngIf="specializationInfo.item.description"
                  class="vcim-body-1 vcim-inner-html mb-5"
                  [content]="specializationInfo.item.description"></ngx-dynamic-hooks>
              </div>

              <ng-container *ngIf="!specializationInfo.isDefault">
                <div class="pricing">
                  <app-service-price-list
                    [searchText]="(searchText$ | async) || ''"
                    [selectable]="true"
                    (onSelect)="selectService($event)"
                    [specId]="specializationInfo.item.id"></app-service-price-list>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</section>

<ng-template #showSpinner>
  <div style="height: 100px" class="my-10 d-flex justify-center">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>

<ng-template #showNotFoundTemp>
  <div class="container-title px-2 mx-auto mb-8 mb-md-12 text-center">
    <h1 class="vcim-headline-1 mb-3">
      {{ 'website.personal-packages.title' | translate }}
    </h1>
    <span class="vcim-subtitle">
      {{ 'website.personal-packages.subtitle' | translate }}
    </span>
  </div>
  <div style="height: 100px" class="my-10 d-flex justify-center vcim-headline-4">
    {{ 'website.personal-packages.not-found' | translate }}
  </div>
</ng-template>
