<section class="vcim-container container pt-8 pt-lg-16 mb-6 pb-10 pb-xl-16">
  <div class="container-title px-2 mx-auto mb-8 mb-md-12 text-center">
    <h1 class="vcim-headline-1 mb-3">
      {{ "website.news.title" | translate }}
    </h1>
    <span class="vcim-subtitle">
      {{ "website.news.subtitle" | translate }}
    </span>
  </div>

  <div
    infinite-scroll
    [infiniteScrollDistance]="1"
    [infiniteScrollThrottle]="300"
    (scrolled)="toggleNewsScrolled()"
    [infiniteScrollDisabled]="newsScrollDisabled"
    class="container-grid vcim-grid vcim-grid-size-1 vcim-grid-size-2-sm vcim-grid-size-3-md vcim-grid-size-4-xl gap-4 gap-md-10 gap-xl-16"
    *ngIf="newsList$ | async as newsList; else showSpinner"
  >
    <div *ngFor="let news of newsList" class="">
      <app-news-card [news]="news"></app-news-card>
    </div>
  </div>
</section>

<ng-template #showSpinner>
  <div style="height: 100px" class="my-10 d-flex justify-center">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
