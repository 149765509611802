import { Injectable } from '@angular/core';
import {
  VacanciesFilterI,
  PaginationDto,
  VacanciesListI,
  VacanciesCategoriesI,
  DoctorDataI,
  VacancyDataI,
} from '@shared/interfaces';
import { map, Observable } from 'rxjs';
import { VacanciesApiService } from '../api/vacancies.api.service';

@Injectable({
  providedIn: 'root',
})
export class VacanciesService {
  constructor(private readonly srv: VacanciesApiService) {}

  getVacanciesList(
    searchOptions: VacanciesFilterI
  ): Observable<PaginationDto<VacanciesListI>> {
    return this.srv.getVacanciesList(searchOptions).pipe(
      map((d) => {
        return { list: d?.data || [], total: d?.total || 0 };
      })
    );
  }

  getCategoriesList(): Observable<VacanciesCategoriesI[]> {
    return this.srv.getCategoriesList().pipe(map((d) => d?.data));
  }

  getVacancyBySlug(slug: string): Observable<VacancyDataI> {
    return this.srv.getVacancyBySlug(slug).pipe(map((d) => d?.data));
  }
  getVacancyDescription(): Observable<VacancyDataI> {
    return this.srv.getVacancyDescription().pipe(map((d) => d?.data));
  }
}
