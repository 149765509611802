<p
  class="vcim-body-2 w-full"
  [ngClass]="{ 'truncate-3': !(expanded$ | async),
                'truncate-4': !(showSeeMore$ | async),
 }"
  #itemElement>
  {{ data }}
</p>
<span class="see-more fw-700 pointer mt-2 d-inline-flex" (click)="expanded$.next(!expanded$.value)" *ngIf="showSeeMore$.value">
  {{ expanded$.value ? ('website.help.opinions.see-less' | translate) : ('website.help.opinions.see-more' | translate) }}
</span>
