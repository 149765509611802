<section class="vcim-container container pt-8 pt-lg-16 mb-6 pb-10 pb-xl-16">
  <div class="container-title px-2 mx-auto mb-8 mb-md-12 text-center">
    <h2 class="vcim-headline-1 mb-3">
      {{ 'website.workshops.title' | translate }}
    </h2>
    <span class="vcim-subtitle">
      {{ 'website.workshops.subtitle' | translate }}
    </span>
  </div>

  <div
    infinite-scroll
    [infiniteScrollDistance]="1"
    [infiniteScrollThrottle]="300"
    (scrolled)="toggleWorkshopsScrolled()"
    [infiniteScrollDisabled]="workshopsScrollDisabled"
    class="d-flex flex-col"
    *ngIf="workshopsList$ | async as workshopsList; else showSpinner"
    class="container-grid vcim-grid vcim-grid-size-1 vcim-grid-size-2-sm vcim-grid-size-3-md vcim-grid-size-4-xl gap-4 gap-md-10 gap-xl-16">
    <div *ngFor="let workshops of workshopsList" class="">
      <div
        class="container-item flex flex-col items-center animated h-full pointer"
        [routerLink]="'/seminars/' + workshops.slug | localize">
        <div class="container-item-image">
          <loading-image
            *ngIf="workshops.photo; else showNoImage"
            [image]="'/portal/' + workshops.photoPath + (workshops.photo.endsWith('.svg') ? '/' : '/medium_') + workshops.photo"
            [alt]="workshops.title"
            [styles]="{
              'object-fit': 'cover',
              'object-position': 'center'
            }"
            class="w-full h-full">
          </loading-image>
        </div>
        <div class="p-6 d-flex flex-col w-full h-full relative">
          <div class="date-label px-2 py-1 vcim-headline-5">
            {{ workshops.seminarDate }}
          </div>

          <div class="vcim-headline-4 mb-3">
            <ngx-dynamic-hooks
              [options]="{ sanitize: false }"
              class="vcim-inner-html inner-html-title"
              [content]="removeHtmlTags(workshops.title)"></ngx-dynamic-hooks>
          </div>

          <p class="mb-2 flex vcim-body-2 mt-auto">
            <i class="vcim-icon ic-time f-20 pr-4"> </i>
            <span>
              {{ workshops.seminarTime }}
            </span>
          </p>

          <p class="flex vcim-body-2">
            <i class="vcim-icon ic-location f-20 pr-4"> </i>
            <span>
              {{ workshops.address }}
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<ng-template #showSpinner>
  <div style="height: 100px" class="my-10 d-flex justify-center">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>

<ng-template #showNoImage>
  <div class="container-item-image-none">
    <app-no-image></app-no-image>
  </div>
</ng-template>
