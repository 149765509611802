<header class="header flex justify-between flex-row p-4 h-auto fixed-header">
  <img
    class="header-logo mr-0 mr-sm-4 pointer"
    alt="vardanants"
    [routerLink]="'/' | localize"
    [src]="'/assets/icons/logo_' + settings.getLanguage() + '.svg'" />
  <div class="flex items-center">
    <div class="header-top-search d-flex items-center">
      <mat-form-field class="vcim-search px-3" subscriptSizing="dynamic" appearance="fill">
        <input
          class="py-1"
          [matAutocomplete]="auto"
          (input)="ChangeSearchText($any($event.target).value)"
          type="text"
          matInput
          [placeholder]="'website.layout.header.search-for' | translate" />
        <i class="vcim-icon ic-search pl-4 f-20"></i>
      </mat-form-field>

      <mat-autocomplete #auto="matAutocomplete">
        <ng-container *ngIf="searchResult$ | async as searchResult">
          <mat-option
            (click)="selectSearchItem(item)"
            class="f-14 fw-600 vcim-headline-5 py-2"
            *ngFor="let item of searchResult"
            [value]="item.title">
            <ngx-dynamic-hooks
              [options]="{ sanitize: false }"
              class="vcim-inner-html inner-html-title"
              [content]="item.title"></ngx-dynamic-hooks>
          </mat-option>
        </ng-container>
        <div class="py-2 d-flex justify-center" *ngIf="selectLoading$ | async">
          <mat-spinner style="height: 40px"></mat-spinner>
        </div>
      </mat-autocomplete>
    </div>

    <button mat-icon-button class="ml-0 ml-sm-4 vcim-icon-button md-button" (click)="triggerSidenav.emit()">
      <i class="vcim-icon ic-burger f-20"></i>
    </button>
  </div>
</header>
