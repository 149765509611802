import { Injectable } from '@angular/core';
import { ApplicationHttpClient } from '@s';
import { BannerDataI, CompanyInfoI, GlobalSearchI, ResponseDto, ResultsDataI } from '@shared';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GlobalApiService {
  constructor(private http: ApplicationHttpClient) {}

  getBannerData(): Observable<ResponseDto<BannerDataI[]>> {
    return this.http.get<ResponseDto<BannerDataI[]>>('/portal/api/v1/home-pages');
  }
  
  getResultsData(): Observable<ResponseDto<ResultsDataI>> {
    return this.http.get<ResponseDto<ResultsDataI>>('/portal/api/v1/company-statistics');
  }
  getCompanyInfo(): Observable<ResponseDto<CompanyInfoI>> {
    return this.http.get<ResponseDto<CompanyInfoI>>('/portal/api/v1/company-info');
  }
  globalSearch(text: string): Observable<ResponseDto<GlobalSearchI[]>> {
    return this.http.get<ResponseDto<GlobalSearchI[]>>('/portal/api/v1/search', {
      params: {
        text
      }
    });
  }  
}
