export interface faqListDto {
  category: string;
  description: string;
  id: number;
  title: string;
}
export interface faqListI {
  category: string;
  list: faqListDto[];
}

export interface faqDescriptionI {
  description: string;
  id: number;
  title: string;
}

export interface getInTouchFormI {
  fullName: string;
  message: string;
  phone: string;
  email: string;
  clientRecaptchaResponse?: string;
}

export interface LicensesListI {
  id: number;
  photo?: string;
  photoPath: string;
  title: string;
}

export interface PrivacyPolicyDataI {
  id: number;
  title: string;
  description: string;
}
export interface TermsAndConditionsDataI {
  id: number;
  title: string;
  description: string;
}
export interface RequisitesListI {
  id: number;
  title: string;
  description: string;
  type: RequisitesType;
}
export enum RequisitesType {
  email = 'email',
  link = 'a_href',
  phone = 'phone',
  text = 'text',
}
export interface OpinionsListI {
  id: number;
  fullName: string;
  message: string;
  rate: number;
}
export interface CompanyDescriptionDataI {
  id: number;
  title: string;
  description: string;
}

export interface LeaveReviewFormI {
  fullName: string;
  email: string;
  phone: string;
  message: string;
  rate: number;
  translations?: {
    language: string;
    fullName: string;
    message: string;
  }[];
  clientRecaptchaResponse: string;
}
export interface OurMissionDataI {
  description: string;
  id: number;
  photo?: string;
  photoPath: string;
  title: string;
}
export interface OurValuesDataI {
  description: string;
  title: string;
  items: {
    description: string;
    id: number;
    photo?: string;
    photoPath: string;
    title: string;
  }[];
}

export interface OurStoryDataI {
  description: string;
  id: number;
  photo?: string;
  photoPath: string;
  title: string;
}

export interface AttachmentsPageI {
  id: number;
  title: string;
  description: string;
  adult: boolean;
  slug: string;
  files: {
    id: number;
    title: string;
    subTitle: string;
    filePath: string;
    file: string;
    size: string;
  }[];
}
