import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { TokenService } from '@core/authentication';
import { Router } from '@angular/router';
import { SettingsService } from '@s';

@Injectable()
export class DefaultInterceptor implements HttpInterceptor {
  constructor(
    public readonly translate: TranslateService,
    private readonly toast: ToastrService,
    private tokenService: TokenService,
    private readonly settings: SettingsService,
    private readonly router: Router,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.url.includes('/api/')) {
      return next.handle(req);
    }

    return next.handle(req).pipe(mergeMap((event: HttpEvent<any>) => this.handleOkReq(event, req)));
  }

  empty(event: HttpResponse<any>) {
    const modifiedBody = { data: [] };
    const modifiedResponse = event.clone({ body: modifiedBody });
    return of(modifiedResponse);
  }

  private handleOkReq(event: HttpEvent<any>, req: HttpRequest<any>): Observable<any> {

    if (event instanceof HttpResponse) {
      const body: any = event.body;
      // failure: { code: **, msg: 'failure' }
      // success: { code: 0,  msg: 'success', data: {} }
      if (body?.error === true && ![-2, -7].includes(body?.code)) {
        switch (body.code) {
          case -1:
            this.toast.error(this.translate.instant('website.auth.errors.ssn-registered'));
            break;
          case -3:
            this.toast.error(this.translate.instant('website.auth.errors.invalid-contact'));
            break;
          case -4:
            this.toast.error(this.translate.instant('website.auth.errors.invalid-code'));
            break;
          case -5:
            this.toast.error(this.translate.instant('website.auth.errors.username-registered'));
            break;
          case -6:
            if (!event.url!.endsWith('/refresh-token')) {
              this.toast.error(this.translate.instant('website.validations.wrong-pass'));
            } else {
              this.tokenService.clear();
            }
            break;
          case -8:
            this.toast.error(this.translate.instant('website.validations.dublicate-login'));
            break;
          case -9:
            this.toast.error(this.translate.instant('website.validations.not-activated-ssn'));
            break;
          case -10:
            this.toast.error(this.translate.instant('website.validations.username-not-found'));
            break;
          case -11:
            // this.toast.error(this.translate.instant('website.validations.patient-not-found'));
            console.warn(`error: ${body.error} - ${body.data}`);
            break;
          case -13:
            // usually not an error to show but empty data
            return this.empty(event);
          case -16:
            this.router.navigateByUrl(`${this.settings.getLanguage()}/404`);
            break;
          case 0:
            if (req.method !== 'GET') {
              return this.empty(event);
            } else {
              this.toast.error(this.translate.instant('website.auth.errors.default'));
            }
            // usually not an error to show but empty data
            break;
          default:
            this.toast.error(this.translate.instant('website.auth.errors.default'));
        }
        console.warn(`error: ${body.error} - ${body.data}`);
        return throwError(() => body.code);
      }
    }
    // Pass down event if everything is OK
    return of(event);
  }
}
