import { Injectable } from '@angular/core';
import { ApplicationHttpClient } from '@s';
import { PaginationI, ResponseDto } from '@shared/interfaces';
import {
  CompanyDescriptionDataI,
  faqDescriptionI,
  faqListDto,
  getInTouchFormI,
  LeaveReviewFormI,
  LicensesListI,
  OpinionsListI,
  OurMissionDataI,
  OurStoryDataI,
  OurValuesDataI,
  PrivacyPolicyDataI,
  RequisitesListI,
  TermsAndConditionsDataI,
} from '@shared/interfaces/help.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AboutUsApiService {
  constructor(private http: ApplicationHttpClient) {}

  getFaqList(): Observable<ResponseDto<faqListDto[]>> {
    return this.http.get<ResponseDto<faqListDto[]>>('/portal/api/v1/faqs', {});
  }
  //   getFaqCategories(): Observable<ResponseDto<any[]>> {
  //     return this.http.get<ResponseDto<any[]>>('/portal/api/v1/faq-categories', {});
  //   }

  getFaqDescription(): Observable<ResponseDto<faqDescriptionI>> {
    return this.http.get<ResponseDto<faqDescriptionI>>('/portal/api/v1/faq-description', {});
  }

  createFaqQuestion(body: getInTouchFormI): Observable<ResponseDto<any>> {
    return this.http.post<ResponseDto<any>>(
      '/portal/api/v1/faq-questions',
      body
    );
  }

  createContactQuestion(body: getInTouchFormI): Observable<ResponseDto<any>> {
    return this.http.post<ResponseDto<any>>(
      '/portal/api/v1/contact-messages',
      body
    );
  }
  getPrivacyPolicy(): Observable<ResponseDto<PrivacyPolicyDataI>> {
    return this.http.get<ResponseDto<PrivacyPolicyDataI>>(
      `/portal/api/v1/privacy-policy`
    );
  }
  getTermsAndConditions(): Observable<ResponseDto<TermsAndConditionsDataI>> {
    return this.http.get<ResponseDto<TermsAndConditionsDataI>>(
      `/portal/api/v1/terms-conditions`
    );
  }
  getRequisites(): Observable<ResponseDto<RequisitesListI[]>> {
    return this.http.get<ResponseDto<RequisitesListI[]>>(
      `/portal/api/v1/requisites`
    );
  }
  getOpinions(
    searchOptions: PaginationI
  ): Observable<ResponseDto<OpinionsListI[]>> {
    return this.http.get<ResponseDto<OpinionsListI[]>>(
      '/portal/api/v1/opinions',
      {
        params: {
          page: searchOptions.pageNumber + 1,
          size: searchOptions.pageSize,
          onlyPublished: true,
        },
      }
    );
  }

  createOpinion(body: LeaveReviewFormI): Observable<ResponseDto<any>> {
    return this.http.post<ResponseDto<any>>('/portal/api/v1/opinions', body);
  }

  getCompanyDescription(): Observable<ResponseDto<CompanyDescriptionDataI>> {
    return this.http.get<ResponseDto<CompanyDescriptionDataI>>(
      `/portal/api/v1/company-description`
    );
  }
  getOurMission(): Observable<ResponseDto<OurMissionDataI>> {
    return this.http.get<ResponseDto<OurMissionDataI>>(
      `/portal/api/v1/our-mission`
    );
  }

  getOurValues(): Observable<ResponseDto<OurValuesDataI>> {
    return this.http.get<ResponseDto<OurValuesDataI>>(
      `/portal/api/v1/our-value`
    );
  }
  getOurStory(): Observable<ResponseDto<OurStoryDataI>> {
    return this.http.get<ResponseDto<OurStoryDataI>>(
      `/portal/api/v1/our-story`
    );
  }
}
