import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AppSettings, defaults } from '../types/settings';
import { LocalStorageService } from './storage.service';
import { MetaService } from '@ngx-meta/core';
import * as moment from 'moment';
import 'moment/locale/ru';
import 'moment/locale/hy-am';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private key = 'ng-app-settings';
  langs = [
    { key: 'en-US', value: 'English', short: 'en', order: 2 },
    { key: 'ru-RU', value: 'Русский', short: 'ru', order: 1 },
    { key: 'hy-AM', value: 'Հայերեն', short: 'hy', order: 0 },
  ];

  private options: AppSettings;

  private readonly notify$ = new BehaviorSubject<Partial<AppSettings>>({});

  get notify() {
    return this.notify$.asObservable();
  }

  constructor(private store: LocalStorageService, private meta: MetaService) {
    const storedOptions = this.store.get(this.key);
    this.options = Object.assign(defaults, storedOptions);
    this.langs.sort((a, b) => (a.short < b.short ? 1 : a.short > b.short ? -1 : 0));
  }

  getOptions(): AppSettings {
    return this.options;
  }

  setOptions(options: AppSettings) {
    this.options = Object.assign(defaults, options);
    this.store.set(this.key, this.options);
    this.notify$.next(this.options);
  }

  getLanguage() {
    return this.options.language;
  }

  setLanguage(lang: string) {
    if (lang === 'hy') {
      moment.locale('hy-am');
    } else {
      moment.locale(lang);
    }
    this.options.language = lang;
    const langObj = this.langs.find(l => l.short === lang)!;
    this.store.set(this.key, this.options);
    this.notify$.next(this.options);
    this.meta.setTag('og:locale', langObj.key);
    this.meta.setTag('og:locale:alternate', 'en-US');
  }

  getUrl(url: string) {
    return url.replace('/' + this.getLanguage(), '').split('?')[0];
  }

  reset() {
    this.store.remove(this.key);
  }
}
