<div class="slider-container mb-4 mb-sm-6 mb-lg-10 mx-0 px-4 px-xl-0" *ngIf="doctorsList$ | async as doctorsList; else showSpinner">
  <ng-container *ngTemplateOutlet="doctorsList.length > 3 ? carousel : simple; context: { doctorsList: doctorsList }"> </ng-container>

  <ng-template #carousel let-doctorsList="doctorsList">
    <owl-carousel-o [options]="customOptions">
      <ng-container *ngFor="let slide of doctorsList; let index; fieldTrackBy">
        <ng-template class="slide" carouselSlide [id]="index.toString()">
          <div class="px-16 px-xs-4 px-xl-6">
            <app-doctors-card [breakName]="true" *ngIf="slide" [doctor]="slide"></app-doctors-card>
          </div>
        </ng-template>
      </ng-container>
    </owl-carousel-o>
  </ng-template>

  <ng-template #simple let-doctorsList="doctorsList">
    <div
      class="px-16 px-xs-0 vcim-grid vcim-grid-size-1 vcim-grid-size-2-xs vcim-grid-size-3-sm vcim-grid-size-3-lg vcim-grid-size-4-xl gap-8 gap-xl-12">
      <div *ngFor="let slide of doctorsList; let index" class="">
        <app-doctors-card [breakName]="true" *ngIf="slide" [doctor]="slide"></app-doctors-card>
      </div>
    </div>
  </ng-template>
</div>

<ng-template #showSpinner>
  <div style="height: 100px" class="my-10 d-flex justify-center">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
