import { ChangeDetectionStrategy, Component, OnInit, SecurityContext } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { environment } from "@env/environment";
import { BranchesListI, Paths } from "@shared";
import { DashboardService } from "@shared/services/pages/dashboard.service";
import { OwlOptions } from "ngx-owl-carousel-o";
import { Observable } from "rxjs";

@Component({
  selector: "app-dashboard-branches",
  templateUrl: "./branches.component.html",
  styleUrls: ["./branches.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class DashboardBranchesComponent implements OnInit {

  Paths = Paths;
  branchesList$: Observable<BranchesListI[]>;
  
  environment = environment;
  
  customOptions: OwlOptions = {
    loop: false,
    autoplay: false,
    center: false,
    dots: false,
    nav: true,
    autoHeight: false,
    autoWidth: true,
    navText: [
      '<i class="vcim-icon ic-arrow-down"></i>',
      '<i class="vcim-icon ic-arrow-down"></i>',
    ],
    responsive: {
      0: {
        items: 1,
        slideBy: 1,
      },
      700: {
        items: 2,
        slideBy: 1,
      },
      900: {
        items: 3,
        slideBy: 1,
      },
      1200: {
        items: 4,
        slideBy: 1,
      },
    },
  };
  
  constructor(
    // private readonly route: ActivatedRoute,
    private readonly srv: DashboardService,
    private _sanitizer: DomSanitizer,
  ) {
    this.branchesList$ = this.srv.getBranchesList();
  }


  ngOnInit() {

  }

  stripTags(html: string): string {
    const str = this._sanitizer.sanitize(SecurityContext.HTML, this._sanitizer.bypassSecurityTrustHtml(html));
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = str || '';
    return tempDiv.textContent || '';
  }

  removeHtmlTags(html: string): string {
    return this.stripTags(html)
  }
}
