import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CitiesListI } from '@shared';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-city-popup',
  templateUrl: './city-popup.component.html',
  styleUrls: ['./city-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CityPopupComponent implements OnInit {
  constructor(
    public readonly dialogRef: MatDialogRef<CityPopupComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { list: CitiesListI[]; selected: number[] },
    private readonly fb: FormBuilder,
    public readonly translate: TranslateService,
    private readonly toastr: ToastrService,
  ) {
    this.form = this.fb.group({
      ids: this.fb.array(data.selected.map((i) => i.toString())),
    });
  }
  form: FormGroup;

  get ids(): FormArray {
    return this.form.get('ids') as FormArray;
  }

  public handleCheckbox(event: any): void {
    if (event.checked) {
      this.ids.push(new FormControl(event.source.value));
    } else {
      this.ids.removeAt(this.ids.value.indexOf(event.source.value));
    }
  }
  save(): void {
    if (this.form.value.ids.length) {
      this.dialogRef.close(this.form.value.ids.map((i: string) => +i));
    } else {
      this.toastr.error(this.translate.instant('website.layout.location.min-length-cities'));
    }
  }
  ngOnInit() {}
}
