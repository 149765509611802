import { Injectable } from '@angular/core';
import { ApplicationHttpClient } from '@s';
import { SpecializationsI, ResponseDto, PaginationDto, SpecializationsFilterI, PriceListFilterI, PriceListI } from '@shared';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SpecializationsApiService {
  constructor(private http: ApplicationHttpClient) {}

  getSpecializationsList(searchOptions: SpecializationsFilterI): Observable<ResponseDto<SpecializationsI[]>> {
    const tasks: {} = searchOptions.task === false || searchOptions.task ? { task: searchOptions.task } : {};
    return this.http.get<ResponseDto<SpecializationsI[]>>('/portal/api/v1/specializations', {
      params: {
        ...tasks,
        page: searchOptions.pageNumber + 1,
        size: searchOptions.pageSize,
        cities: searchOptions.cityIds || '',
        filterForDoctors: searchOptions.filterForDoctors || false,
        doctorId: searchOptions.doctorId || '',
        text: searchOptions.searchText || '',
        serviceText: searchOptions.serviceSearchText || '',
      },
    });
  }
  getSpecializationsHomePageList(): Observable<ResponseDto<SpecializationsI[]>> {
    return this.http.get<ResponseDto<SpecializationsI[]>>('/portal/api/v1/home-page-specializations/specializations');
  }

  getSpecializationDescription(): Observable<ResponseDto<SpecializationsI>> {
    return this.http.get<ResponseDto<SpecializationsI>>('/portal/api/v1/specialization-description', {});
  }

  getPriceListDescription(): Observable<ResponseDto<SpecializationsI>> {
    return this.http.get<ResponseDto<SpecializationsI>>('/portal/api/v1/service-description', {});
  }

  getPersonalPackagesDescription(): Observable<ResponseDto<SpecializationsI>> {
    return this.http.get<ResponseDto<SpecializationsI>>('/portal/api/v1/personal-package-description', {});
  }

  getSpecializationBySlug(slug: string): Observable<ResponseDto<SpecializationsI>> {
    const encodedRoute = encodeURIComponent(slug);
    return this.http.get<ResponseDto<SpecializationsI>>(`/portal/api/v1/specializations/bySlug/${encodedRoute}`);
  }
  getPersonalPackagesList(): Observable<ResponseDto<SpecializationsI[]>> {
    return this.http.get<ResponseDto<SpecializationsI[]>>('/portal/api/v1/personal-packages/specializations', {});
  }
}
