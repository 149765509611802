import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '@front/app/base-component';
import { AuthService, emailRegex } from '@front/app/core';
import { createTree, filterOutFiles, formatSurvey } from '@front/app/shared/helpers/survey-helper';
import {
  AppointmentsTableI,
  SurveyItemI,
  SurveyResponse,
  SurveyTreeNodeI,
} from '@front/app/shared/interfaces/personal-data.interface';
import { AppointmentCreateService } from '@front/app/shared/services/pages/appointment-create.service';
import { TranslateService } from '@ngx-translate/core';
import { ItemType, SettingsService } from '@s';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, map, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-fill-survey',
  templateUrl: './fill-survey.component.html',
  styleUrls: ['./fill-survey.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FillSurveyComponent extends BaseComponent implements OnInit {
  survey$: Observable<SurveyTreeNodeI[] | null> = of(null);
  form: FormGroup;
  title: string = '';
  description: string = '';
  subtitle: string = '';
  list: SurveyItemI[] = [];
  isLoading$ = new BehaviorSubject(true);
  maxDate = moment();
  minDate = moment().add(-100, 'years');

  constructor(
    private readonly srv: AppointmentCreateService,
    public route: ActivatedRoute,
    private readonly fb: FormBuilder,
    private readonly toastr: ToastrService,
    public readonly translate: TranslateService,
    public readonly settings: SettingsService,
    private readonly router: Router,
    private readonly auth: AuthService
  ) {
    super();
    const accessable = this.route.snapshot.data?.public
      ? of({ id: true, hasSurvey: true, filledSurvey: false } as any as AppointmentsTableI)
      : this.srv.getAppointmentDetails(this.route.snapshot.params.id);
    this.survey$ = accessable.pipe(
      switchMap(i => {
        const req = this.route.snapshot.data?.public
          ? this.srv.getSurveyFormPublic(this.route.snapshot.params.id)
          : this.srv.getSurveyForm(this.route.snapshot.params.id);
        if (i.id && i.hasSurvey && !i.filledSurvey) {
          return req.pipe(
            tap(i => {
              this.title = i.title;
              this.list = i.items;
              this.subtitle = i.subtitle;
              this.description = i.description;

              this.form.get('surveyId')?.setValue(i.id);
              this.form.get('surveyTitle')?.setValue(i.title);
              i.items
                .filter(i => i.itemType === ItemType.Question)
                .forEach(i => {
                  this.answers.push(
                    this.fb.group({
                      question: [i.title],
                      questionId: [i.id],
                      answer: this.fb.group({}),
                    })
                  );
                });
              this.isLoading$.next(false);
            }),
            map(i => {
              return createTree(i.items);
            })
          );
        } else {
          this.isLoading$.next(false);
          return of(null);
        }
      })
    );
    this.form = this.fb.group({
      answers: this.fb.array([]),
      surveyTitle: ['', Validators.required],
      surveyId: ['', Validators.required],
      fullName: ['', Validators.required],
      birthDate: ['', Validators.required],
      phone: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, Validators.pattern(emailRegex)])],
      height: [null],
      weight: [null],
      description: [''],
    });
  }

  get answers(): FormArray {
    return this.form.get('answers') as FormArray;
  }
  ngOnInit() {}
  saveForm(): void {
    const files = filterOutFiles(this.form.value as SurveyResponse, this.list);
    const value = formatSurvey(this.form.value as SurveyResponse, this.list);
    let req = this.srv.saveSurveyForm(this.route.snapshot.params.id, value, files);
    if (this.route.snapshot.data?.public) {
      req = this.srv.saveSurveyFormPublic(this.route.snapshot.params.id, value, files);
    }
    req.pipe(this.takeUntilDestroy()).subscribe({
      next: i => {
        this.toastr.success(this.translate.instant('website.personal-data.appointments.survey.saved'));
        if (this.auth.userValue()) {
          this.router.navigate(['/' + this.settings.getLanguage() + '/personal-data/appointments']);
        } else {
          this.router.navigate(['/' + this.settings.getLanguage() + '/survey-completed']);
        }
      },
      error: () => {
        this.toastr.success(this.translate.instant('website.personal-data.appointments.survey.error'));
      },
    });
  }

  reset(): void {
    this.form = this.fb.group({
      answers: this.fb.array([]),
      surveyTitle: ['', Validators.required],
      surveyId: ['', Validators.required],
    });
    this.isLoading$.next(true);
    setTimeout(() => {
      this.isLoading$.next(false);
    });
  }
}
