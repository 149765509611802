import type { FormGroup, FormControl } from '@angular/forms';

export type ControlsOf<T extends Record<string, any>> = {
  [K in keyof T]: T[K] extends Record<any, any>
    ? FormGroup<ControlsOf<T[K]>>
    : FormControl<T[K]>;
};

export interface IProfile {
  username: string;
  email: string;
  gender: string;
  city: string;
  address: string;
  company: string;
  mobile: string;
  tele: string;
  website: string;
  date: string;
}

export interface User {
  [prop: string]: any;
  createdBy: number;
  createdAt: string;
  refreshToken: string;
  changedBy: number;
  changedAt: string;
  login: string;
  password?: string;
  fullName: string;
  lastLogin: string;
  lastPasswordChange: string;
  roles: Role[];
  permissions: string[];

  description: string | null;
  email: string;
  externalId: string | null;
  id: number;
  name: string;
  parentId: number;
  phone: string;
  photo: string | null;
  ssn: string;
  surname: string;
  userUuid: string;
  photoPath: string;
  username: string;
  verified: boolean;
}

export interface Permission {
  id: number;
  createdBy: number;
  createdAt: string;
  changedBy: number;
  changedAt: string;
  name: string;
  description: string;
}

export interface IDoctor {
  id: number;
  birthday: string;
  email: string;
  firstName: string;
  gender: string;
  lastName: string;
  middleName: string;
  phone: string;
  secondaryPhone: string;
  description: string;
  ssn: string;
}

export interface DoctorResp {
  id: number;
  externalId: string;
  birthday: string;
  email: string;
  phone: string;
  secondaryPhone: string;
  ssn: string;
  translations: {
    entityId: number;
    firstName: string;
    gender: string;
    language: string;
    lastName: string;
    middleName: string;
    description: string;
  };
}

export interface UserFormI {
  fullName: string;
  email: string;
  phone: string;
  roles: string[];
  login: string;
  password: string;
  id?: string;
}

export interface Role {
  id: number;
  createdBy?: number;
  createdAt?: string;
  changedBy?: number;
  changedAt?: string;
  name: string;
  description: string;
  permissions: Permission[];
}

export type PartialOrNull<T> = { [P in keyof T]?: T[P] | null };

export const genders = ['common.male', 'common.female'];

// this used for routes and seo
export enum Paths {
  News = 'news',
  Doctors = 'doctors',
  Vacancies = 'vacancies',
  Seminars = 'seminars',
  Services = 'services',
  Branches = 'branches',
  PersonalPackages = 'personal-packages',
  Pages = 'pages',
  PriceList = 'price-list',
  Attachments = 'attachments'
}
