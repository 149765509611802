import { Injectable } from "@angular/core";
import { ApplicationHttpClient } from "./http-client.service";
import { ISeo } from "projects/shared/src/lib/types/entities";
import { DeepPartial, mapRouteToApiFront } from "../types/interfaces";
import { Observable, catchError, of, switchMap, tap } from "rxjs";
import { environment } from "@env/environment";
import { ProtocolService } from "./protocol.service";
import { SettingsService } from "./settings.service";

export interface SeoMeta {
  title: string;
  description: string;
  tags: string;
  'og:image': string;
  'og:image:secure_url': string;
  firstName: string;
  lastName: string;
  newsDate: string;
}

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  constructor(
    private ps: ProtocolService,
    private settings: SettingsService,
    private http: ApplicationHttpClient
  ) { }

  getSeoData(global?: boolean) {
    let param = '';
    if (global !== undefined) param = `?global=${global}`;
    return this.http.Get<ISeo[]>(`/portal/api/v1/seo-data/allWithTranslations${param}`);
  }


  upsertSeoArray(data: ISeo[]) {
    return this.http.Post<ISeo[]>('/portal/api/v1/seo-data/saveAll', data).pipe(
      switchMap(() => this.http.get(this.ps.getUrl(environment.website + '/generate-seo-files'))
      ));
  }

  upsertSeo(data: DeepPartial<ISeo>) {
    let seoRequest: Observable<ISeo>;
    if (data.id) {
      seoRequest = this.updateSeo(data);
    } else {
      seoRequest = this.insertSeo(data);
    }

    return seoRequest.pipe(
      tap(() => this.http.get(this.ps.getUrl(environment.website + '/generate-seo-files')).subscribe()),
      catchError(error => {
        console.error('Error in one of the requests:', error);
        return of({} as ISeo); // or handle error appropriately
      })
    );
  }

  updateSeo(data: DeepPartial<ISeo>) {
    return this.http.Put<ISeo>(`/portal/api/v1/seo-data/${data.id}`, data);
  }

  insertSeo(data: DeepPartial<ISeo>) {
    return this.http.Post<ISeo>('/portal/api/v1/seo-data', data);
  }

  getSeoAndObject(objectStr: string, slug: string) {
    const object = mapRouteToApiFront[objectStr as keyof typeof mapRouteToApiFront];
    return this.http.Get<Record<string, any>>(`/portal/api/v1/seo-data/objectWithTranslationsAndPhotos?object=${object}&slug=${slug}`);
  }

  mapForSeo(objectAndSeo: Record<string, any>): SeoMeta {
    const getImg = (entity: any) => {
      if (entity.photoPath) {
        return this.ps.getUrl(`/portal/${entity.photoPath}/${entity.photo}`);
      } else if (entity.coverPhoto) {
        return this.ps.getUrl(`/portal/${entity.coverPhotoPath}/${entity.coverPhoto}`);
      }
      return '';
    }
    const entity = objectAndSeo.entity;
    const seoData = objectAndSeo.seoData;
    const lang = this.settings.getLanguage();
    const translations = entity.translations.find((t: any) => t.language === lang) || {};
    let image = getImg(entity);
    const imgLocalized = getImg(translations);
    let tags = '';
    let doctorsTitle = (translations.firstName || translations.lastName) ? translations?.firstName + ' ' + translations?.lastName : '';
    let title = translations?.title || doctorsTitle || '';
    let description = translations?.description || '';
    if (seoData && seoData.photo) {
      image = this.ps.getUrl(`/portal/${seoData.photoPath}/${seoData.photo}`);
    } else if (imgLocalized) {
      image = imgLocalized;
    }

    if (seoData) {
      title = seoData.translations.find((t: any) => t.language === lang)?.title || title;
      description = seoData.translations.find((t: any) => t.language === lang)?.description || description;
      tags = seoData.translations.find((t: any) => t.language === lang)?.tags || '';
    }

    return {
      title: title,
      description: description,
      tags: tags,
      'og:image': image,
      'og:image:secure_url': image,
      firstName: translations?.firstName,
      lastName: translations?.lastName,
      newsDate: entity.newsDate,
    };
  }

  getSeo(route: string) {
    // if error return empty object
    // escape and /or decode url
    const encodedRoute = encodeURIComponent(route);
    return this.http.Get<Record<string, any>>(`/portal/api/v1/seo-data/byRoute?route=${encodedRoute}`).pipe(
      catchError(() => {
        return of({} as Record<string, any>); // or handle error appropriately
      })
    );
  }

  changeRoute(route: string, newRoute: string) {
    const encodedRoute = encodeURIComponent(route);
    return this.http.Get<Record<string, any>>(`/portal/api/v1/seo-data/byRoute?route=${encodedRoute}`)
      .pipe(switchMap((seo) => {
        if (seo) {
          seo.route = newRoute;
          return this.upsertSeo(seo);
        }
        return of();
      }));
  }

  getSeoById(id: number | string) {
    return this.http.Get<ISeo>(`/portal/api/v1/seo-data/${id}`);
  }

  deleteSeo(id: number) {
    return this.http.delete<ISeo>(`/portal/api/v1/seo-data/${id}`).pipe(switchMap(() =>
      this.http.get(this.ps.getUrl(environment.website + '/generate-seo-files'))));
  }

  deleteSeoOnly(id: number) {
    return this.http.delete<ISeo>(`/portal/api/v1/seo-data/${id}`);
  }

  deletePhoto(id: number, fileName: string) {
    return this.http.delete<ISeo>(`/portal/api/v1/seo-data/deletePhoto/${id}`,
      {
        body: fileName,
      });
  }

  getPhoto(url: string): any {
    return this.http.get(`${url}`, { responseType: 'blob' });
  }

  uploadPhoto(id: number, file: File) {
    const formData = new FormData();
    formData.append('file', file);

    return this.http.Post(
      `/portal/api/v1/seo-data/uploadPhoto`,
      formData,
      {
        params: {
          id: id.toString()
        },
      }
    );
  }
}