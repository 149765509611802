import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AttachmentsPageComponent } from './attachments-page/attachments-page.component';

export const childRoutes = [
  { path: '', component: AttachmentsPageComponent },
];
@NgModule({
  declarations: [],
  imports: [
    // RouterModule.forChild(childRoutes),
  ],
  exports: [
    RouterModule
  ]
})
export class AttachmentsRoutingModule {}
